import { Field, reduxForm } from "redux-form";
import { makeStyles } from "@mui/styles";
import InputFields, {
  FieldTitle,
  ThemedButton,
  ThemedTitle,
} from "src/Components/InputFields";
import { Box, Typography } from "@mui/material";
import getInitFields from "src/utils/getInitFields";
import { useEffect, useState } from "react";
import getScreenDetail from "src/utils/screenDetails";
import GCTooltip from "src/Components/GCTooltip";
import * as Yup from "yup";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";

//icons
import bankIcon from 'src/assets/paymentmethod/bankIcon.svg';
import cardIcon from 'src/assets/paymentmethod/cardIcon.svg';
import Theme from "src/Theme/theme";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  convDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "center",
    },
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
}));

const validationSchema = Yup.object().shape({
  // payment_type: Yup.string().trim().nullable().required("Required"),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

let PaymentMethod = (props) => {
  const classes = useStyles();
  const { handleSubmit, submitting, invalid, change, auto_pay } = props;
  const [screenDetail, setScreenDetail] = useState(null);
  const [needDeposit, setNeedDeposit] = useState(false);

  let disabled = () => {
    if (!auto_pay) {
      return false;
    } else if (invalid) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    const init = async () => {
      let initialValues = {
        details: null,
        needDeposit: true,
        autoPay: auto_pay,
        method: "card"
      }
      let credit_check = await localStorage.getItem("credit_check_response");
      let method = await localStorage.getItem("payment-method");
      if (credit_check) {
        credit_check = JSON.parse(credit_check)
        let depositAmount = credit_check && credit_check.totalDepositAmount;
        if (depositAmount === 0) {
          let details = await getScreenDetail("payment-method", Theme);
          initialValues = {
            details: details,
            needDeposit: false,
            autoPay: true,
            method: "bank"
          }
        } else {
          let details = await getScreenDetail("deposit", Theme);
          initialValues.details = details;
        }
        if (method) {
          method = JSON.parse(method);
          initialValues.autoPay = method.auto_pay;
          initialValues.method = method.payment_type;
        }
      } else {
        console.error("No credit check data")
      }

      setScreenDetail(initialValues.details);
      setNeedDeposit(initialValues.needDeposit);
      change("auto_pay", initialValues.autoPay);
      change("payment_type", initialValues.method);
    };
    init();
  }, []);

  const handleAutopay = (e) => {
    if (!needDeposit) {
      if (!e.target.checked) {
        change("payment_type", null);
      } else {
        change("payment_type", "bank");
      }
    }
  }

  return (
    <form id="payment-method" onSubmit={handleSubmit}>
      <ThemedTitle>
        {screenDetail &&
          <div
            dangerouslySetInnerHTML={{ __html: screenDetail.description }}
          />}
      </ThemedTitle>
      <div className={classes.convDiv}>
        <br />
        {!needDeposit && <div
          class="cc-selector"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Field
            id="bank"
            component="input"
            type="radio"
            name="payment_type"
            value="bank"
            disabled={!auto_pay}
          />
          <label
            class="drinkcard-cc bank"
            for="bank"
            style={{
              borderRadius: 12,
              height: 211,
              width: 247,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src={bankIcon} alt="bank" style={{ marginBottom: 14, height: "64", width: "56" }} />
              <Typography variant="body2" style={{ color: "#21212185", fontSize: "20px", fontWeight: 600 }}>Bank account</Typography>
            </Box>
          </label>
        </div>}
        <Box sx={{ height: 23, width: 23 }} />
        <div
          class="cc-selector"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Field
            id="card"
            component="input"
            type="radio"
            name="payment_type"
            disabled={!auto_pay}
            value="card"
          />
          <label
            class="drinkcard-cc card"
            for="card"
            style={{
              borderRadius: 12,
              height: 211,
              width: 247,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src={cardIcon} alt="bank" style={{ marginBottom: 14, height: "64", width: "56" }} />
              <Typography variant="body2" style={{ color: "#21212185", fontSize: "20px" }}>Credit or debit</Typography>
            </Box>
          </label>
        </div>
      </div>
      <Box sx={{ mt: 2, display: "flex", padding: "0 10", justifyContent: "center" }}>
        <Field
          name="auto_pay"
          type="switch"
          component={InputFields}
          additional={handleAutopay}
          placeholder="Autopay"
        />
        {/* <Field
          name="eBill"
          type="switch"
          disabled={true}
          component={InputFields}
          placeholder="eBill"
        /> */}
      </Box>

      <style jsx>{`
        .cc-selector input {
          margin: 0;
          padding: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }

        .cc-selector-2 input {
          position: absolute;
          z-index: 999;
        }

        .cc-selector-2 input:active + .drinkcard-cc,
        .cc-selector input:active + .drinkcard-cc {
          opacity: 0.9;
        }
        .cc-selector-2 input:checked + .drinkcard-cc,
        .cc-selector input:checked + .drinkcard-cc {
          background-color: white;
          border: 2px solid #ff5a6a;
          -webkit-filter: none;
          -moz-filter: none;
          filter: none;
        }
        .drinkcard-cc {
          cursor: pointer;
          background-size: contain;
          background-repeat: no-repeat;
          border: 2px solid #e6e6e6;
          // background-color: #F3F3F3;
          display: inline-block;
          width: 100px;
          height: 70px;
          -webkit-transition: all 100ms ease-in;
          -moz-transition: all 100ms ease-in;
          transition: all 100ms ease-in;
          -webkit-filter: brightness(1) grayscale(0) opacity(1);
          -moz-filter: brightness(1) grayscale(0) opacity(1);
          filter: brightness(1) grayscale(0) opacity(1);
        }
        .drinkcard-cc:hover {
          -webkit-filter: brightness(1) grayscale(0) opacity(1);
          -moz-filter: brightness(1) grayscale(0) opacity(1);
          filter: brightness(1) grayscale(0) opacity(1);
        }
        .drinkcard-cc:disabled {
          -webkit-filter: brightness(1) grayscale(1) opacity(1);
          -moz-filter: brightness(1) grayscale(1) opacity(1);
          filter: brightness(1) grayscale(1) opacity(1);
        }
      `}</style>

      <Box className={classes.forContinueAndTooltop}>
        <Box>
          <ThemedButton
            isSubmitting={submitting}
            disabled={disabled()}
            type="submit"
          >
            {!auto_pay && !needDeposit ? "Skip" : "Continue"}
          </ThemedButton>
        </Box>
        <Box>
          {screenDetail && screenDetail.tooltip && (
            <GCTooltip>{screenDetail.tooltip}</GCTooltip>
          )}
        </Box>
      </Box>
    </form>
  );
};

PaymentMethod = reduxForm({
  initialValues: getInitFields("payment-method"),
  form: "payment-method",
  destroyOnUnmount: false,
  validate,
})(PaymentMethod);

const selector = formValueSelector('payment-method') // <-- same as form name
PaymentMethod = connect(
  state => {
    // can select values individually
    const auto_pay = selector(state, 'auto_pay')
    return {
      auto_pay,
    }
  }
)(PaymentMethod)

export default PaymentMethod;

import { Box } from "@mui/material";
import React from "react";
import { Field, reduxForm } from "redux-form";
import InputFields, {
  ThemedButton,
} from "src/Components/InputFields";
import { makeStyles } from "@mui/styles";
import getInitFields from "src/utils/getInitFields";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import getScreenDetail from "src/utils/screenDetails";
import GCTooltip from "src/Components/GCTooltip";
import moment from "moment";
import axios from "src/utils/axios";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
}));

let yesterday = new Date().getTime() - 24 * 60 * 60 * 1000;
yesterday = new Date(yesterday).getTime();

const fieldErrors = JSON.parse(localStorage.getItem("fieldErrors"));
const fieldTitles = JSON.parse(localStorage.getItem("fieldTitles"));

const validationSchema = Yup.object().shape({
  start_date: Yup.string().trim().nullable()
    .test("date-format", fieldErrors?.startDate.start_date.validDate, value => {
      let newVal = new Date(value).setHours(0, 0, 0, 0);
      let sdate = JSON.parse(localStorage.getItem("blackoutStartDate"))
      let edate = JSON.parse(localStorage.getItem("blackoutEndDate"))
      let check = (newVal >= new Date(sdate + "T00:00:00").setHours(0, 0, 0, 0) && newVal <= new Date(edate + "T00:00:00").setHours(0, 0, 0, 0));
      return check;
    })
    .test("date-format", fieldErrors?.startDate.start_date.activeDate, value => {
      let arraydates = JSON.parse(localStorage.getItem("blackoutArrayDate"));
      let disabledDateArray = [];
      let newVal = new Date(value).setHours(0, 0, 0, 0);
      arraydates.map((data) => {
        disabledDateArray.push(new Date(data).setHours(0, 0, 0, 0))
      })
      let check = !disabledDateArray.includes(newVal);
      return check;
    })
    .test("date-format", fieldErrors?.startDate.start_date.validStartDate, value => moment(value).isValid())
    .required(fieldErrors?.required),
});

const validate = (values) => {
  const formErrors = {};
  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    // window.NREUM.noticeError(errors);                                  // no need since these errors are for basic field validation
    errors.inner !== undefined && errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }
  return formErrors;
};

let StartService = (props) => {
  const { handleSubmit, submitting, invalid, change } = props;
  const classes = useStyles();
  const [screenDetail, setScreenDetail] = useState(null);
  const [disContinue, setDisContinue] = useState(true);
  const [blackoutDates, setBlackoutDates] = useState({
    "tdu": "5",
    "blackout": [
      "2022-05-15T00:00:00",
      "2022-05-22T00:00:00",
      "2022-05-29T00:00:00",
      "2022-06-05T00:00:00",
      "2022-06-12T00:00:00",
      "2022-06-19T00:00:00",
      "2022-06-26T00:00:00",
      "2022-07-03T00:00:00"
    ],
    "start": "2022-05-20",
    "end": "2022-07-08",
    "cutoff": "1600",
    "max_days": "60"
  });

  const getBlackoutDates = async () => {
    let tdu = JSON.parse(localStorage.getItem("distribution_point_response"));
    tdu = tdu[0].distributionServiceProviderID;
    try {
      let response = await axios.get(
        `/blackout-dates/${tdu}`,
      );
      setBlackoutDates(response.data);
      let startDate = JSON.stringify(response.data.start)
      let endDate = JSON.stringify(response.data.end)
      let arrayDate = JSON.stringify(response.data.blackout)
      localStorage.setItem("blackoutStartDate", startDate)
      localStorage.setItem("blackoutEndDate", endDate)
      localStorage.setItem("blackoutArrayDate", arrayDate)
    } catch (err) {
      window.NREUM.noticeError(err);
    }
  };

  const dateValid = (date) => {
    let dateFormatted = moment(date).format("yyyy-MM-DDT00:00:00")
    let i = 0;
    while (blackoutDates.blackout.includes(dateFormatted)) {
      dateFormatted = moment(new Date(new Date(dateFormatted).getTime() + 60 * 60 * 24 * 1000)).format("yyyy-MM-DDT00:00:00")
      i = i + 1;
      if (i >= 30) {
        return dateFormatted
      }
    }
    return dateFormatted
  }

  useEffect(async () => {
    setDisContinue(true)
    if (localStorage.getItem("start-date")) {
      await change("start_date", JSON.parse(localStorage.getItem("start-date")).start_date)
      setDisContinue(false)
    }
    else {
      await change("start_date", dateValid(JSON.parse(localStorage.getItem("blackoutStartDate"))))
      change("start_date", dateValid(JSON.parse(localStorage.getItem("blackoutStartDate")))).payload === "1970-01-01T00:00:00" ? setDisContinue(true) : setDisContinue(false)
    }
  }, [blackoutDates])

  const disabledDates = (date) => {
    let disabledDateArray = [];
    blackoutDates.blackout.map((data) => {
      disabledDateArray.push(new Date(data).getTime())
    })
    return disabledDateArray.includes(date.getTime()) ? true : false;
  }

  useEffect(() => {
    let path = window.location.pathname.replace("/", "")
    if (path === "start-date") {
      const init = async () => {
        let details = await getScreenDetail("start-date");
        setScreenDetail(details);
      };
      getBlackoutDates()
          .then(()=>{
        console.log('blackout dates');
      })
          .catch((error) => {
            window.NREUM.noticeError(error);
          });
      init();
    }
  }, []);

  return (
    <form id="start-date" onSubmit={handleSubmit}>
      {/* <ThemedTitle>When should we start your service?</ThemedTitle>
      <br /> */}
      <Box className={classes.mainDiv}>
        <Box>
          {/* <Box>
            {window.screen.width >= 720 ? <FieldTitle required>Select date</FieldTitle> : <Box style={{ marginTop: 50 }} />}
          </Box> */}
          <Box style={{ marginTop: 20 }} />
          <Field
            name="start_date"
            placeholder={moment(blackoutDates.start).format("ddd, MMM DD, yyyy")}
            label={fieldTitles?.startDate.start_date}
            component={InputFields}
            type="datePicker"
            inputFormat="EEE, MMM dd, yyyy"
            minDate={new Date(blackoutDates.start + "T00:00:00")}
            maxDate={new Date(blackoutDates.end + "T00:00:00")}
            shouldDisableDate={disabledDates}
          />
        </Box>
      </Box>
      <Box className={classes.forContinueAndTooltop}>
        <Box>
          <ThemedButton
            isSubmitting={submitting}
            type="submit"
            disabled={invalid || disContinue}
          >
            Continue
          </ThemedButton>
        </Box>
        <Box>
          {screenDetail && screenDetail.tooltip && (
            <GCTooltip>{screenDetail.tooltip}</GCTooltip>
          )}
        </Box>
      </Box>
    </form>
  );
};

StartService = reduxForm({
  // a unique name for the form
  initialValues: getInitFields("start-date"),
  destroyOnUnmount: false,
  validate,
  form: "startService",
})(StartService);

export default StartService;

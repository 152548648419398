import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_DEV_API_ENDPOINT
});

export const axiosProd = axios.create({
    baseURL: process.env.REACT_APP_PROD_API_ENDPOINT,
})

export default axiosInstance;


export function autoPayOpt() {
    let autoPay = localStorage.getItem("autoPayOption");
    autoPay = autoPay && String(autoPay);
    autoPay = autoPay === "enable" ? true : false;
    return autoPay;
}

export function depositWaiverOpt() {
    let depositWaiver = localStorage.getItem("depositWaiverOption");
    depositWaiver = depositWaiver && String(depositWaiver);
    depositWaiver = depositWaiver === "enable" ? true : false;
    return depositWaiver;
}
import { useEffect } from "react";
import { Typography } from "@mui/material";

const DepositIFrame = ({ iframeConfig, setSubRes, auto_pay, deposit_waiver, err }) => {

    const getResult = (e) => {
        let jsonData = e.data;
        if (typeof jsonData !== "undefined") {
            if (
                typeof jsonData === "object" &&
                jsonData.constructor.name === "Object"
            ) {
                let source = jsonData.source;
                //Should check the source system. Vendor should return it in the response JSON.
                if (typeof source != "undefined" && source === "iCheck") {
                    let transactionResponse = null;
                    try {
                        let matches = jsonData.transactionResponse.match('|');
                        if (matches[0].length > 0) {
                            transactionResponse = typeof jsonData.transactionResponse != "undefined" && jsonData.transactionResponse.split('|')[0] === 'DECLINED';
                        } else {
                            let matches = jsonData.transactionResponse.match(' - ');
                            if (matches[0].length > 0) {
                                transactionResponse = typeof jsonData.transactionResponse != "undefined" && jsonData.transactionResponse.split(' - ')[0] === 'DECLINED';
                            }
                        }
                    } catch (e) {
                        window.NREUM.noticeError(e);
                        transactionResponse = false;
                    }
                    if (
                        (typeof jsonData.error !== "undefined" && jsonData.error !== null) ||
                        transactionResponse
                    ) {
                        let declineData = localStorage.getItem("paymentDeclineMessage");
                        let credit_card_err_msg = localStorage.getItem("creditCardErrMsg") || "We could not process your credit card";
                        window.NREUM.noticeError("iCheck Gateway Error:", jsonData.error || jsonData.transactionResponse);
                        setSubRes({ status: false, message: `${(iframeConfig && iframeConfig.amount) ? JSON.parse(declineData) : credit_card_err_msg}` });
                    } else {
                        setSubRes({ status: true, res: jsonData });

                    }
                }
            }
        }
    }

    useEffect(() => {
        const submitPaymentDetails = async () => {
            if (window.addEventListener) {
                window.addEventListener("message", getResult);
            } else {
                window.attachEvent("onmessage", function (e) {
                });
            }
        }
        if (deposit_waiver !== undefined) {
            submitPaymentDetails();
        }
        return () => { window.removeEventListener("message", () => null) }
    }, [iframeConfig])

    if (iframeConfig && iframeConfig.url) {
        return (
            <>
                <Typography color="red"> {err && err}</Typography>
                <iframe
                    title="Credit Card"
                    id="iFrameCC"
                    name="iFrameCC"
                    src={iframeConfig.url}
                    frameborder="0"
                    scrolling="no"
                    width="320"
                    height="600"
                />
            </>
        )
    } else return null
}

export default DepositIFrame;


import logo from "./assets/logo.png";
import menuIcon from "./assets/menuIcon.svg";
import { useNavigate, Route, Routes } from "react-router-dom";
import "./App.css";
import SignupWizard from "./pages/SignupForms/Signup";
import { Box, CircularProgress, IconButton, Typography, Tooltip } from "@mui/material";
import PhoneIcon from 'src/assets/phone.svg';
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import reload from "src/assets/reload2.svg";
import ConfirmationModal from "./Components/ConfirmationModal";
import NoPlans from "./Components/NoPlans";
import getTokens from "./utils/getTokens";
import PlanDocuments from "src/Components/PlanDocuments"
import appInfo from "./app_info.json"

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    display: "flex",
  },
  wizard: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      // width: "60%",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      padding: "8px 2%",
      paddingTop: 10,
    },
  },
  menuAndLogo: {
    display: "flex",
    alignItems: "center"
  },
  menu: {
    marginRight: 10
  },
  logo: {
    display: "block",
    opacity: 0,
    width: "120px",
    height: "18px",
    marginLeft: 0,
    cursor: 'pointer',
    [theme.breakpoints.up("md")]: {
      opacity: 1,
      // marginLeft: "13%",
      width: "202px",
      height: "30px",
    },
    PointerEvent: "none",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  phoneAndReloadBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // backgroundColor: "yellow",
    width: 120,
    [theme.breakpoints.up("md")]: {
      width: 300
    }
  },
  phoneComponent: {
    display: "none",
    alignItems: "center",
    flex: 1,
    [theme.breakpoints.up("md")]: {
      display: "flex",
    }
  },
  phoneComponent2: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    position: "absolute",
    bottom: 15,
    left: "50%",
    transform: "translate(-50%, 0)",
    [theme.breakpoints.up("md")]: {
      display: "none",
    }
  },
  phoneLogo: {
    backgroundColor: '#FFE9EE',
    height: 18,
    width: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    [theme.breakpoints.up("md")]: {
      height: 24,
      width: 24,
    }
  },
  phoneNumberText: {
    fontSize: "13px !important",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px !important",
    }
  },
  reloadBtn: {
    backgroundColor: "rgba(230, 230, 230, 0.3) !important",
    width: 36,
    height: 36,
    [theme.breakpoints.up("md")]: {
      width: 42,
      height: 42,
    }
  },
  reloadIcon: {
    width: 25,
    height: 25,
    [theme.breakpoints.up("md")]: {
      width: 25,
      height: 25,
    }
  }
}));

const initModal = {
  cacheClear: false
}

function App() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(initModal);
  const homeURL = JSON.parse(localStorage.getItem("homeURL"));

  const handleClearCache = async () => {
    navigate("/zip-code")
    localStorage.clear();
    sessionStorage.clear();
    let new_version_info = appInfo.version;
    localStorage.setItem("versionInfo", JSON.stringify(new_version_info))
    setModal({ ...initModal, cacheClear: false });
    await getTokens();
    setIsLoading(false);
  }

  useEffect(() => {
    const initialize = async () => {
      let versionInfo = localStorage.getItem("versionInfo");
      let tokensForV = localStorage.getItem("tokens");
      if (tokensForV) {
        if (versionInfo) {
          if (JSON.parse(versionInfo) !== appInfo.version) {
            setModal({ ...initModal, cacheClear: true });
          }
          else {
            await getTokens();
            setIsLoading(false);
          }
        }
        else {
          setModal({ ...initModal, cacheClear: true });
        }
      }
      else {
        localStorage.setItem("versionInfo", JSON.stringify(appInfo.version))
        await getTokens();
        setIsLoading(false);
      }
    }
    initialize();
  }, [])

  const PhoneNumberComponent = ({ position }) => {
    return (
      <Box className={position === "top" ? classes.phoneComponent : classes.phoneComponent2}>
        <Box className={classes.phoneLogo} >
          <img src={PhoneIcon} alt="Phone" />
        </Box>
        <Box width={8} />
        <Typography variant="body2" className={classes.phoneNumberText} style={{ fontWeight: 600 }}>
          {JSON.parse(localStorage.getItem("contactNumber"))}
        </Typography>
      </Box>
    )
  }

  return (
    <Box>
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // bgcolor: "yellow",
          }}
        >
          <CircularProgress />
        </Box>
      )
        : (
          <Box
            sx={{
              justifyContent: "center",
              // px: 2,
            }}
          >
            {window.location.pathname !== '/document' && <Box className={classes.header}>
              <Box className={classes.menuAndLogo}>
                <Tooltip title="Home">
                  <IconButton onClick={() => window.open(homeURL, "_self")} >
                    <img src={menuIcon} className={classes.menu} alt="menu-icon" />
                  </IconButton>
                </Tooltip>
                <img src={logo} className={classes.logo} alt="logo" onClick={() => window.open(homeURL, "_self")} />
              </Box>
              <Box className={classes.phoneAndReloadBox}>
                <PhoneNumberComponent position="top" />
                {/* <Box width={66} /> */}
                <Tooltip title="Go to start">
                  <IconButton
                    onClick={() => {
                      localStorage.removeItem("last_submitted");
                      navigate("/zip-code");
                    }}
                    className={classes.reloadBtn}
                  >
                    <img src={reload} alt="reload" className={classes.reloadIcon} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>}
            <Box className={classes.root}>
              <Box className={classes.wizard}>
                <Routes>
                  <Route path="/" element={<SignupWizard />} />
                  <Route path="/:step" element={<SignupWizard />} />
                  <Route path="/no-plans" element={<NoPlans />} />
                  <Route path="/document" element={<PlanDocuments />} />
                </Routes>
              </Box>
            </Box>
          </Box>
        )}
      <ConfirmationModal
        open={modal.cacheClear}
        handleClose={() => null}
        title="Version Mismatch"
        agree={{ title: "Continue", onClick: handleClearCache }}
      // disagree={{ title: null, onClick: null }}
      >
        It appears there is an update to the signup journey. You need to start the signup journey again.
      </ConfirmationModal>
    </Box>
  );
}

export default App;

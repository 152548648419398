import { getPartnerLabel } from "./getPartnerID";

const getFieldValues = async (values) => {
  let last_submitted = null;
  let redirctTo = null;
  if (values.screen_id <= 4) {
    redirctTo = fields[4].name;
    last_submitted = fields[4].name;
  }
  else {
    sessionStorage.setItem("added_email", 1)
    redirctTo = fields[values.screen_id].name;
    last_submitted = fields[values.screen_id - 1].name;
  }
  const saveData = async (name, fieldArray, url) => {

    let json = {};
    switch (name) {
      case "zip-code":
        json = {
          zip_code: values.zip_code,
          promo_code: values.promo_code ? values.promo_code : null,
        };
        await localStorage.setItem(name, JSON.stringify(json));
        break;

      case "plan":
        json = {
          plan_id: values.plan_id,
          plan_name: values.plan_name,
          plan_amount: values.plan_amount
        }
        await localStorage.setItem(name, JSON.stringify(json));
        break;

      case "basic-info":
        json = {
          first_name: values.first_name,
          last_name: values.last_name
        }
        await localStorage.setItem(name, JSON.stringify(json));
        break;

      case "contact-details":
        json = {
          email: values.email,
          phone_number: `+1${values.phone_number}`,
          sms_communication: values.sms_communication === 1 ? true : false,
          marketing_communication: values.marketing_communication === 1 ? true : false,
        }
        await localStorage.setItem(name, JSON.stringify(json));
        sessionStorage.setItem("added_email", 1)
        break;

      case "pet-type":
        json = {
          pet_type: values.pet_type,
          pet_sex: values.pet_sex,
          has_pet: values.has_pet
        }
        await localStorage.setItem(name, JSON.stringify(json));
        break;

      case "pet-name":
        json = {
          pet_name: values.pet_name,
          pet_breed: values.pet_breed
        }
        await localStorage.setItem(name, JSON.stringify(json));
        break;

      case "pet-age":
        json = {
          pet_year: values.pet_year,
          pet_month: values.pet_month,
          pet_weight: values.pet_weight
        }
        await localStorage.setItem(name, JSON.stringify(json));
        break;

      case "address":
        json = {
          address: values.address,
          esiid: values.esiid,
          pet_address: values.pet_address,
          apt_unit: values.apt_unit,
          street: values.street,
          city: values.city,
          state: values.state,
          lat: values.lat,
          lon: values.lon
        }
        await localStorage.setItem(name, JSON.stringify(json));
        break;

      case "switching":
        json = {
          moving_switching: values.moving_switching === 1 ? "moving" : "switching",
        }
        await localStorage.setItem(name, JSON.stringify(json));
        break;

      case "start-date":
        json = {
          start_date: values.start_date,
        }
        await localStorage.setItem(name, JSON.stringify(json));
        if (values.distribution_point_response) {
          await localStorage.setItem("distribution_point_response", values.distribution_point_response)
        }
        break;

      case "partner":
        let partnerLabel = getPartnerLabel(values.partner_id);
        json = {
          partner: partnerLabel,
          match: values.match === 1 ? true : false,
          general_rescue: values.gc_general_rescue === 1 ? true : false
        }
        await localStorage.setItem(name, JSON.stringify(json));
        break;

      case "credit-check":
        json = {
          dob: values.dob,
          social_security_number: values.social_security_number,
          driving_liscence: values.driving_liscence,
          credit_check: values.credit_check === 1 ? "runCredit" : "skipCredit",
        }
        await localStorage.setItem(name, JSON.stringify(json));
        if (values.credit_check_response) {
          await localStorage.setItem("credit_check_response", values.credit_check_response);
        }
        break;

      case "payment-method":
        json = {
          payment_method: values.payment_method,
          auto_pay: values.auto_pay,
          eBill: true    // keeping it true as default for now
        }
        await localStorage.setItem(name, JSON.stringify(json));
        break;
      case "payment-details":
        json = {
          payment_token: values.payment_token,
          auto_pay: values.auto_pay ? true : false,
          deposit_waiver: values.depositWaived ? true : false,
          eBill: true    // keeping it true as default for now
        }
        await localStorage.setItem(name, JSON.stringify(json));
        if (values.payment_response) {
          await localStorage.setItem("payment-response", values.payment_response)
        }
      default:
        console.log(name);
        break;
    }

  };

  let tokens = await localStorage.getItem("tokens");
  tokens = JSON.parse(tokens);
  tokens = { ...tokens, uuid: values.uuid };
  tokens = JSON.stringify(tokens);
  await localStorage.setItem("tokens", tokens);

  fields.map(async ({ name, fields, redirctTo }, index) => {
    if (values.screen_id > index) {
      await saveData(name, fields, redirctTo);
    } else {
      console.log("done");
      return null;
    }
  });
  localStorage.setItem("last_submitted", last_submitted)
  await localStorage.setItem("current_page", redirctTo);
  window.location.href = `/${redirctTo}`;
};

export default getFieldValues;

const fields = [
  {
    name: "zip-code",
    fields: [{ title: "zip_code" }, { title: "promo_code" }],
  },
  {
    name: "plan",
    fields: [{ title: "plan_id" }, { title: "plan_name" }, { title: "plan_amount" }],
  },
  {
    name: "basic-info",
    fields: [{ title: "first_name" }, { title: "last_name" }],
  },
  {
    name: "contact-details",
    fields: [{ title: "email" }, { title: "phone_number" }, { title: "sms_communication" }, { title: "marketing_communication" }],
  },
  {
    name: "pet-type",
    fields: [{ title: "pet_type" }, { title: "pet_sex" }],
  },
  {
    name: "pet-name",
    fields: [{ title: "pet_name" }, { title: "pet_breed" }, { title: "has_pet" }],
  },
  {
    name: "pet-age",
    fields: [
      { title: "pet_year" },
      { title: "pet_month" },
      { title: "pet_weight" },
    ],
  },
  {
    name: "address",
    fields: [{ title: "address" }, { title: "apt_unit" }, { title: "street" }, { title: "city" }, { title: "state" }, { title: "lat" }, { title: "lon" }],
  },
  {
    name: "switching",
    fields: [{ title: "moving_switching" }],
  },
  {
    name: "start-date",
    fields: [{ title: "start_date" }],
  },
  {
    name: "partner",
    fields: [{ title: "partner" }, { title: "match" }, { title: "general_rescue" }],
  },
  {
    name: "credit-check",
    fields: [
      { title: "dob" },
      { title: "social_security_number" },
      { title: "driving_liscence" },
      { title: "credit_check" },
      { title: "credit_score" },
    ],
  },
  {
    name: "payment-method",
    fields: [
      { title: "payment_type" },
      { title: "auto_pay" },
      { title: "eBill" }
    ],
  },
  {
    name: "payment-details",
    fields: [{ title: "payment_token" }]
  },
  {
    name: "review",
    fields: []
  },
];

import { Radio, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Field, reduxForm } from "redux-form";
import InputFields, {
  FieldTitle,
  ThemedButton,
  ThemedTitle,
} from "src/Components/InputFields";
import getInitFields from "src/utils/getInitFields";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import getScreenDetail from "src/utils/screenDetails";
import GCTooltip from "src/Components/GCTooltip";
import moment from "moment";

let today = new Date().getTime();
let ssnRx = /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
let ssnRxMasked = RegExp(String(ssnRx).replaceAll('-','').replaceAll('/',''));

const fieldErrors = JSON.parse(localStorage.getItem("fieldErrors"));
const fieldTitles = JSON.parse(localStorage.getItem("fieldTitles"));

const validationSchema = Yup.object().shape({
  dob: Yup.string().trim().nullable()
    .test("18+ check", fieldErrors?.creditCheck.dob.eighteenYears, value => {                  // 18+ age check
      let today = new Date();
      let birthDate = new Date(value);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      let check = age && age >= 18;
      return check
    })
    .test("Check future date", fieldErrors?.creditCheck.dob.futureDates, value => {          // checking future date
      let newVal = new Date(value).getTime();
      let check = newVal < today;
      return check;
    })
    .test("Check possible past dates", fieldErrors?.creditCheck.dob.validDob, value => {          // checking future date
      let today = new Date();
      let birthDate = new Date(value);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      let check = age && age <= 120;
      return check;
    })
    .test("date-format", fieldErrors?.creditCheck.dob.validDob, value => moment(value).isValid())     // date validation
    .required(fieldErrors?.required),                                                                    // required
  social_security_number: Yup.string()
  .when('run_credit_check', {
    is: "runCredit",
    then: Yup.string().trim().nullable().test("length", fieldErrors?.creditCheck.ssn.nineDigits, (value) => {
      let formatted = value && value.replaceAll(/(\D+)/g, "")
      let err = formatted && formatted.length !== 9 ? false : true
      return err;
    }).test("correct ssn", fieldErrors?.creditCheck.ssn.correctSsn, (value)=>{
      let check = ssnRx.test(value) || ssnRxMasked.test(value);
      return check;
    }).required(fieldErrors?.required)
  }).nullable(),
  run_credit_check: Yup.string().trim().nullable().required(fieldErrors?.required),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

const useStyles = makeStyles((theme) => ({
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
}));

let CreditCheck = (props) => {
  const { handleSubmit, submitting, invalid, change } = props;
  const classes = useStyles();

  const [screenDetail, setScreenDetail] = useState(null);
  const [visibleSSN, setVisibleSSN] = useState(false);
  const [disableSSN, setDisableSSN] = useState(false);

  useEffect(() => {
    const init = async () => {
      let details = await getScreenDetail("credit-check");
      setScreenDetail(details);
    };
    init();
  }, []);

  const title =
    "We’ll need to verify your identity to perform a soft credit check and determine if a deposit is required.";


  return (
    <form id="credit-check" onSubmit={handleSubmit}>
      {/* <ThemedTitle>{title}</ThemedTitle> */}

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box sx={{ justifyContent: "start" }} style={{marginTop: 20}}>
          {/* <FieldTitle required>Date of birth</FieldTitle> */}
          <Field
            name="dob"
            placeholder="mm/dd/yyyy"
            label={fieldTitles?.creditCheck.dob}
            component={InputFields}
            type="datePicker"
            inputFormat="MM/dd/yyyy"
            minDate={moment().subtract(120, "years")._d}
            maxDate={moment().subtract(18, "years")._d}
          />
          <Box style={{ marginTop: 5, marginBottom: 0, marginLeft: 10 }}>
            <Typography variant="body2" style={{ fontSize: 12, fontWeight: 600, color: '#21212185' }}>
              Must be 18 years of age to continue
            </Typography>
          </Box>

          {/* <br /> */}

          {/* <FieldTitle required>Your social security number</FieldTitle> */}
          <Box style={{marginTop: 20}} />
          <Field
            name="social_security_number"
            placeholder="XXX-XX-XXXX"
            label={fieldTitles?.creditCheck.ssn}
            component={InputFields}
            type="password"
            visible={visibleSSN}
            setVisible={setVisibleSSN}
            onBlur={()=>{setVisibleSSN(false)}}
            onFocus={()=>{setVisibleSSN(true)}}
            disabled={disableSSN}
          />

          {/* <br />

          <FieldTitle required>Driving Licence Number</FieldTitle>
          <Field
            name="driving_licence"
            placeholder="Driving Licence Number"
            component={InputFields}
            type="textField"
          /> */}

          <br />
          <br />

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Field
              component={InputFields}
              type="radio"
              value="runCredit"
              name="run_credit_check"
              onClick={(e)=>{
                if(e.target.checked){
                  setDisableSSN(false)
                }
              }}
            />
            <Box sx={{ width: 10 }} />
            <Typography variant="body1">Run credit check using SSN</Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Field
              component={InputFields}
              type="radio"
              value="skipCredit"
              name="run_credit_check"
              onClick={(e)=>{
                if(e.target.checked){
                  setDisableSSN(true);
                  change("social_security_number", null);
                }
              }}
            />
            <Box sx={{ width: 10 }} />
            <Typography variant="body1">
              Skip credit check and pay deposit
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.forContinueAndTooltop}>
        <Box>
          <ThemedButton
            isSubmitting={submitting}
            disabled={invalid}
            type="submit"
          >
            Continue
          </ThemedButton>
        </Box>
        <Box>
          {screenDetail && screenDetail.tooltip && (
            <GCTooltip>{screenDetail.tooltip}</GCTooltip>
          )}
        </Box>
      </Box>
    </form>
  );
};

CreditCheck = reduxForm({
  initialValues: getInitFields("credit-check"),
  validate,
  destroyOnUnmount: false,
  form: "CreditCheck",
})(CreditCheck);

export default CreditCheck;

import Crypto from 'crypto-js';

const privateKey = process.env.REACT_APP_CRYPTO_KEY || "Testing";

const decryptCustData = () => {
    let customerData = localStorage.getItem("customerData");
    let parsedCustData = null;
    if (customerData) {
        let bytes = Crypto.AES.decrypt(customerData, privateKey);
        let custDataStr = bytes.toString(Crypto.enc.Utf8);
        parsedCustData = JSON.parse(custDataStr) || null;
        return parsedCustData;
    }
    return null;
}

const encryptCustData = (data) => {
    if (data) {
        let dataString = JSON.stringify(data);
        let ciphertext = Crypto.AES.encrypt(dataString, privateKey).toString();
        return ciphertext;
    }
    return null;
}

const get = (key) => {
    if (!key) {
        return null;
    }
    let custData = decryptCustData();
    if (custData) {
        return custData[key] || null;
    }
    return null;
}

const save = (key, data) => {
    if (!key) {
        return null;
    }
    let parsedCustData = decryptCustData();
    let newCustomerData = {
        ...parsedCustData,
        [key]: data
    };
    let ciphertext = encryptCustData(newCustomerData);
    localStorage.setItem("customerData", ciphertext);
}

const remove = (key) => {
    if (!key) {
        return null;
    }
    let custData = decryptCustData();
    if (typeof (custData) === 'object') {
        delete custData[key];
        if (!custData.length) {
            localStorage.removeItem("customerData");
        } else {
            let ciphertext = encryptCustData(custData);
            localStorage.setItem("customerData", ciphertext);
        }
    }
}

const cryptoJS = { get, save, remove };

export default cryptoJS;
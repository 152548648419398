import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Typography,
  FormHelperText,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Radio,
  InputAdornment,
  IconButton,
  InputLabel
} from "@mui/material";
import { useSwitch } from "@mui/base/SwitchUnstyled";
import clsx from "clsx";
import { styled } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { AccessTime } from "@mui/icons-material";

import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import arrowRight from "src/assets/arrow-right.png";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "react-phone-input-2/lib/material.css";
import TimePicker from "@mui/lab/TimePicker";
import errorIcon from "src/assets/Error.png";
import {
  // Visibility as VisibilityIcon,
  // VisibilityOff as VisibilityOffIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@mui/icons-material';
import visibilityIcon from "src/assets/pass-visible.svg"
import visibilityOffIcon from "src/assets/pass-hidden.svg"
import calendarIcon from "src/assets/calendarIcon.svg";
import MaterialUiPhoneNumber from "material-ui-phone-number";

import CheckIcon from "src/assets/checkIcon.svg"

import Theme from "../Theme/theme";



import PickersDay, {
  PickersDayProps,
  pickersDayClasses
} from "@mui/lab/PickersDay";


const renderWeekPickerDay = (
  date,
  selectedDates,
  pickersDayProps
) => {
  return (
    <PickersDay
      {...pickersDayProps}
      sx={{
        [`&&.${pickersDayClasses.selected}`]: {
          backgroundColor: Theme.palette.primary.main
        }
      }}
    />
  );
};


const useStyles = makeStyles((theme) => ({
  InputFields: {
    fontSize: 16,
    height: 48,
    borderRadius: "10px !important",
    width: "338px !important",
    "&::placeholder": {
      color: "rgba(33, 33, 33, 0.52)",
    },
    [theme.breakpoints.up("md")]: {
      width: "350px !important",
    },
  },
  inputTextProps: {
    fontWeight: 600,
    fontSize: 16,
    paddingLeft: 5
  },
  checkbox: {
    marginTop: "-8px !important",
    [theme.breakpoints.up("md")]: {
      marginTop: "0px !important"
    }
  },
  aptUnit: {
    fontSize: 16,
    height: 48,
    borderRadius: "10px !important",
    width: "336px !important",
    "&::placeholder": {
      color: "rgba(33, 33, 33, 0.52)",
    },
    [theme.breakpoints.up("md")]: {
      width: "200px !important",
    },
  },
  reviewField: {
    fontSize: 16,
    height: 48,
    borderRadius: "10px !important",
    width: "170px !important",
    paddingLeft: "0px !important",
    "&::placeholder": {
      color: "rgba(33, 33, 33, 0.52)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "240px !important",
    },
  },
  phoneButton: {
    width: 30,
    height: 30,
    marginTop: 10,
    borderRadius: "10px !important",
  },
  fieldTitle: {
    margin: 8,
    marginTop: 20,
    fontWeight: "bold !important",
    color: "#3A3A3A",
  },
  cardDetCvc: {
    width: 164,
    height: 48,
    [theme.breakpoints.up("md")]: {
      width: 170,
      height: 48,
    },
  },
  submitBtn: {
    height: 48,
    width: 180,
    boxShadow: 3,
    "&.MuiButtonBase-root:hover": {
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.up("md")]: {
      height: 60,
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-around",
    zIndex: 3,
  },
  btnShadow: {
    "@media only screen and (-webkit-min-device-pixel-ratio: 1)": {
      '.ios.bg-overlay': {
        '-webkit-backdrop-filter': "blur('60px')"
      }
    },
    position: "absolute",
    // left: "12.43%",
    left: "11.89%",
    right: "11.89%",
    top: "50%",
    bottom: "-10%",
    background: theme.palette.primary.main,
    opacity: 1,
    filter: "blur(1.125rem)",
    borderRadius: "29px",
    zIndex: 1,
  },
  themedTitleBox: {
    marginTop: 20,
    marginBottom: 0,
    maxWidth: 337,
    [theme.breakpoints.up("md")]: {
      marginBottom: 20,
      marginTop: 30,
      maxWidth: 600
    },
  },
  themedTitle: {
    fontWeight: "bold",
    fontSize: "20px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.28px !important",
    [theme.breakpoints.up("md")]: {
      fontSize: "34px !important",
      lineHeight: "45px !important",
      letterSpacing: "-1px !important",
    },
  },
  errortext: {
    textTransform: "uppercase",
    fontSize: "9px !important",
    lineHeight: 2,
    fontWeigh: 600,
  },
  selectAdornment: {
    marginRight: theme.spacing(3),
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.3em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: Theme.palette.primary.main,
      outline: "0px solid slategrey",
    },
  },
  selectNotFull: {
    // 
  },
  reviewLang: {
    width: 136,
    height: 48,
    [theme.breakpoints.up("md")]: {
      width: 184,
    },
  },
  phoneLabelCss: {
    color: '#adacac',
    fontSize: 12
  },
  phoneLabelCssOnFocus: {
    color: Theme.palette.primary.main,
    fontSize: 12
  },
  fullWidthField: {
    width: "100%"
  },
  renderOpt: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  optionImg: {
    height: 20
  }
}));

const grey = {
  400: "#BFC7CF",
  500: "#AAB4BE",
  600: "#6F7E8C",
};

const BasicSwitchRoot = styled("span")(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 46px;
  height: 25px;
  margin: 10px;
  // background: ${theme.palette.mode === "dark" ? grey[600] : grey[400]};
  background: white;
  border-radius: 16px;
  cursor: pointer;
  border: 1px solid #BFC7CF;

  &.Switch-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.Switch-checked {
    border: 1px solid ${Theme.palette.primary.main} ;
    background: ${Theme.palette.primary.main};
  }
  `
);

const BasicSwitchInput = styled("input")`
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
`;

const BasicSwitchThumb = styled("span")`
  display: block;
  width: 19px;
  height: 19px;
  top: 3px;
  left: 3px;
  border-radius: 16px;
  background-color: #bfc7cf;
  position: relative;
  transition: all 200ms ease;

  &.Switch-focusVisible {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.Switch-checked {
    left: 24px;
    top: 3px;
    background-color: #fff;
  }
`;

function BasicSwitch(props) {
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);

  const stateClasses = {
    "Switch-checked": checked,
    "Switch-disabled": disabled,
    "Switch-focusVisible": focusVisible,
  };

  const handleChange = (e) => {
    getInputProps().onChange(e);
    props.additional(e);
    // console.log(e)
  }

  return (
    <BasicSwitchRoot className={clsx(stateClasses)}>
      <BasicSwitchThumb className={clsx(stateClasses)} />
      <BasicSwitchInput {...getInputProps()} onChange={handleChange} aria-label="Demo switch" />
    </BasicSwitchRoot>
  );
}


const grey1 = {
  400: "#BFC7CF",
  500: "#AAB4BE",
  600: "#6F7E8C",
};

const BasicSwitchRoot1 = styled('span')(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 46px;
  height: 25px;
  margin: 10px;
  background: white;
  border: 2px solid #E1E1E1;
  border-radius: 16px;
  cursor: pointer;

  &.Switch-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.Switch-checked {
    border: 2px solid ${Theme.palette.secondary.main};
    background: ${Theme.palette.secondary.main};
  }
  `,
);

const BasicSwitchInput1 = styled("input")`
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
`;

const BasicSwitchThumb1 = styled("span")`
  display: block;
  width: 19px;
  height: 19px;
  top: 3px;
  left: 3px;
  border-radius: 16px;
  background-color: #E1E1E1;
  position: relative;
  transition: all 200ms ease;

  &.Switch-focusVisible {
    background-color: ${grey1[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.Switch-checked {
    left: 24px;
    top: 3px;
    background-color: #fff;
  }
`;

function BasicSwitch1(props) {
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);

  const stateClasses = {
    "Switch-checked": checked,
    "Switch-disabled": disabled,
    "Switch-focusVisible": focusVisible,
  };

  const handleChange = (e) => {
    getInputProps().onChange(e);
    props.additional(e);
    // console.log(e)
  }

  return (
    <BasicSwitchRoot1 className={clsx(stateClasses)}>
      <BasicSwitchThumb1 className={clsx(stateClasses)} />
      <BasicSwitchInput1 {...getInputProps()} onChange={handleChange} aria-label="Demo switch" />
    </BasicSwitchRoot1>
  );
}

const InputFields = ({
  type,
  input,
  label,
  meta: { touched, invalid, error },
  otherType,
  children,
  placeholder,
  fullWidth,
  customClass,
  required,
  additional,
  visible,
  setVisible,
  ...rest
}) => {
  const [errorMsg, setErrorMsg] = useState(null);
  // const [visible, setVisible] = useState(false);
  const [openCal1, setOpenCal1] = useState(false)
  const [PhoneOnFocus, setPhoneOnFocus] = useState(false);

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const plInputProps = {
    padding: 10,
    fontSize: 16,
    fontFamily: "Nunito",
    borderRadius: 10,
  };

  const toggleVisibility = () => {
    setVisible(!visible)
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  if (errorMsg) {
    setTimeout(() => {
      setErrorMsg(null);
    }, 5000);
  }

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 2].focus();
      event.preventDefault();
    }
  };

  const classes = useStyles();
  const handleChange = (event) => {
    let value = event.target.value;
    // console.log("hey")

    // input.onChange(value);
  };

  if (input.name === "social_security_number") {
    function addDashes(str) {
      let r = /(\D+)/g,
        fThree = '',
        middle = '',
        last4 = '';
      str = str.replace(r, '');
      fThree = str.substr(0, 3);
      middle = str.substr(3, 2);
      last4 = str.substr(5, 4);
      if (visible) {
        if (str.length > 5) {
          str = fThree + '-' + middle + '-' + last4;
        }
        else if (str.length > 3) {
          str = fThree + '-' + middle;
        }
      }
      input.onChange(str)
    }
    addDashes(input.value)
  }

  const inputColor = () => {
    if (input.value && typeof input.value === "string" && input.value.trim() !== "") {
      return "#3A3A3A";
    }
    return "rgba(33, 33, 33, 0.52)";
  };

  const selectProps = {
    sx: { borderRadius: 2, color: inputColor, fontWeight: 600 },
    className: customClass ? classes[customClass] : classes.InputFields
  };

  const selectPropsNotFull = {
    sx: { borderRadius: 2, color: inputColor, fontWeight: 600 },
  };

  const textFieldProps = {
    sx: { borderRadius: 2 },

    className: customClass ? classes[customClass] : classes.InputFields,
  };

  const showError = () => {
    if (error && touched) {
      return true;
    } else if (required && !input.value) {
      return true;
    } else if (required && input.value && input.value.trim() === "") {
      return true;
    }
    return false;
  };

  const ShowErrorIcon = () => {
    if (showError) {
      return <img src={errorIcon} alt="error-icon" />;
    } else return null;
  };

  const [prevPB, setPrevPB] = useState(null);

  switch (type) {
    case "textField":
      return (
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment" style={{ marginTop: -3 }}>{placeholder}</InputLabel>
          <OutlinedInput
            autoComplete="off"
            error={touched && invalid}
            label={placeholder}
            endAdornment={
              <InputAdornment position="end">
                {touched && invalid && <img src={errorIcon} alt="error-icon" />}
              </InputAdornment>
            }
            // InputProps={{
            //   ...textFieldProps
            // }}
            {...textFieldProps}
            inputProps={{
              className: classes.inputTextProps,
            }}
            {...input}
            {...rest}
            onKeyDown={handleEnter}
          />
          <FormHelperText error>
            <Typography className={classes.errortext}>
              {touched && error}
            </Typography>
          </FormHelperText>
        </FormControl>
      );
      break;
    case "textFieldNumber":
      return (
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment" style={{ marginTop: -3 }}>{placeholder}</InputLabel>
          <OutlinedInput
            autoComplete="off"
            error={touched && invalid}
            label={placeholder}
            endAdornment={
              <InputAdornment position="end">
                {touched && invalid && <img src={errorIcon} alt="error-icon" />}
              </InputAdornment>
            }
            {...textFieldProps}
            inputProps={{
              className: classes.inputTextProps,
            }}
            {...input}
            {...rest}
            type="tel"
            onKeyDown={handleEnter}
          />
          <FormHelperText error>
            <Typography className={classes.errortext}>
              {touched && error}
            </Typography>
          </FormHelperText>
        </FormControl>
      );
      break;
    case "password":
      return (
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment" style={{ marginTop: -3 }}>{label}</InputLabel>
          <OutlinedInput
            type={visible ? "text" : "password"}
            autoComplete="off"
            error={touched && invalid}
            placeholder={placeholder}
            label={label}
            endAdornment={
              <Box>
                <IconButton onClick={toggleVisibility} {...rest}>
                  {visible
                    ? <img src={visibilityIcon} alt="visibilityIcon" />
                    : <img src={visibilityOffIcon} alt="visibilityOffIcon" />
                  }
                </IconButton>
              </Box>
            }
            {...textFieldProps}
            inputProps={{
              className: classes.inputTextProps,
            }}
            {...input}
            {...rest}
            onKeyDown={handleEnter}
          />
          <FormHelperText error>
            <Typography className={classes.errortext}>
              {touched && error}
            </Typography>
          </FormHelperText>
        </FormControl>
      )
      break;
    case "passwordNumber":
      return (
        <FormControl variant="outlined">
          <OutlinedInput
            type={visible ? "password" : "tel"}
            autoComplete="off"
            error={touched && invalid}
            placeholder={placeholder}
            endAdornment={
              <Box>
                <IconButton onClick={toggleVisibility}>
                  {visible
                    ? <img src={visibilityIcon} alt="visibilityIcon" />
                    : <img src={visibilityOffIcon} alt="visibilityOffIcon" />
                  }
                </IconButton>
              </Box>
            }
            {...textFieldProps}
            inputProps={{
              className: classes.inputTextProps,
            }}
            {...input}
            {...rest}
            onKeyDown={handleEnter}
          />
          <FormHelperText error>
            <Typography className={classes.errortext}>
              {touched && error}
            </Typography>
          </FormHelperText>
        </FormControl>
      )
      break;
    case "phoneNumber":
      return (
        <FormControl variant="outlined">
          <ReactPhoneInput
            autoComplete="off"
            country={"us"}
            onlyCountries={["us"]}
            inputExtraProps={{
              required: true,
              autoFocus: true,
            }}
            placeholder={placeholder}
            specialLabel={<span className={PhoneOnFocus ? classes.phoneLabelCssOnFocus : classes.phoneLabelCss}>{placeholder}</span>}
            containerClass={classes.InputFields}
            buttonClass={classes.phoneButton}
            dropdownStyle={{ borderRadius: "10px !important" }}
            label={placeholder}
            countryCodeEditable={false}
            {...input}
            {...rest}
            {...textFieldProps}
            onKeyDown={handleEnter}
            onFocus={() => setPhoneOnFocus(true)}
            onBlur={() => setPhoneOnFocus(false)}
          />
          <Box sx={{ position: "absolute", right: 15, top: 15 }}>
            {touched && invalid && <img src={errorIcon} alt="error-icon" />}
          </Box>
          <FormHelperText error>
            <Typography className={classes.errortext}>
              {touched && error}
            </Typography>
          </FormHelperText>
        </FormControl>
      );
    case "selectField":
      return (
        <Box>
          <FormControl>
            {fullWidth ? (
              <Select
                size="small"
                displayEmpty
                {...input}
                {...rest}
                onKeyDown={handleEnter}
                // className={classes.InputFields}
                input={<OutlinedInput />}
                IconComponent={KeyboardArrowDownIcon}
                {...selectProps}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      marginTop: 0.5,
                      borderRadius: 3,
                      bgcolor: 'white',
                      color: '#21212185',
                      '& .MuiMenuItem-root': {
                        margin: 1,
                        padding: 1,
                        borderRadius: 3,
                      },
                      '& .Mui-selected': {
                        color: 'black',
                        backgroundColor: '#6F6C6C0D'
                      },
                      '& .MuiTouchRipple-root': {
                        color: 'black'
                      }
                    },
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 250,
                    }
                  },
                }}
              >
                <MenuItem disabled value="">
                  {placeholder}
                </MenuItem>
                {children}
              </Select>
            ) : (
              <Select
                size="small"
                displayEmpty
                {...input}
                {...rest}
                input={<OutlinedInput />}
                IconComponent={KeyboardArrowDownIcon}
                {...selectPropsNotFull}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      marginTop: 0.5,
                      borderRadius: 3,
                      bgcolor: 'white',
                      color: '#21212185',
                      '& .MuiMenuItem-root': {
                        margin: 1,
                        padding: 1,
                        borderRadius: 3,
                      },
                      '& .Mui-selected': {
                        color: 'black',
                        backgroundColor: '#6F6C6C0D'
                      },
                      '& .MuiTouchRipple-root': {
                        color: 'black'
                      }
                    },
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    }
                  },
                }}
              >
                <MenuItem disabled value="">
                  {placeholder}
                </MenuItem>
                {children}
              </Select>
            )}
            <FormHelperText error>
              <Typography className={classes.errortext}>
                {touched && error}
              </Typography>
            </FormHelperText>
          </FormControl>
        </Box>
      );
    case "selectSearch":
      return (
        <Box style={{ display: "flex" }}>
          <FormControl style={{ width: "100%" }}>
            {fullWidth ? (
              <Autocomplete
                value={input.value}
                onChange={(event, newValue) => input.onChange(newValue)}
                getOptionSelected={(option, value) => {
                  return option === value || option === input.value;
                }}
                autoComplete
                autoHighlight
                clearOnBlur={false}
                size="small"
                disableClearable={input.value ? false : true}
                // {...input}
                {...rest}
                {...selectProps}
                onKeyDown={handleEnter}
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      marginTop: 0.5,
                      borderRadius: 3,
                      bgcolor: 'white',
                      color: '#21212185',
                      '& .MuiMenuItem-root': {
                        margin: 1,
                        padding: 1,
                        borderRadius: 3,
                      },
                      '& .Mui-selected': {
                        color: 'black',
                        backgroundColor: '#6F6C6C0D'
                      },
                      '& .MuiTouchRipple-root': {
                        color: 'black'
                      }
                    },
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 250,
                    }
                  },
                }}
                renderOption={(props, option, { selected }) => {
                  let optionImg;
                  // for partner selection page
                  // additional?.partners?.map(item => {
                  //   if (item.label === option && item.img_url.includes("src/assets/partners/")) {
                  //     let imgName = item.img_url.replace("src/assets/partners/", "");
                  //     optionImg = require(`src/assets/partners/${imgName}`);
                  //   }
                  // })
                  return (
                    <li {...props}>
                      <div className={classes.renderOpt}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {option} &nbsp;
                          {optionImg &&
                            (<img
                              src={optionImg}
                              alt="checkIcon"
                              className={classes.optionImg}
                            />)}
                        </div>
                        {selected && <img src={CheckIcon} alt="checkIcon" />}
                      </div>
                    </li>
                  )
                }}
                renderInput={(params) =>
                  <Box>
                    <InputLabel htmlFor="outlined-adornment" style={{ marginTop: -3 }}>{placeholder}</InputLabel>
                    <OutlinedInput {...params}
                      {...input}
                      label={placeholder}
                      {...params.InputProps}
                      style={{ ...plInputProps }}
                    />
                  </Box>
                }
              />
            ) : (
              <Autocomplete
                value={input.value}
                onChange={(event, newValue) => input.onChange(newValue)}
                getOptionSelected={(option, value) => {
                  return option === value || option === input.value;
                }}
                id="controllable-states-demo"
                autoComplete
                autoHighlight
                size="small"
                disableClearable={input.value ? false : true}
                // {...input}
                {...rest}
                {...selectPropsNotFull}
                onKeyDown={handleEnter}
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      marginTop: 0.5,
                      borderRadius: 3,
                      bgcolor: 'white',
                      color: '#21212185',
                      '& .MuiMenuItem-root': {
                        margin: 1,
                        padding: 1,
                        borderRadius: 3,
                      },
                      '& .Mui-selected': {
                        color: 'black',
                        backgroundColor: '#6F6C6C0D'
                      },
                      '& .MuiTouchRipple-root': {
                        color: 'black'
                      }
                    },
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 250,
                    }
                  },
                }}
                renderInput={(params) => <Box>
                  <InputLabel htmlFor="outlined-adornment" style={{ marginTop: -3 }}>{placeholder}</InputLabel>
                  <OutlinedInput {...params}
                    label={placeholder}
                    {...params.InputProps}
                    style={{ ...plInputProps }}
                    {...input}
                  // InputProps={{
                  //   ...params.InputProps,
                  //   style: { ...plInputProps },
                  // }}
                  />
                </Box>
                }
              />
            )}
            <FormHelperText error>
              <Typography className={classes.errortext}>
                {touched && error}
              </Typography>
            </FormHelperText>
          </FormControl>
        </Box>
      );
    case "checkBox":
      return (
        <div>
          <Box alignItems="center" display="flex" ml={-1}>
            <Checkbox
              className={classes.checkbox}
              checked={input.value}
              onChange={
                input.value === ""
                  ? input.onChange(false)
                  : input.onChange(input.value)
              }
              inputProps={{ "aria-label": "secondary checkbox" }}
              {...input}
              {...rest}
            />
            <Typography className={classes.fieldTitle} color="textSecondary">
              {label}
            </Typography>
          </Box>
        </div>
      );
    case "switch": {
      return (
        <Box display="flex" sx={{ alignItems: "center" }}>
          <BasicSwitch sx={{ ml: -1 }} checked={input.value} additional={additional} {...input} {...rest} />
          <Typography variant="body1"> {placeholder}</Typography>
        </Box>
      );
    }
    case "switch1": {
      return (
        <Box display="flex" sx={{ alignItems: "center" }}>
          <BasicSwitch1 sx={{ ml: -1 }} checked={input.value} additional={additional} {...input} {...rest} />
          <Typography variant="body1"> {placeholder}</Typography>
        </Box>
      );
    }
    case "datePicker":
      return (
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              allowSameDateSelection={true}
              {...input}
              {...rest}
              onKeyDown={handleEnter}
              open={openCal1}
              onOpen={() => setOpenCal1(true)}
              onClose={() => setOpenCal1(false)}
              renderDay={renderWeekPickerDay}
              style={{ borderRadius: 5 }}
              renderInput={(params) => (
                <Box>
                  <InputLabel htmlFor="outlined-adornment" style={{ marginTop: -3 }}>{label}</InputLabel>
                  <OutlinedInput
                    className={classes.InputFields}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={calendarIcon} alt="calendar" />
                      </InputAdornment>
                    }
                    InputAdornmentProps={{
                      position: "start",
                      style: { paddingLeft: 11 },
                    }}
                    // onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    onClick={(e) => {
                      if (e.detail === 1) {
                        setOpenCal1(true)
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value.length === 10 || e.target.value.length === 17) {
                        setOpenCal1(false)
                      }
                      else {
                        setOpenCal1(true)
                      }
                    }}
                    autoComplete="off"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: placeholder,
                    }}
                    label={label}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              )}
            />
          </LocalizationProvider>
          <Box>
            <FormHelperText error>
              <Typography className={classes.errortext}>
                {touched && error}
              </Typography>
            </FormHelperText></Box>
        </FormControl>
      );
    case "radio":
      return (
        <Box>
          <Radio {...input} {...rest} />
        </Box>
      );
    case "timePicker":
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            fullWidth
            id="time-picker"
            format="HH:mm"
            error={touched && invalid}
            helperText={touched && error}
            inputVariant="outlined"
            {...input}
            {...rest}
            onChange={(date) => input.onChange(date)}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            InputProps={{ readOnly: true }}
            keyboardIcon={<AccessTime />}
          />
        </LocalizationProvider>
      );
    default:
      return "Unknown Input Field";
  }
};

export default InputFields;

export function ThemedTitle({ children }) {
  const classes = useStyles();
  return (
    <Box className={classes.themedTitleBox}>
      <Typography className={classes.themedTitle} variant="h4" color="inherit">
        {children}
      </Typography>
    </Box>
  );
}

export function FieldTitle(props) {
  const classes = useStyles();
  return (
    <Box className={classes.fieldTitle}>
      <Typography className={classes.fieldTitle} variant="body2">
        {props.children}{" "}
        {props.required ? (
          <span style={{ color: Theme.palette.primary.main }}>{" *"}</span>
        ) : null}
      </Typography>
    </Box>
  );
}

export function ThemedButton({ children, disabled, isSubmitting, titleOnly, ...rest }) {
  const classes = useStyles();
  return (
    <Box
      sx={{
        display: "flex",
        mt: 4,
        justifyContent: "center",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      <Button
        variant="contained"
        disabled={disabled}
        className={classes.submitBtn}
        {...rest}
        sx={{
          borderRadius: 32,
        }}
      >
        {!isSubmitting ? (
          <>
            <Box className={classes.btnContainer}>
              <Typography sx={{ fontSize: 16, fontWeight: 600, color: "white" }}>
                {children}
              </Typography>
              {!disabled && !titleOnly ? (
                <img
                  src={arrowRight}
                  alt="arrow-left"
                  style={{ objectFit: "contain", marginLeft: 5 }}
                />
              ) : null}
            </Box>
            {!disabled && <Box className={classes.btnShadow} />}
          </>
        ) : (
          <>
            <Box
              className={classes.btnContainer}
              sx={{ color: "#fff", width: "80%", alignItems: "center" }}
            >
              <CircularProgress
                size={18}
                thickness={6}
                sx={{
                  m: 0,
                  mr: 0.5,
                  p: 0,
                }}
                color="inherit"
              />
              <Typography sx={{ fontSize: 16, fontWeight: 600, color: "white" }}>{titleOnly ? children : "Submitting"}</Typography>
              <Box className={classes.btnShadow} />
            </Box>
          </>
        )}
      </Button>
    </Box>
  );
}

import { Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { change, formValueSelector, reduxForm, reset } from "redux-form";
import { connect } from "react-redux";
import {
  FieldTitle,
  ThemedButton,
  ThemedTitle,
} from "src/Components/InputFields";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import Popover from '@mui/material/Popover';

import petDog from "src/assets/pettype/petDog.svg";
import petCat from "src/assets/pettype/petCat.svg";
import maleIcon from "src/assets/pettype/maleIcon.svg";
import femaleIcon from "src/assets/pettype/femaleIcon.svg";
import InfoIcon from '@mui/icons-material/Info';

// import MaleIcon from "@mui/icons-material/Male";
// import FemaleIcon from "@mui/icons-material/Female";

import GCTooltip from "src/Components/GCTooltip";
import { Typography } from "@mui/material";
import { Field } from "redux-form";
import getScreenDetail from "src/utils/screenDetails";
import getInitFields from "src/utils/getInitFields";
import Theme from "src/Theme/theme";
import axios from "src/utils/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  activeTBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75px",
    width: "75px",
    borderRadius: "100%",
    backgroundColor: "#FFF7F8",
  },
  inactiveTBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75px",
    width: "75px",
    borderRadius: "100%",
    backgroundColor: "#F7F7F7",
  },
  activeSBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "68px",
    width: "68px",
    borderRadius: "100%",
    backgroundColor: "#FFF7F8",
    border: "1px solid #FF5A6A",
  },
  inactiveSBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "68px",
    width: "68px",
    borderRadius: "100%",
    backgroundColor: "#E6E6E6",
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    width: '100%',
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  allCyan: {
    color: Theme.palette.secondary.main
  },
  dhpColor: {
    color: '#3A3A3A'
  },
  infoiconColor: {
    color: '#21212185'
  }
}));

const validationSchema = Yup.object().shape({
  pet_type: Yup.string().nullable().when("has_pet", {
    is: 0,
    then: Yup.string().nullable(),
    otherwise: Yup.string().nullable().required("Required")
  }),
  pet_sex: Yup.string().nullable().when("has_pet", {
    is: 0,
    then: Yup.string().nullable(),
    otherwise: Yup.string().nullable().required("Required")
  })
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

let Pet = (props) => {
  const classes = useStyles();
  const { handleSubmit, submitting, invalid, change, reset, pet_type, has_pet, pet_sex } = props;
  const [screenDetail, setScreenDetail] = useState(null);
  const [infoClicked, setInfoClicked] = useState(false);

  const navigate = useNavigate();
  const [anchor, setAnchor] = useState(null);

  const handleNoPet = async () => {

    // reset();
    change("pet_type", null);
    change("pet_sex", null);
    change("has_pet", 0);

    // let tokens = await localStorage.getItem("tokens")
    // tokens = JSON.parse(tokens)
    // let hasNoPetJson = {
    //   uuid: tokens.uuid,
    //   screen_id: 5,
    //   has_pet: 0
    // }
    // try {
    //   let response = await axios.patch("/customer/update", hasNoPetJson);
    //   await localStorage.setItem("pet-type", JSON.stringify({ has_pet: 0 }))
    //   navigate("/address")
    // } catch (e) {
    //   console.log(e)
    // }
  };

  useEffect(() => {
    const init = async () => {
      if (!screenDetail) {
        let scrData = await getScreenDetail("pet-type");
        setScreenDetail(scrData);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (!has_pet && (pet_type || pet_sex)) {
      change("has_pet", 1);
    }
  }, [pet_type, pet_sex])

  const submit = (values) => {
    console.log(values)
  }

  const handlePopoverOpen = (event) => {
    setAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const open = Boolean(anchor);

  return (
    <form id="pet-type" onSubmit={handleSubmit}>
      <Box className={classes.root} >
        <Box sx={{ width: 313 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Box>
              {/* {window.screen.width >= 720 ? <FieldTitle required>Pet type</FieldTitle> : <Box style={{marginTop: 50}} />} */}
              <Box sx={{ marginTop: 1.5 }}>
                <div
                  class="cc-selector"
                  style={{
                    width: 289,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Field
                      component="input"
                      id="dog"
                      type="radio"
                      name="pet_type"
                      value="dog"
                    />
                    <label
                      class="drinkcard-cc dog"
                      for="dog"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={petDog} alt="dog" />
                    </label>
                    <Box sx={{ width: 13 }} />
                    <Typography variant="body2">Dog</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Field
                      component="input"
                      id="cat"
                      type="radio"
                      name="pet_type"
                      value="cat"
                    />
                    <label
                      class="drinkcard-cc cat"
                      for="cat"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={petCat} alt="cat" />
                    </label>
                    <Box sx={{ width: 13 }} />
                    <Typography variant="body2">Cat</Typography>
                  </Box>
                </div>
              </Box>
              <br />

              <Box style={{ display: 'flex', alignItems: 'flex-start', marginLeft: 5 }}>

                <Box style={{ display: 'flex', alignItems: 'center', width: 140 }}>
                  <Typography
                    variant="body2"
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    onClick={handleNoPet}
                    className={Boolean(anchor) || !has_pet ? classes.allCyan : classes.dhpColor}
                    style={{ textDecoration: 'underline', cursor: "pointer" }}
                  >
                    Don’t have a pet?
                  </Typography>
                </Box>

                {/* <Box style={{ display: 'flex', alignItems: 'center', width: 140 }}>
                  <Link to="#" onClick={() => handleNoPet()}>
                    <Typography
                      variant="body2"
                      className={Boolean(anchor) ? classes.allCyan : classes.dhpColor}
                      style={{ textDecoration: 'underline' }}
                    >
                      Don’t have a pet?
                    </Typography>
                  </Link>
                  <InfoIcon
                    style={{ fontSize: 'small', marginLeft: 5, cursor: 'pointer' }}
                    className={Boolean(anchor) ? classes.allCyan : classes.infoiconColor}
                    onClick={openPopover}
                  />
                </Box> */}
                <Popover
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={open}
                  anchorEl={anchor}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                  style={{
                    marginLeft: 16,
                    borderRadius: '8px',
                  }}
                >
                  <Box style={{
                    maxWidth: '246px',
                    padding: '8px 9px 8px 16px',
                    backgroundColor: '#CEF5F1',

                  }}
                  >
                    <Typography
                      variant="body2"
                      color={Theme.palette.secondary.main}
                      fontWeight="600"
                    >
                      If you don't have a pet we will donate your pet perks to a rescue partner on your behalf.
                    </Typography>
                  </Box>
                </Popover>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Box>
              {/* { window.screen.width >= 720 ? <FieldTitle required>Pet sex</FieldTitle> : <Box style={{marginTop:30}} />} */}
              <Box sx={{ marginTop: 3 }}>
                <div
                  class="cc-selector"
                  style={{
                    width: 289,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Field
                      component="input"
                      id="male"
                      type="radio"
                      name="pet_sex"
                      value="male"
                    />
                    <label
                      class="drinkcard-cc male"
                      for="male"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 68,
                        width: 68,
                      }}
                    >
                      <img src={maleIcon} alt="male" />
                    </label>
                    <Box sx={{ width: 13 }} />
                    <Typography variant="body2">Boy</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Field
                      component="input"
                      id="female"
                      type="radio"
                      name="pet_sex"
                      value="female"
                    />
                    <label
                      class="drinkcard-cc female"
                      for="female"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 68,
                        width: 68,
                      }}
                    >
                      <img src={femaleIcon} alt="female" />
                    </label>
                    <Box sx={{ width: 13 }} />
                    <Typography variant="body2">Girl</Typography>
                  </Box>
                </div>
              </Box>
            </Box>
          </Box>

        </Box>
      </Box>
      <Box className={classes.forContinueAndTooltop}>
        <Box>
          <ThemedButton
            isSubmitting={submitting}
            type="submit"
            disabled={invalid}
          >
            Continue
          </ThemedButton>
        </Box>
        <Box>
          {screenDetail && screenDetail.tooltip && (
            <GCTooltip>{screenDetail.tooltip}</GCTooltip>
          )}
        </Box>
      </Box>

      <style jsx>{`
        .cc-selector input {
          margin: 0;
          padding: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }

        .cc-selector-2 input {
          position: absolute;
          z-index: 999;
        }

        // .dog{background-image:url(${petDog});}
        // .cat{background-image:url(${petCat});}

        .cc-selector-2 input:active + .drinkcard-cc,
        .cc-selector input:active + .drinkcard-cc {
          opacity: 0.9;
        }
        .cc-selector-2 input:checked + .drinkcard-cc,
        .cc-selector input:checked + .drinkcard-cc {
          background-color: #fff7f8;
          border: 2px solid #ff5a6a;
          -webkit-filter: none;
          -moz-filter: none;
          filter: none;
        }
        .drinkcard-cc {
          cursor: pointer;
          background-size: contain;
          background-repeat: no-repeat;
          border: 2px solid #e6e6e6;
          background-color: grey;
          border-radius: 100%;
          display: inline-block;
          width: 68px;
          height: 68px;
          -webkit-transition: all 100ms ease-in;
          -moz-transition: all 100ms ease-in;
          transition: all 100ms ease-in;
          -webkit-filter: brightness(1.8) grayscale(1) opacity(0.7);
          -moz-filter: brightness(1.8) grayscale(1) opacity(0.7);
          filter: brightness(1.8) grayscale(1) opacity(0.7);
        }
        .drinkcard-cc:hover {
          -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
          -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
          filter: brightness(1.2) grayscale(0.5) opacity(0.9);
        }
      `}</style>
    </form>
  );
};

Pet = reduxForm({
  // a unique name for the form
  initialValues: getInitFields('pet-type'),
  destroyOnUnmount: false,
  validate,
  form: "pet-info",
})(Pet);

const selector = formValueSelector('pet-info') // <-- same as form name
Pet = connect(
  state => {
    // can select values individually
    const pet_type = selector(state, 'pet_type');
    const has_pet = selector(state, 'has_pet');
    const pet_sex = selector(state, 'pet_sex');
    return {
      pet_type,
      has_pet,
      pet_sex
    }
  }
)(Pet)

export default Pet;

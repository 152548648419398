import { Field, reduxForm } from "redux-form";
import { makeStyles } from "@mui/styles";
import InputFields, {
  FieldTitle,
  ThemedButton,
  ThemedTitle,
} from "src/Components/InputFields";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import getScreenDetail from "src/utils/screenDetails";
import Theme from "src/Theme/theme";
import getInitFields from "src/utils/getInitFields";
import GCTooltip from "src/Components/GCTooltip";
import { useNavigate } from "react-router-dom";
import axios from "src/utils/axios";
import InfoModal from "src/Components/InfoModal";

let title = "Now we’ll need your billing details to set up autopay.";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inputFieldYM: {
    height: 48,
    minWidth: 350,
  },
}));

let BankDetails = (props) => {
  const classes = useStyles();
  const { handleSubmit, submitting, invalid } = props;
  let accTypes = ["Checking", "Savings"];
  const [data, setData] = useState(null);
  const [err, setErr] = useState(null);

  let errorInitVal = { error: false, message: null };
  const [subError, setSubError] = useState(errorInitVal);

  const [screenDetails, setScreenDetails] = useState(null);
  const [iframeConfig, setIframeConfig] = useState(null);
  let tokens = localStorage.getItem("tokens");
  tokens = JSON.parse(tokens);
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      let scrInfo = await getScreenDetail("bank-details", Theme);
      setScreenDetails(scrInfo);
      let basicInfo = await localStorage.getItem("basic-info");
      let credit_check = await localStorage.getItem("credit_check_response");
      basicInfo = JSON.parse(basicInfo);
      credit_check = JSON.parse(credit_check);
      setIframeConfig({
        ...basicInfo,
        amount: credit_check.totalDepositAmount,
        appId: process.env.REACT_APP_ICHECK_APP_ID,
        appSecret: process.env.REACT_APP_ICHECK_APP_SECRET,
        uuid: tokens.uuid
      })
    };
    if (!screenDetails) {
      init();
    }
    const submitPaymentDetails = async () => {
      if (window.addEventListener) {
        window.addEventListener("message", function (e) {
          var jsonData = e.data;
          if (typeof jsonData != "undefined") {
            if (
              typeof jsonData == "object" &&
              jsonData.constructor.name == "Object"
            ) {
              var source = jsonData.source;
              //Should check the source system. Vendor should return it in the response JSON.
              if (typeof source != "undefined" && source === "iCheck") {
                if (
                  typeof jsonData.error != "undefined" &&
                  jsonData.error != null
                ) {
                  setErr(jsonData.error);
                } else {
                  const init = async () => {

                    let tokenPaymentDetails = await localStorage.getItem("tokens");
                    tokenPaymentDetails = JSON.parse(tokenPaymentDetails);
                    const newValues = {
                      // ...values,
                      payment_token: jsonData.token,
                      payment_response: JSON.stringify(jsonData),
                    };
                    // await saveData(newValues);
                    try {
                      let response = await axios.patch(`/customer/update`, {
                        uuid: tokenPaymentDetails.uuid,
                        screen_id: 14,
                        ...newValues,
                      });
                      localStorage.setItem("nextPage", JSON.stringify("review"));
                      localStorage.setItem("last_submitted", "payment-details")
                      navigate("/review")
                      await this.localStorage.setItem(
                        "payment-response",
                        JSON.stringify(jsonData)
                      );
                      await this.localStorage.setItem(
                        "payment-details",
                        JSON.stringify({ payment_token: jsonData.token })
                      );
                    } catch (e) {
                      console.log(e);
                      setSubError({ error: true, message: e.message })
                    }
                    // console.log("form submission:", newValues);

                  };
                  init();
                  setData(jsonData);
                }
                // console.log(jsonData);
              }
            }
          }
        });
      } else {
        window.attachEvent("onmessage", function (e) {
          console.log("Inside onmessage");
        });
      }
    }
    submitPaymentDetails();
  }, []);

  return (
    <form id="payment-details" onSubmit={handleSubmit}>
      <InfoModal open={subError.error} message={subError.message} close={() => setSubError(errorInitVal)} />
      <ThemedTitle>{screenDetails && screenDetails.description}</ThemedTitle>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <FieldTitle required>Name on account</FieldTitle>
          <Field
            component={InputFields}
            variant="outlined"
            type="textField"
            placeholder="Enter your name on account"
            name="name_on_account"
          />
        </div>
        {iframeConfig && (
          <>
            <Typography color="red"> {err && err}</Typography>
            <iframe
              title="Credit Card"
              id="iFrameBA"
              name="iFrameBA"
              src={`https://iframe.icheckgateway.com/iFrameBA.aspx?appId=${iframeConfig.appId}&appSecret=${iframeConfig.appSecret}&custId=${iframeConfig.uuid}&firstName=${iframeConfig.first_name}&lastName=${iframeConfig.last_name}&css=payment{border: 0px; box-shadow: none; background: white}  input[type=submit],input[type=reset]{background: rgb(255, 91, 106); border-radius: 25px; max-width: 128px; height:38; text-transform: capitalize}`}
              frameborder="0"
              scrolling="no"
              width="320"
              height="520"
            />
          </>
        )}
        {/* <div className={classes.mainDiv}>
        <div>
          <FieldTitle required>Account type</FieldTitle>
          <Field
              component={InputFields}
              type="selectField"
              name="account-type"
              placeholder="Choose your acccount type"
              // className={classes.inputFieldYM}
              fullWidth
            >
              {accTypes.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Field>
        </div>

        <div>
          <FieldTitle required>Name on account</FieldTitle>
          <Field
            component={InputFields}
            variant="outlined"
            type="textField"
            placeholder="Enter account name"
            name="firstName"
          />
        </div>

        <div>
          <FieldTitle required>Enter routing number</FieldTitle>
          <Field
            component={InputFields}
            variant="outlined"
            type="textField"
            placeholder="Enter routing number"
            name="firstName"
          />
        </div>

        <div>
          <FieldTitle required>Bank account number</FieldTitle>
          <Field
            component={InputFields}
            variant="outlined"
            type="textField"
            placeholder="Enter account number"
            name="firstName"
          />
        </div>

        <div>
          <FieldTitle required>Confirm bank account number</FieldTitle>
          <Field
            component={InputFields}
            variant="outlined"
            type="textField"
            placeholder="Confirm account number"
            name="firstName"
          />
        </div>

      </div> */}
        <Box sx={{ my: 2, display: "flex", width: 350, padding: "0 10" }}>
          {/* <Field
            name="auto_pay"
            type="switch"
            component={InputFields}
            placeholder="Autopay"
          /> */}
          <Box width={32} />
          {/* <Field
            name="eBill"
            type="switch"
            component={InputFields}
            placeholder="eBill"
          /> */}
        </Box>
        <Box className={classes.forContinueAndTooltop}>
          {/* <Box>
            <ThemedButton
              isSubmitting={submitting}
              disabled={invalid || !data}
              type="submit"
            >
              Continue
            </ThemedButton>
          </Box> */}
          {screenDetails && screenDetails.tooltip && (
            <GCTooltip>{screenDetails.tooltip}</GCTooltip>
          )}
          <Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

BankDetails = reduxForm({
  // a unique name for the form
  initialValues: getInitFields("bank-details"),
  destroyOnUnmount: false,
  form: "bankDetails",
})(BankDetails);

export default BankDetails;

let vars = [
    "+1",
    " ",
    "(",
    ")",
    "-",
]

const formatPhoneNumber = (str) => {
    let newStr = str.replace(1,"");
    // console.log(newStr)
    // vars.map((item) => {
    //     newStr = newStr.replaceAll(item, "");
    // });
    // console.log(newStr)
    return newStr;
}

export default formatPhoneNumber;
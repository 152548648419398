import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Redirect } from "react-router-dom";
import BasicInfo from "./BasicInfo";
import ContactDetails from "./Contacts";
import PetType from "./PetInfo";
import PetName from "./PetName";
import PetAge from "./PetAge";
import StartService from "./StartService";
import Address from "./Address";
import SwitchingProvider from "./SwitchingProvider";
import {
  Box,
  IconButton,
  Dialog,
  Typography,
  Grid,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ShowPlans from "src/pages/plans/ShowPlans";
import Partner from "./Partner_v2";
import CreditCheck from "./CreditCheck";
import PaymentMethod from "./PaymentMethod";
import BankDetails from "./BankDetails";
import CardDetails from "./CardDetails";
import ProgressBar from "src/Components/SignupWizard";
import charlie from "src/assets/charlie-2.png";
import arrowLeft from "src/assets/arrow-left.png";
import GetPlans from "src/pages/plans/ZipCode";
import { ThemedButton, ThemedTitle } from "src/Components/InputFields";
import Review from "src/pages/Review";
import PageNotFound from "src/Components/PageNotFound";
import dialogImg from "src/assets/review-popup.png";
import GCTooltip from "src/Components/GCTooltip";
import axios from "src/utils/axios";
import axiosEndPoint from "axios";
import { useSnackbar } from "notistack";
import ConfirmationModal from "src/Components/ConfirmationModal";
import getFieldValues from "src/utils/getFieldValues";
import moment from "moment";
import Theme from "src/Theme/theme";
import getScreenInfo from "src/utils/screenDetails";
import PaymentDetails from "./PaymentDetails";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import reload from "src/assets/reload.svg";
import NoPlans from "src/Components/NoPlans";
import formatPhoneNumber from "src/utils/formatPhoneNumber";

import dialogImg2 from "src/assets/reviewMobileImage.png";
import accountCreation from "src/utils/accountCreation";
import saveDistributionPoints from "src/utils/saveDistributionPoints";
import getInitFields from "src/utils/getInitFields";
import handleSubmit from "src/utils/handleSubmit";
import InfoModal from "src/Components/InfoModal";
import getTokens from "src/utils/getTokens";
import { autoPayOpt } from "src/utils/getPaymentOptions";

import { updateUtmCookiesFields, updateUtmCookiesUTMContent, uploadUTMs } from "src/utils/utmCookies";

const steps = [
  "zip-code",
  "plans",
  "basic-info",
  "contact-details",
  "pet-type",
  "pet-name",
  "pet-age",
  "address",
  "switching",
  "start-date",
  "partner",
  "credit-check",
  "payment-method",
  "payment-details",
  // "bank-details",
  // "card-details",
  "review",
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // overflow: "hidden",
    paddingBottom: 100,
  },
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
  },
  divider: {
    borderColor: "#F5F4F4",
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 50,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginBottom: 70,
    },
  },
  cancel: {
    top: 10,
    right: 10,
    position: "absolute",
  },
  wizard: {
    minWidth: "100%",
    flex: 1,
    flexGrow: 1.5,
    marginTop: theme.spacing(2),
  },
  iconButton: {
    position: "absolute",
    top: "5rem",
    left: 32,
    [theme.breakpoints.up("md")]: {
      left: "10%",
      height: "72px",
      top: "17rem",
    },
  },
  backIcon: {
    height: "30px",
    objectFit: "contain",
    [theme.breakpoints.up("md")]: {
      height: "72px",
    },
  },
  tint4: {
    position: "absolute",
    top: -36,
    width: 62,
    height: 62,
    padding: 6,
    [theme.breakpoints.up("md")]: {
      top: -58,
      padding: 12,
      width: 100,
      height: 100,
    },
    backgroundColor: "rgba(255, 233, 238, 0.5)",
    borderRadius: 100,
    animation: `$pulse 1.2s infinite ease-out`,
  },
  tint3: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFDEE1",
    borderRadius: 100,
  },
  avatar: {
    width: "100%",
    height: "100%",
    borderRadius: 100,
  },
  submitBtn: {
    height: 60,
    width: 180,
    boxShadow: 3,
    "&.MuiButtonBase-root:hover": {
      background: theme.palette.primary.main,
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "70%",
    zIndex: 3,
  },
  btnShadow: {
    position: "absolute",
    left: "12.43%",
    right: "11.89%",
    top: "25%",
    bottom: "0%",
    background: theme.palette.primary.main,
    opacity: 1,
    filter: "blur(40px)",
    borderRadius: "29px",
    zIndex: 1,
  },
  themeText: {
    color: theme.palette.secondary.main,
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  "@keyframes pulse": {
    "0%": {
      // transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(255, 233, 238, 0.5)",
    },

    "70%": {
      // transform: "scale(1)",
      boxShadow: "0 0 0 10px rgba(255, 233, 238, 0.5)",
    },

    "100%": {
      // transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(255, 233, 238, 0.5)",
    },
  },
  dialogImg: {
    height: "100%",
    width: "100%",
    borderRadius: "11px",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      width: "100%",
      // objectFit: 'fill'
    },
  },
  reviewModal: {
    height: 458,
    width: 328,
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      height: 500,
      width: 980,
      overflow: "hidden",
    },
  },
  imgBox: {
    overflow: "hidden",
    borderRadius: 8,
    height: "189px",
    margin: "12.5px 14px 0px 14px",
    [theme.breakpoints.up("md")]: {
      margin: "16px 17px 16px 17px",
      height: "468px",
      width: "458px",
    },
  },
  dialogContent: {
    padding: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "90%",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      height: "100%",
    },
  },
  reviewBox1: {
    textAlign: "center",
    height: 142,
    width: 268,
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      height: "135px",
      width: "390px",
      marginBottom: "120px",
      marginLeft: "15px",
    },
  },
  reviewModalTextHurray: {
    color: Theme.palette.secondary.main,
    fontSize: "20px",
    fontWeight: "700",
    [theme.breakpoints.up("md")]: {
      fontSize: "34px",
      fontWeight: "700",
    },
  },
  reviewModalText1: {
    fontSize: "20px",
    fontWeight: "700",
    [theme.breakpoints.up("md")]: {
      fontSize: "34px",
      fontWeight: "700",
    },
  },
  reviewModalText2: {
    height: "40px",
    width: "268px",
    fontSize: "13px",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
      height: "48px",
      width: "386px",
    },
  },
  btnTextBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 268,
    [theme.breakpoints.up("md")]: {
      width: "386px",
    },
  },
}));

const pulse = keyframes`
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
  `;

let modalInitVal = {
  error: false,
  errorMessage: null,
  alreadyCustomer: false,
  sgSimilarCustomer: null,
  isStarted: false,
};

const initStep = {
  step: 0,
  name: null,
  screenDetail: null,
};

const SignupWizard = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(initStep);
  const [tokens, setTokens] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const totalSteps = steps.length - 1;
  const [modal, setModal] = useState(modalInitVal);

  let progress = (activeStep.step / totalSteps) * 100;
  progress = Math.round(progress);

  const saveCode = async () => {
    let searchCode = { term: window.location.search };
    if (searchCode.term) {
      const params = new URLSearchParams(searchCode.term);
      searchCode.invalid = true;
      if (params.get("referral_code")) {
        searchCode.invalid = false;
        searchCode.referral_code = params.get("referral_code");
        document.cookie = `referral_code=${searchCode.referral_code};`;
        localStorage.setItem("referral_code", searchCode.referral_code);
      } else {
        let utms = updateUtmCookiesFields();
        let utmlookupcode = "";
        utms.map(utmcode => {
          if (utmcode != null) {
            if (utmlookupcode != "" ) {
              utmlookupcode = utmlookupcode + '_';
            }
            utmlookupcode = utmlookupcode + utmcode;
          }
        });
        if (utmlookupcode.length) {
          searchCode.invalid = false;
          searchCode.utmlookupcode = utmlookupcode;
          localStorage.setItem("utmlookupcode", searchCode.utmlookupcode);
        }
        //  TODO:fix
         let partnerutms = updateUtmCookiesUTMContent();
         let partnerutmlookupcode = "";
         partnerutms.map(utmcode => {
           if (utmcode != null) {
             partnerutmlookupcode = partnerutmlookupcode + utmcode;
           }
         })
         if (partnerutmlookupcode.length) {
           //searchCode.invalid = false;
           //searchCode.partnerutmlookupcode = partnerutmlookupcode
           localStorage.setItem("partnerutmlookupcode", partnerutmlookupcode);
         }
      }
      if (!searchCode.invalid) {
        await uploadUTMs(searchCode.utmlookupcode, searchCode.referral_code);
      }
    }
  };

  useEffect(() => {
    //for initialization
    const init = async () => {
      let step = 0;
      let stepName = "";
      if (params["step"]) {
        if (steps.includes(params["step"])) {
          step = steps.indexOf(params["step"]);
          stepName = params["step"];
        }
      } else {
        stepName = "zip-code";
      }
      let last_submitted = await localStorage.getItem("last_submitted");
      if (last_submitted) {
        if (
          (last_submitted !== "zip-code",
          last_submitted !== stepName && last_submitted !== steps[step - 1])
        ) {
          // setModal({ ...modalInitVal, isStarted: true });
          let check = 1;
          let autoPayOption = autoPayOpt();
          let credit_check = JSON.parse(
            localStorage.getItem("credit_check_response")
          );
          switch (stepName) {
            case "payment-details":
              if (!autoPayOption && last_submitted === "credit-check") {
                check = 0;
              } else if (
                autoPayOption &&
                credit_check.totalDepositAmount > 0 &&
                last_submitted === "credit-check"
              ) {
                check = 0;
              }
              break;
            case "review":
              if (!autoPayOption && last_submitted === "credit-check") {
                check = 0;
              } else if (
                autoPayOption &&
                credit_check.totalDepositAmount === 0 &&
                last_submitted === "payment-method"
              ) {
                check = 0;
              }
              break;
          }
          if (check) {
            let contactDet = localStorage.getItem("contact-details");
            if (contactDet) {
              let localEmail = JSON.parse(contactDet).email;
              try {
                let response = await axios.post("/get/valid/uuid", {
                  email: localEmail,
                });
                let token = localStorage.getItem("tokens");
                token = JSON.parse(token);
                if (token.uuid !== response.data.uuid) {
                  setModal({
                    ...modalInitVal,
                    sgSimilarCustomer: response.data.uuid,
                  });
                } else {
                  setModal({ ...modalInitVal, isStarted: true });
                }
              }
              catch (error) {
                window.NREUM.noticeError(error);
                if (error.response?.status && error.response?.status === 409) {
                  setModal({ ...modalInitVal, isStarted: true });
                } else {
                  console.error(error);
                }
              }
            } else {
              setModal({ ...modalInitVal, isStarted: true });
            }
          }
        }
      }
      if (!tokens) {
        let tokenInfo = await localStorage.getItem("tokens");
        setTokens(JSON.parse(tokenInfo));
      }
    };
    saveCode();
    init();
  }, []);

  useEffect(() => {
    // for each step
    const initialize = async () => {
      let step = 0;
      let stepName = "";
      if (params["step"]) {
        if (steps.includes(params["step"])) {
          step = steps.indexOf(params["step"]);
          stepName = params["step"];
          let scrInfo = await getScreenInfo(stepName, Theme);
          setActiveStep({
            step: step,
            name: stepName,
            screenDetail: scrInfo,
          });
        }
        setIsLoading(false);
      } else {
        stepName = "zip-code";
        let scrInfo = await getScreenInfo(stepName, Theme);
        setActiveStep({ ...initStep, screenDetail: scrInfo });
        navigate(`/zip-code`);
      }
      // setActiveStepName(stepName);

      await localStorage.setItem("current_page", stepName);
    };
    initialize();
  }, [params.step]);

  const onAgree = () => {
    getFieldValues(modal.alreadyCustomer);
    console.log("continue with existing account");
  };

  const onDisagree = () => {
    setModal({ ...modalInitVal, alreadyCustomer: false });
    // console.log("continue with new email");
  };

  const handleNext = () => {
    let stepName = steps[activeStep.step + 1];
    navigate(`/${stepName}`);
  };

  const handleBack = async () => {
    if (activeStep.name === "address") {
      let petType = await localStorage.getItem("pet-type");
      petType = JSON.parse(petType);
      if (petType.has_pet === 0) {
        navigate("/pet-type");
        return null;
      }
      let stepName = steps[activeStep.step - 1];
      navigate(`/${stepName}`);
    } else if (activeStep.name === "payment-details") {
      let credit_check = await localStorage.getItem("credit_check_response");
      credit_check = JSON.parse(credit_check);
      if (credit_check && credit_check.totalDepositAmount === 0) {
        navigate("/payment-method");
      } else {
        navigate("/credit-check");
      }
    } else if (activeStep.name === "review") {
      let paymentResponse = localStorage.getItem("payment-response");
      let paymentDetails = localStorage.getItem("payment-details");
      if (
        paymentResponse ||
        (paymentDetails && JSON.parse(paymentDetails).deposit_waiver)
      ) {
        navigate("/payment-details");
      } else {
        let autoPayOption = autoPayOpt();
        if (autoPayOption) {
          navigate("/payment-method");
        } else {
          navigate("/credit-check");
        }
      }
    } else if (activeStep.step !== 0) {
      let stepName = steps[activeStep.step - 1];
      navigate(`/${stepName}`);
    } else {
      navigate(`/`);
    }
  };

  const handleContinueJourney = async () => {
    let last_submitted = await localStorage.getItem("last_submitted");
    let nextPage = steps.indexOf(last_submitted);
    setModal({ ...modalInitVal, isStarted: false });
    nextPage = steps[nextPage + 1];
    //TODO:: remove hack to skip auto-pay
    if (nextPage === "payment-method" || nextPage === "review") {
      console.log("hc1");
      let creditCheck = localStorage.getItem("credit_check");
      if (creditCheck && JSON.parse(creditCheck).depositAmount > 0) {
        console.log("hc2");
        let paymentResponse = localStorage.getItem("payment-response");
        let paymentDetails = localStorage.getItem("payment-details");
        if (
          paymentResponse ||
          (paymentDetails !== null && JSON.parse(paymentDetails).deposit_waiver)
        ) {
          console.log("hc4");
          console.log(paymentResponse);
          console.log(paymentDetails);
          nextPage = "review";
        } else {
          console.log("hc5");
          nextPage = "payment-details";
        }
      } else {
        console.log("hc3");
        nextPage = "review";
      }
    } else {
      console.log("hc6");
    }
    //TODO:: remove hack to skip auto-pay
    navigate(`/${nextPage}`);
  };

  const handleReset = async () => {
    localStorage.clear();
    sessionStorage.clear();
    await getTokens();
    window.location.href = "/zip-code";
  };

  const handleCancel = async () => {
    setModal({ ...modalInitVal, isStarted: false });
    handleReset();
  };

  const submit = async (values) => {
    let openModal = {
      similarSgCust: (uuid) =>
        setModal({ ...modalInitVal, sgSimilarCustomer: uuid }),
      alreadyCustomer: (values) =>
        setModal({ ...modalInitVal, alreadyCustomer: values }),
      // alreadyStarted: (values) => setModal(values => values.alreadyCustomer = false),
      error: (message) =>
        setModal({ ...modalInitVal, error: true, errorMessage: message }), //setErrorOpen(true)
    };
    await handleSubmit(
      values,
      tokens,
      activeStep.name,
      navigate,
      handleNext,
      openModal
    );
  };

  const agreeSGModal = () => {
    const myaccountUrl = process.env.REACT_APP_MYACCOUNT_REDIRECT;
    window.open(
      `${myaccountUrl}register?t=${modal.sgSimilarCustomer}`,
      "_self"
    );
    setModal({ ...modalInitVal, sgSimilarCustomer: null });
  };

  const disagreeSGModal = () => {
    setModal({ ...modalInitVal, sgSimilarCustomer: null });
    // handleReset();
  };

  const GetSteps = ({ step }) => {
    if (!isLoading) {
      switch (step) {
        case "zip-code":
          return <GetPlans onSubmit={submit} />;
        case "plans":
          return <ShowPlans onSubmit={submit} />;
        case "basic-info":
          return <BasicInfo onSubmit={submit} />;
        case "contact-details":
          return <ContactDetails onSubmit={submit} />;
        case "pet-type":
          return <PetType onSubmit={submit} />;
        case "pet-name":
          return <PetName onSubmit={submit} />;
        case "pet-age":
          return <PetAge onSubmit={submit} />;
        case "address":
          return <Address onSubmit={submit} />;
        case "switching":
          return <SwitchingProvider onSubmit={submit} />;
        case "start-date":
          return <StartService onSubmit={submit} />;
        case "partner":
          return <Partner onSubmit={submit} />;
        case "credit-check":
          return <CreditCheck onSubmit={submit} />;
        case "payment-method":
          return <PaymentMethod onSubmit={submit} />;
        case "payment-details":
          return <PaymentDetails onSubmit={submit} />;
        // case "bank-details":
        //   return <BankDetails onSubmit={submit} />;
        // case "card-details":
        //   return <CardDetails onSubmit={submit} />;
        case "review":
          return <Review />;
        default:
          return <PageNotFound />;
      }
    } else return <div></div>;
  };

  if (isLoading && activeStep.screenDetail === null) {
    return null;
  } else {
    return (
      <Box className={classes.root}>
        {/* <Box className={classes.cancel}>
          <IconButton onClick={() => null}>
            <img src={reload} alt="reload" sx={{ width: 30, height: 30 }} />
          </IconButton>
        </Box> */}
        {/* <ProgressBar
          steps={steps}
          totalSteps={totalSteps}
          completed={progress}
        /> */}
        {activeStep.step !== 0 && (
          <Box className={classes.iconButton}>
            <IconButton onClick={handleBack}>
              <img
                src={arrowLeft}
                alt="back-button"
                className={classes.backIcon}
              />
            </IconButton>
          </Box>
        )}
        <Box
          className={classes.divider}
          sx={{ borderTop: 2, borderColor: "#f4f4f4" }}
        >
          <Box className={classes.tint4}>
            <Box className={classes.tint3}>
              <img
                src={charlie}
                alt="charlie-avatar"
                className={classes.avatar}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.container}>
          {activeStep.name !== "payment-method" &&
            activeStep.name !== "payment-details" &&
            activeStep.screenDetail && (
              <ThemedTitle>
                <div
                  dangerouslySetInnerHTML={{
                    __html: activeStep.screenDetail.description,
                  }}
                />
              </ThemedTitle>
            )}
          <Box>
            <GetSteps step={activeStep.name} />
          </Box>
        </Box>

        {/* Smartgrid Modal */}
        <ConfirmationModal
          open={modal.sgSimilarCustomer}
          handleClose={disagreeSGModal}
          title="Are you a current customer?"
          agree={{ title: "MyAccount", onClick: agreeSGModal }}
          disagree={{ onClick: disagreeSGModal }}
        >
          It looks like you already have an account with us, please login to
          continue.
        </ConfirmationModal>

        {/* found existing customer on prospect table Modal */}
        <ConfirmationModal
          open={modal.alreadyCustomer}
          handleClose={onDisagree}
          title="Previously started signup journey?"
          agree={{ title: "Continue", onClick: onAgree }}
          disagree={{ title: "Cancel", onClick: onDisagree }}
        >
          It appears you have previously started the signup process, would you
          like to start where you left?
        </ConfirmationModal>

        <ConfirmationModal
          open={modal.isStarted}
          title="Previously started signup journey?"
          agree={{ title: "Continue", onClick: handleContinueJourney }}
          disagree={{ title: "Cancel", onClick: handleCancel }}
        >
          It appears you have previously started the signup process, would you
          like to start where you left?
        </ConfirmationModal>

        <InfoModal
          open={modal.error}
          message={modal.errorMessage}
          close={() =>
            setModal({ ...modalInitVal, error: false, errorMessage: null })
          }
        />
      </Box>
    );
  }
};

export default SignupWizard;

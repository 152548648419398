import axios from "./axios";

function utmRetriver(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

const checkUTMCode = (url) => {
  var utm_campaign = utmRetriver("utm_campaign")
  var utm_source = utmRetriver("utm_source")
  var utm_medium = utmRetriver("utm_medium")
  var utm_content = utmRetriver("utm_content")
  var utm_term = utmRetriver("utm_term")

  if (!url) {
    return [utm_campaign, utm_source, utm_medium, utm_content, utm_term]
  }

  var newURL = url;
  if (!utm_campaign && !utm_source && !utm_medium && !utm_content && !utm_term) {
    return newURL;
  } else {
    var newURLObj = {};
    if (utm_campaign) {
      newURLObj = { ...newURLObj, "utm_campaign": utm_campaign };
    }
    if (utm_source) {
      newURLObj = { ...newURLObj, "utm_source": utm_source };
    }
    if (utm_medium) {
      newURLObj = { ...newURLObj, "utm_medium": utm_medium };
    }
    if (utm_content) {
      newURLObj = { ...newURLObj, "utm_content": utm_content };
    }
    if (utm_term) {
      newURLObj = { ...newURLObj, "utm_term": utm_term };
    }
    var newURLQuery = new URLSearchParams(newURLObj).toString();
  }
  if (newURLQuery) {
    newURL = newURL + "?" + newURLQuery;
  }
  return newURL;
}

const saveUtmCookiesFields = (utm_campaign, utm_source, utm_medium, utm_content, utm_term) => {
  if (!utm_campaign && !utm_source && !utm_medium && !utm_content && !utm_term) {
    return null;
  }
  if (!utmRetriver("utm_campaign") || !utmRetriver("utm_source") || !utmRetriver("utm_medium") || !utmRetriver("utm_content") || !utmRetriver("utm_term")) {
    document.cookie = utm_campaign && `utm_campaign=${utm_campaign};`
    document.cookie = utm_source && `utm_source=${utm_source};`
    document.cookie = utm_medium && `utm_medium=${utm_medium};`
    document.cookie = utm_content && `utm_content=${utm_content};`
    document.cookie = utm_term && `utm_term=${utm_term};`
  }
}

// saveUtmCookiesFields(); //pass the arguments sequentially

const updateUtmCookiesUTMContent = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  var utm_campaign = params.utm_campaign;
  var utm_source = params.utm_source;
  var utm_medium = params.utm_medium;
  var utm_content = params.utm_content;
  var utm_term = params.utm_term;
  if (utm_campaign) {
    //document.cookie = `utm_campaign=${utm_campaign};`
  }
  if (utm_source) {
    //document.cookie = `utm_source=${utm_source};`
  }
  if (utm_medium) {
    //document.cookie = `utm_medium=${utm_medium};`
  }
  if (utm_content) {
    //document.cookie = `utm_content=${utm_content};`
  }
  if (utm_term) {
    //document.cookie = `utm_term=${utm_term};`
  }
  //return [utm_campaign, utm_source, utm_medium, utm_content]
  return [utm_content]
}

const updateUtmCookiesFields = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  var utm_campaign = params.utm_campaign;
  var utm_source = params.utm_source;
  var utm_medium = params.utm_medium;
  var utm_content = params.utm_content;
  var utm_term = params.utm_term;
  if (utm_campaign) {
    document.cookie = `utm_campaign=${utm_campaign};`
  }
  if (utm_source) {
    document.cookie = `utm_source=${utm_source};`
  }
  if (utm_medium) {
    document.cookie = `utm_medium=${utm_medium};`
  }
  if (utm_content) {
    document.cookie = `utm_content=${utm_content};`
  }
  if (utm_term) {
    document.cookie = `utm_term=${utm_term};`
  }
  return [utm_campaign, utm_source, utm_medium, utm_content]
}

const removeUtmCookiesFields = () => {
  const utms = ["utm_campaign", "utm_source", "utm_medium", "utm_content", "utm_term"]
  utms.map(utmName => {
    document.cookie = utmName + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
  })

  console.log("done")
}

const uploadUTMs = async (utmlookupcode, referral_code) => {
  let mytoken = await localStorage.getItem("tokens");
  mytoken = JSON.parse(mytoken)
  try {
    await axios.patch(`/customer/update`, {
      uuid: mytoken.uuid,
      utmlookup_code: utmlookupcode || null,
      referral_code: referral_code || null
    });
  } catch (e) {
    console.log(e);
  }
}

export { utmRetriver, checkUTMCode, saveUtmCookiesFields, updateUtmCookiesFields, updateUtmCookiesUTMContent, removeUtmCookiesFields, uploadUTMs }

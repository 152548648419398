//docArray is array of documents - array
//language is document language - example(Spanish, English) - string
//type is document type - example(EFL, TOS, YRAC) - string
function docFilter(docArray, language, type, docProviderID) {
    const result = type === 'EFL' ? docArray.filter(doc => doc.productDocumentLanguageName === language).filter(item => item.documentTypeName === type).filter(item => item.distributionServiceProviderID === docProviderID) : docArray.filter(doc => doc.productDocumentLanguageName === language).filter(item => item.documentTypeName === type);
    return result ? (
        result.length >= 1 ? (
            result[0].documentURL ? result[0].documentURL : null 
            ) : (
                result.documentURL ? result.documentURL : null
            )
    ) : null;
}

export default docFilter;
import { makeStyles } from "@mui/styles";
import "react-phone-input-2/lib/bootstrap.css";
import { Box } from "@mui/material";
import Iframe from 'react-iframe';

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }
}));

let PlanDocuments = (props) => {
const classes = useStyles();
//const docUrl = window.location.search.replace('?http','http');
//const docUrl = window.location.search.replace('?','');
const docUrl = process.env.REACT_APP_DEV_API_ENDPOINT + 'get/document/' + window.location.search.replace('?http','http');
  return (
      <Box className={classes.mainDiv}>
        <Iframe 
            url={docUrl}
            width="100%"
            height="100%"
            id="myId"
            display="initial"
            position="fixed"
            allowFullScreen
        />
      </Box>
  );
};

export default PlanDocuments;

import axios from "src/utils/axios";
import axiosEndPoint from "axios"
import formatPhoneNumber from "./formatPhoneNumber";
import moment from "moment"
import { getAccountNote } from "./getPartnerID";

let submitDataLayer = true;
const accountCreation = async (setReviewRes, enqueueSnackbar, customFields) => {
    const getData = async (step) => {
        let localData = await localStorage.getItem(step);
        return JSON.parse(localData);
    };

    let zip = await getData("zip-code")
    let tokens = await getData("tokens");
    let switching = await getData("switching");
    let startDate = await getData("start-date");
    let address = await getData("address");
    let contact = await getData("contact-details");
    let basicInfo = await getData("basic-info");
    let creditCheck = await getData("credit-check");
    let credit_check_response = await getData("credit_check_response");
    let metaData = await getData("metaData");
    let partner = await getData("partner");
    let payment = await getData("payment-method");
    let paymentDetails = await getData("payment-details");
    let paymentRes = await getData("payment-response");
    let plan = await getData("plan");
    let distributionPoints = await getData("distribution_point_response");    // not on server


    if (!contact) {
        window.NREUM.noticeError("accountCreation> missing_data: <contact-details>")           // mylog
        // setReviewRes({
        //     error: true,
        //     message: "Account creation failed",                                   // mylog as popup
        //     sgSuccess: false
        // })
        return null;                                                //end the account creation
    }

    let phoneNumber = formatPhoneNumber(contact.phone_number)
    let notificationChannels = [{
        notificationChannelID: 1
    }];

    if (contact.sms_communication) {
        notificationChannels.push(
            {
                notificationChannelID: 2
            }
        )
    }

    let PaymentAccountTypeID = null;
    try {
        metaData.map(({ listType, listData }) => {
            if (listType === "PAYMENT_ACCOUNT_TYPE") {
                listData.map(({ name, value }) => {
                    if ((payment.payment_type === "card" && name === "Credit Card") || (payment.payment_type === "bank" && name === "E-Check")) {
                        PaymentAccountTypeID = value;
                    }
                })
            }
        })
    } catch(e) {
        window.NREUM.noticeError(e);
    }
    let accountName = null;
    try {
        if (payment.payment_type === "card" && paymentRes?.cardNumber) {
            accountName = `CC${paymentRes.cardNumber}`
        } else {
            if (payment.payment_type === "bank" && paymentRes.accountNumber) {
                accountName = `BA${paymentRes.accountNumber}`
            }
        }
    } catch(e) {
        window.NREUM.noticeError(e);
    }

    let accountNotes = [
        {
            "accountID": 0,
            "noteTypeID": 2,
            "noteText": ""                     // ip address
        }
    ]
    if (paymentDetails && paymentDetails.deposit_waiver) {
        try {
            let response = await axios("/get/picklist/settings");
            let pickList = response.data.picklist[0].picklist_entries;
            pickList.map((item) => {
                if (item.key === "deposit_waived") {
                    accountNotes.push({
                        "accountID": 0,
                        "noteTypeID": 2,
                        "noteText": item.label
                    })
                }
            })
        } catch (e) {
            window.NREUM.noticeError(e);
            return null;
        }
    }

    let agentId = null;
    let utmCode = localStorage.getItem("utmlookupcode");
    if (utmCode) {
        try {
            let agentRes = await axios.post(`/get/agent_id`, {
                "uuid": tokens.uuid,
                "utmlookup_code": utmCode
            });
            agentId = agentRes.data.agent_id;
        } catch (e) {
            window.NREUM.noticeError(e);
        }
    }
    let customNote = null;
    try {
        customNote = getAccountNote("label", partner.partner)
    } catch (e) {
        window.NREUM.noticeError(e);
    }

    let accountCustomFields = [
            {
                "accountCustomFieldID": 0,
                "accountID": 0,
                "customFieldID": 1002,                          // for now
                "value": customNote
            }
        ]
    try {
        accountCustomFields = accountCustomFields.concat(customFields);
    } catch(e) {
        window.NREUM.noticeError(e);
    }
    const handleSgError = (response) => {
        window.NREUM.noticeError(response);
        setReviewRes({
            error: true,
            message: response,
            sgSuccess: false
        })
    }
    let paymentToken = null
    if (paymentRes) {
        if (paymentRes.token) {
            paymentToken = paymentRes.token;
        }
    }
    // }

    if (!distributionPoints) {
        window.NREUM.noticeError('!distributionPoints');                       // mylog
        return null;
    }


    let sgParameters = {
        "uuid": tokens.uuid,
        "AccountEnrollment": {
            "isMoveInRequest": switching.moving_switching === "moving" ? true : false,
            "moveInDate": moment(startDate.start_date).format("YYYY-MM-DD HH:mm:ss"),
            "accountDepositOption": {
                "createMaxDeposit": creditCheck.credit_check === "runCredit" ? false : true,
                "createDeposit": credit_check_response.totalDepositAmount > 0 ? true : false,
                "accountDeposits": credit_check_response.accountDeposits
            },
            "account": {
                "agentID": agentId,
                "companyID": 1,
                "accountTypeID": 1,
                "languageID": 1,
                "distributionPoints": distributionPoints,
                "persons": [
                    {
                        "personContacts": [
                            {
                                "contactTypeID": contact.sms_communication ? 1 : 2,
                                "contactValue": phoneNumber
                            }
                        ],
                        "personTypeID": 1,  //always 1 = prospect
                        "firstName": basicInfo.first_name,
                        "lastName": basicInfo.last_name,
                        "userName": "eh00000",              // for now
                        "password": "sdfjlfjalsajflds",     // for now
                        "emailAddress": contact.email,
                        "isPrimary": true,                  // always true
                        "notificationChannels": notificationChannels,
                        "ssn": creditCheck.social_security_number,
                        "dateOfBirth": moment(creditCheck.dob).format("YYYY-MM-DD")
                    }
                ],
                "accountAddress": {
                    "address1": distributionPoints[0].address1,
                    "city": distributionPoints[0].city,
                    "postalCode": distributionPoints[0].zipCode,
                    "state": address.state
                },
                "securityQuestionID": 1,                                  // hard coded for now
                "securityQuestionAnswer": "houston",                      // hard coded for now
                "billDeliveryMethod": [                                   // hard coded for now
                    {
                        "billDeliveryMethodID": 2                         // hard coded for now
                    }
                ],
                "accountNotes": accountNotes,
                "accountCustomFields": accountCustomFields
            },
            "ipAddress": ""             // getting handled by server
        },
        "PaymentAccountTypeID": PaymentAccountTypeID,    //ui (meta data)
        "Active": "true",
        "AccountName": accountName,      // ui - payment response
        "ExternalProfileIdentifier": paymentToken,    // ui  - payment response
        "isAutoPayAccount": String(payment.auto_pay)      // ui  - payment method
    }

    try {
        let response = await axios.post("/create/account/sg", sgParameters, {
            headers: {
                "sg-authorization": `Bearer ${tokens.sg_token.accessToken}`,
            },
        });
        let response_data = response.data.data;
        //console.log("sg account creation", response.data );
        if (typeof response_data === "string") {
            handleSgError(response_data);
        } else {
            let user_id = response.data.user_id;
            //console.log("gc user_id: ", user_id);
            if (submitDataLayer) {
                submitDataLayer = false;
                let dlData = {
                    event: "signup_complete",
                    email: contact.email,
                    user_id: user_id,
                    fname: basicInfo.first_name,
                    phone: phoneNumber
                };
                let dlResult = await window.dataLayer.push(dlData);
                //console.log("dlResult: ", dlResult);
            }
            setReviewRes({
                error: false,
                message: null,
                sgSuccess: true
            })
            await localStorage.setItem("sg_account", JSON.stringify(response_data));
            try {
                /*
                *  post to hS
                * */
                const portalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
                const enrollmentCompleteFormId = process.env.REACT_APP_HUBSPOT_ENROLLMENT_COMPLETE_FORM_ID;
                const HUBSPOT_API_URL = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${enrollmentCompleteFormId}`;
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
                let hubspottuk = null;
                let utm_campaign = null;
                let utm_source = null;
                let utm_medium = null;
                let utm_content = null;
                const today = new Date();
                document.cookie.split('; ').forEach(function (a, b) {
                    if (a.toString().match(/hub/)) {
                        hubspottuk = a.toString().split('=')[1];
                    } else if (a.toString().match(/utm_campaign/)) {
                        utm_campaign = a.toString().split('=')[1];
                    } else if (a.toString().match(/utm_source/)) {
                        utm_source = a.toString().split('=')[1];
                    } else if (a.toString().match(/utm_medium/)) {
                        utm_medium = a.toString().split('=')[1];
                    } else if (a.toString().match(/utm_content/)) {
                        utm_content = a.toString().split('=')[1];
                    }
                }, hubspottuk);
                let promo_code = null;
                if (zip.promo_code) {
                    promo_code = zip.promo_code;
                }
                let HUBSPOT_FIELDS = [];
                if (basicInfo.first_name) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "firstname",
                            value: basicInfo.first_name,
                        }
                    );
                }
                if (basicInfo.last_name) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "lastname",
                            value: basicInfo.last_name,
                        }
                    );
                }
                if (contact.email) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "email",
                            value: contact.email,
                        }
                    );
                }
                if (phoneNumber) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "phone",
                            value: phoneNumber ?? "",
                        }
                    );
                }
                if (address.address) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "address",
                            value: address.address,
                        }
                    );
                }
                if (address.city) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "city",
                            value: address.city,
                        }
                    );
                }
                if (address.state) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "state",
                            value: address.state,
                        }
                    );
                }
                if (zip.zip_code) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "zip",
                            value: zip.zip_code,
                        }
                    );
                }
                if (promo_code) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "promo_code",
                            value: promo_code,
                        }
                    );
                }
                if (tokens.uuid) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "signup_prospect_uuid",
                            value: tokens.uuid,
                        }
                    );
                }
                if (user_id) {
                    HUBSPOT_FIELDS.push({
                            objectTypeId: "0-1",
                            name: "gc_id",
                            value: user_id,
                        }
                    );
                }
                if (utm_campaign) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "utm_campaign",
                            value: utm_campaign,
                        }
                    );
                }
                if (utm_source) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "utm_source",
                            value: utm_source,
                        }
                    );
                }
                if (utm_medium) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "utm_medium",
                            value: utm_medium,
                        }
                    );
                }
                if (utm_content) {
                    HUBSPOT_FIELDS.push(
                        {
                            objectTypeId: "0-1",
                            name: "utm_content",
                            value: utm_content,
                        }
                    );
                }
                HUBSPOT_FIELDS.push(
                    {
                        objectTypeId: "0-1",
                        name: "signup_date",
                        value: (today.getMonth() + 1).toString().padStart(2, '0') + '/' + today.getDate().toString().padStart(2, '0') + '/' + today.getFullYear(),
                    }
                );
                const response = await axios.post(
                    HUBSPOT_API_URL,
                    {
                        fields: HUBSPOT_FIELDS,
                        context: {
                            hutk: hubspottuk,
                            pageName: "Signup Journey",
                        },
                    },
                    config
                );
                let logPayload = {
                    "success": response,
                    "file": 'accountCreation'
                };
                if (tokens.uuid) {
                    logPayload.uuid = tokens.uuid;
                }
                await axios.post("/log", logPayload);
            } catch (e) {
                window.NREUM.noticeError(e);
            }
        }
    } catch (e) {
        window.NREUM.noticeError(e);
        setReviewRes({
            error: true,
            message: e.message && e.message,
            sgSuccess: false
        });
    }
}

export default accountCreation;

import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate, Route, Routes } from "react-router-dom";

import { makeStyles } from '@mui/styles';
import theme from 'src/Theme/theme';
import Theme from 'src/Theme/theme';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

//utils
import docFilter from 'src/utils/docFilter';

//icons
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgress } from '@mui/material';

import VideoVetPink from 'src/assets/plan/goodenergy/videovetpink.svg';
import RoundupPink from 'src/assets/plan/goodenergy/rounduppink.svg';
import EmergencyFundPink from 'src/assets/plan/goodenergy/emergencyfundpink.svg';
import TickIconPink from 'src/assets/plan/goodenergy/tickpink.svg';
import MedPink from 'src/assets/plan/goodenergy/medpink.svg';
import HeartPink from 'src/assets/plan/goodenergy/heartpink.svg';
import BandagePink from 'src/assets/plan/goodenergy/bandagepink.svg';


import VideoVetBlue from 'src/assets/plan/goodgreen/videovetblue.svg';
import RoundupBlue from 'src/assets/plan/goodgreen/roudupblue.svg';
import EmergencyFundBlue from 'src/assets/plan/goodgreen/emergencyfundblue.svg';
import TickIconBlue from 'src/assets/plan/goodgreen/tickblue.svg';
import MedBlue from 'src/assets/plan/goodgreen/medblue.svg';
import HeartBlue from 'src/assets/plan/goodgreen/heartblue.svg';
import BandageBlue from 'src/assets/plan/goodgreen/bandageblue.svg';

import DocIcon from 'src/assets/plan/documenticon.svg';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    mainBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: '90%',
        overflow: 'scroll',
        backgroundColor: 'white',
        boxShadow: 24,
        [theme.breakpoints.up("md")]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 900,
            height: '80%',
            overflow: 'scroll',
            backgroundColor: 'white',
            boxShadow: 24,
        },
    },
    energyDesc: {
        marginTop: 20,
        width: 320,
        minHeight: 164,
        [theme.breakpoints.up("md")]: {
            marginTop: 0,
            width: 533,
            minHeight: 164,
        },
    },
    insideEnergyDesc: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 10px 0px 10px',
        [theme.breakpoints.up("md")]: {
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 50px',
        },
    },
    priceBox: {
        width: '100%',
        height: 164,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
        marginTop: 20,
        [theme.breakpoints.up("md")]: {
            marginTop: 0,
            width: 128,
            display: 'flex',
            flexDirection: 'column',
        },
    },
    mainDescDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        [theme.breakpoints.up("md")]: {
            display: 'flex',
            flexDirection: 'row',
            width: '90%',
            justifyContent: 'space-evenly',
        },
    },
    planDetails: {
        width: '90%',
        // margin: '0px 0px 30px 15%',
        [theme.breakpoints.up("md")]: {
            margin: '50px 0px 30px 15%',
            width: '85%',
        },
    },
    tickImgClass: {
        marginRight: 5,
        height: 15,
        width: 15,
        [theme.breakpoints.up("md")]: {
            marginRight: 10,
            height: 'auto',
            width: 'auto',
        },
    },
    planDetailsDataText: {
        width: '90%',
        color: '#3A3A3A',
        fontSize: '12px !important',
        [theme.breakpoints.up("md")]: {
            width: '85%',
            fontSize: '13px !important'
        },
    },
    iconAndText: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        margin: 4,
        width: '100%',
        [theme.breakpoints.up("md")]: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            margin: 4,
            width: '32%',
        },
    },
    docDetails: {
        width: '90%',
        margin: '20px 0px 0px 0px',
        [theme.breakpoints.up("md")]: {
            width: '85%',
            margin: '30px 0px 30px 15%',
        },
    },
    packBenefitsTop: {
        width: '90%',
        margin: '10px 0px 0px 0px',
        [theme.breakpoints.up("md")]: {
            width: '80%',
            margin: '10px 0px 0px 0px',
        },
    },
    impactLogos: {
        height: 50,
        width: 50,
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.up("md")]: {
            height: 50,
            width: 50,
            marginBottom: 40,
        }
    },
    choosingGC: {
        width: '90%',
        margin: '50px 0px 30px 0px',
        [theme.breakpoints.up("md")]: {
            width: '70%',
            margin: '50px 0px 30px 0px',
        },
    },
    faqDiv: {
        width: '90%',
        margin: '40px 0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.up("md")]: {
            width: '70%',
        },
    },
    faqQBox: {
        display: 'table',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        // justifyContent: 'space-between'
    },
    accordionCss: {
        width: '100%',
        margin: '5px 0px',
        "&.MuiAccordion-root:before": {
            backgroundColor: "white",
        },
        [theme.breakpoints.up("md")]: {
            borderRadius: 8,
            width: '90%',
            margin: '5px 0px',
            "&.MuiAccordion-root:before": {
                backgroundColor: "white",
            },
        },
    },
    accTopDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        [theme.breakpoints.up("md")]: {
            flexDirection: 'row'
        },
    },
    benefitsWrapper: {
        display: 'flex',
        flexDirection: 'column-reverse',
        padding: '10px 10px 0px 10px',
        justifyContent: 'space-between',
        [theme.breakpoints.up("md")]: {
            display: 'flex',
            flexDirection: 'row-reverse',
            padding: '20px 50px 30px 50px',
            justifyContent: 'space-between',
        },
    },
    benefitsWrapperBoxes: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        // marginTop: 10,
        [theme.breakpoints.up("md")]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: 0,
        },
    },
    benefitsWrapperImage: {
        display: 'flex',
        height: 85,
        width: 85,
        borderRadius: 100,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 15,
        [theme.breakpoints.up("md")]: {
            marginBottom: 20,
        },
    },
    benefitWrapperContent: {
        fontSize: 12,
        fontWeight: 400,
        color: '#3A3A3A',
        width: '100%',
        [theme.breakpoints.up("md")]: {
            width: 170
        },
    },
    ChoosingGCWrapper: {
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'space-between',
        [theme.breakpoints.up("md")]: {
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
        },
    },
    docStyling:{
        display: 'flex',
        alignItems: 'center',
        "&:hover":{
            textDecorationLine: 'underline'
        }
    }
}))

const PlanDetailsModal = ({
    open,
    handleClose,
    plan,
    plan_switch,
    planCompleteData,
    faqData,
    packBenefits,
    packImpacts,
    selectPlanBtnClick,
    currentPlanId,
    selectButtonLoading,
    docDetails
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const planDetailsData = [
        plan.productDisplayShortDescription ? plan.productDisplayShortDescription.replace('-', ' ').replace(',', '') : '',
        'No hidden fees',
        'All the Pack Benefits!',
        'Earth friendly e-billing',
        'Lowest rate'
    ];
    const planDetailsDataGreen = [
        plan.productDisplayShortDescription ? plan.productDisplayShortDescription.replace('-', ' ').replace(',', '') : '',
        '100% Renewable',
        'No hidden fees',
        'All the Pack Benefits!',
        'Earth friendly e-billing'
    ];

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box className={classes.mainBox}>

                    <Box style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', position: 'sticky', top: '0px', left: '-20px' }}>
                        <Box onClick={handleClose} style={{ margin: '35px 35px 0px 0px', color: '#21212185' }}>
                            <CancelOutlinedIcon fontSize='large' />
                        </Box>
                    </Box>

                    <Box className={classes.mainDescDiv}>
                        {/* Energy Description Div */}
                        <Box className={classes.energyDesc} style={{ borderRadius: '12px', backgroundColor: plan_switch ? 'rgba(206, 245, 241, 0.3)' : 'rgba(255, 233, 238, 0.2)', border: plan_switch ? '1px solid rgba(206, 245, 241, 1)' : `1px solid rgba(255, 90, 106, 0.2)` }}>
                            <Box className={classes.insideEnergyDesc}>
                                <Typography variant='body2' style={{ fontWeight: 700, fontSize: '20px', color: plan_switch ? Theme.palette.secondary.main : Theme.palette.primary.main }}>
                                    {plan.name}
                                </Typography>
                                <Typography variant='body2' style={{ margin: '13px 0px', color: '#3A3A3A', fontSize: '16px', fontWeight: 400 }}>
                                    {plan.productDisplayLongDescription}
                                </Typography>
                            </Box>
                        </Box>

                        {/* Price Div */}
                        <Box className={classes.priceBox}>
                            <Box>
                                <Typography variant='body2' style={{ fontSize: 16, fontWeight: 700 }}>
                                    Join The Pack!
                                </Typography>
                                <Typography variant='body2' style={{ fontSize: 48, fontWeight: 700 }}>
                                    {plan.kwh2000Price.toLocaleString(navigator.language, { minimumFractionDigits: 1 })}¢
                                </Typography>
                                <Typography variant='body2' style={{ fontSize: 12, fontWeight: 600, color: 'rgba(58, 58, 58, 0.8)' }}>
                                    Based on 2,000 kWh
                                </Typography>
                            </Box>
                            <Box width={47} />
                            <Button
                                onClick={selectPlanBtnClick}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    height: 40,
                                    width: 124,
                                    boxShadow: 3,
                                    color: 'white',
                                    borderRadius: '32px',
                                    background: theme.palette.primary.main,
                                    "&.MuiButtonBase-root:hover": {
                                        background: theme.palette.primary.main,
                                    },
                                    [theme.breakpoints.up("md")]: {
                                        height: 40,
                                        width: 127
                                    },
                                }}>
                                {!selectButtonLoading ? (
                                    <>
                                        <Box className={classes.btnContainer}>
                                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: "white" }}>
                                                {currentPlanId === String(plan.productID) ? "Selected" : "Select Plan"}
                                            </Typography>
                                        </Box>
                                    </>
                                )

                                    :

                                    (
                                        <>
                                            <Box
                                                className={classes.btnContainer}
                                                sx={{ display: 'flex', color: "#fff", width: "70%", alignItems: "center" }}
                                            >
                                                <CircularProgress
                                                    size={18}
                                                    thickness={6}
                                                    sx={{
                                                        m: 0,
                                                        mr: 0.5,
                                                        p: 0,
                                                    }}
                                                    color="inherit"
                                                />
                                                <Typography sx={{ fontSize: 16, fontWeight: 600, color: "white" }}>{selectButtonLoading ? "Submitting" : (currentPlanId === String(plan.productID) ? "Selected" : "Select Plan")}</Typography>
                                            </Box>
                                        </>
                                    )}
                            </Button>
                        </Box>
                    </Box>

                    {/* Plan Details Div */}
                    <Box className={classes.planDetails}>
                        <Box>
                            <Typography variant='body2' style={{ fontSize: 16, fontWeight: 700, marginBottom: 20 }}>
                                Plan Details
                            </Typography>
                        </Box>
                        <Grid container>
                            {(plan_switch ? planDetailsDataGreen : planDetailsData).map((text, i) =>
                                <Grid item xs={6} sm={4} sx={{ marginBottom: 2 }} className={classes.iconAndText}>
                                    <img src={plan_switch ? TickIconBlue : TickIconPink} className={classes.tickImgClass} />
                                    <Typography variant="body2" className={classes.planDetailsDataText}>
                                        {text}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Box>

                    <Box style={{ border: 0, borderTop: 1, borderColor: "#F3F3F3", borderStyle: "solid", width: '80%' }} />

                    {/* Plan Documents Div */}
                    <Box className={classes.docDetails}>
                        <Box>
                            <Typography variant='body2' style={{ fontSize: 16, fontWeight: 700, marginBottom: 20 }}>
                                Plan Documents
                            </Typography>
                        </Box>
                        <Grid container>
                            <Grid item xs={6} sm={4} sx={{mb: 2}} className={classes.iconAndText}>
                                {
                                    docFilter(plan.productDocuments, 'English', 'EFL', planCompleteData.distributionServiceProvider.distributionServiceProviderID) ?
                                    <Typography variant="body2" style={{ color: '#3A3A3A', cursor: 'pointer' }} className={classes.planDetailsDataText}>
                                        <span onClick={()=>window.open(`/document?${encodeURIComponent(docFilter(plan.productDocuments, 'English', 'EFL', planCompleteData.distributionServiceProvider.distributionServiceProviderID))}`, "_blank")} className={classes.docStyling}>
                                            <img src={DocIcon} className={classes.tickImgClass} />
                                            Electricity facts label
                                        </span>
                                        <br />
                                        <span>
                                            {docDetails.eflDetails}
                                        </span>
                                    </Typography>
                                    : 
                                    <Typography variant="body2" style={{ color: '#3A3A3A', cursor: 'pointer' }} className={classes.planDetailsDataText}>
                                        <span className={classes.docStyling}>
                                            <img src={DocIcon} className={classes.tickImgClass} />
                                            Electricity facts label
                                        </span>
                                        <br />
                                        <span>
                                            {docDetails.eflDetails}
                                        </span>
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={6} sm={4} className={classes.iconAndText}>
                                {
                                    docFilter(plan.productDocuments, 'English', 'YRAC', planCompleteData.distributionServiceProvider.distributionServiceProviderID)  ?
                                    <Typography variant="body2" style={{ color: '#3A3A3A', cursor: 'pointer' }} className={classes.planDetailsDataText}>
                                        <span onClick={()=>window.open(`document?${encodeURIComponent(docFilter(plan.productDocuments, 'English', 'YRAC', planCompleteData.distributionServiceProvider.distributionServiceProviderID))}`, "_blank")} className={classes.docStyling}>
                                            <img src={DocIcon} sx={{mb: 2}} className={classes.tickImgClass} />
                                            Your Rights as a Customer
                                        </span>
                                        <br />
                                        <span>
                                            {docDetails.yracDetails}
                                        </span>
                                    </Typography>
                                    : 
                                    <Typography variant="body2" style={{ color: '#3A3A3A', cursor: 'pointer' }} className={classes.planDetailsDataText}>
                                        <span className={classes.docStyling}>
                                            <img src={DocIcon} sx={{mb: 2}} className={classes.tickImgClass} />
                                            Your Rights as a Customer
                                        </span>
                                        <br />
                                        <span>
                                            {docDetails.yracDetails}
                                        </span>
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={6} sm={4} sx={{mb: 2}} className={classes.iconAndText}>
                                {
                                    docFilter(plan.productDocuments, 'English', 'TOS', planCompleteData.distributionServiceProvider.distributionServiceProviderID)  ?
                                    <Typography variant="body2" style={{ color: '#3A3A3A', cursor: 'pointer' }} className={classes.planDetailsDataText}>
                                        <span onClick={()=>window.open(`document?${encodeURIComponent(docFilter(plan.productDocuments, 'English', 'TOS', planCompleteData.distributionServiceProvider.distributionServiceProviderID))}`, "_blank")} className={classes.docStyling}>
                                            <img src={DocIcon} className={classes.tickImgClass} />
                                            Terms of service
                                        </span>
                                        <br />
                                        <span>
                                            {docDetails.tosDetails}
                                        </span>
                                    </Typography>
                                    : 
                                    <Typography variant="body2" style={{ color: '#3A3A3A', cursor: 'pointer' }} className={classes.planDetailsDataText}>
                                        <span className={classes.docStyling}>
                                            <img src={DocIcon} className={classes.tickImgClass} />
                                            Terms of service
                                        </span>
                                        <br />
                                        <span>
                                            {docDetails.tosDetails}
                                        </span>
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Pack Benefits */}
                    <Box className={classes.packBenefitsTop} style={{ borderRadius: '12px', backgroundColor: plan_switch ? 'rgba(206, 245, 241, 0.3)' : 'rgba(255, 233, 238, 0.2)', border: plan_switch ? '1px solid rgba(206, 245, 241, 1)' : `1px solid rgba(255, 90, 106, 0.2)` }}>
                        <Box className={classes.insideEnergyDesc}>
                            <Typography variant='body2' style={{ fontWeight: 700, fontSize: '20px', color: '#3A3A3A' }}>
                                {packBenefits.label}
                            </Typography>
                            <Typography variant='body2' style={{ margin: '13px 0px', color: '#3A3A3A', fontSize: '14px', fontWeight: 400 }}>
                                {/* 518 picklist_entries */} {JSON.parse(localStorage.getItem('packBenefitsSubheading'))}
                            </Typography>
                        </Box>

                        <Box className={classes.benefitsWrapper}>
                            {
                                packBenefits.picklist_entries.map((data) =>
                                    <Box className={classes.benefitsWrapperBoxes}>
                                        <Box className={classes.benefitsWrapperImage}>
                                            {data.key === 'video_vet' && <img src={plan_switch ? VideoVetBlue : VideoVetPink} style={{ height: 50, width: 50 }} />}
                                            {data.key === 'emergency_fund' && <img src={plan_switch ? EmergencyFundBlue : EmergencyFundPink} style={{ height: 50, width: 50 }} />}
                                            {data.key === 'round_up' && <img src={plan_switch ? RoundupBlue : RoundupPink} style={{ height: 50, width: 50 }} />}
                                        </Box>
                                        <Typography variant='body2' style={{ fontSize: 13, color: plan_switch ? Theme.palette.secondary.main : Theme.palette.primary.main, fontWeight: 700, marginBottom: 10 }}>
                                            {data.label}
                                        </Typography>
                                        <Typography variant='body2' className={classes.benefitWrapperContent} style={{ fontSize: 12, marginBottom: 20 }}>
                                            {data.descriptions}
                                        </Typography>
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>

                    {/* Choosing GC (IMPACT)*/}
                    <Box className={classes.choosingGC}>
                        <Typography variant='body2' style={{ fontSize: 16, fontWeight: 700, color: '#3A3A3A' }}>
                            {packImpacts.label}
                        </Typography>
                        <Typography variant='body2' style={{ fontSize: 14, marginTop: 14, marginBottom: 10 }}>
                            {/* 519 picklist_entries */} {JSON.parse(localStorage.getItem('packImpactsSubheading'))}
                        </Typography>

                        <Box className={classes.ChoosingGCWrapper}>
                            {
                                packImpacts.picklist_entries.map((data) =>
                                    <Box className={classes.benefitsWrapperBoxes}>
                                        {data.key === 'rescue_pack' && <img src={plan_switch ? MedBlue : MedPink} className={classes.impactLogos} />}
                                        {data.key === 'orphan_care' && <img src={plan_switch ? HeartBlue : HeartPink} className={classes.impactLogos} />}
                                        {data.key === 'neuter_surgeries' && <img src={plan_switch ? BandageBlue : BandagePink} className={classes.impactLogos} />}
                                        <Typography variant='body2' style={{ fontSize: 13, color: '#4D4D4D', fontWeight: 700, marginBottom: 10 }}>
                                            {data.label}
                                        </Typography>
                                        <Typography variant='body2' className={classes.benefitWrapperContent} style={{ fontSize: 12 }}>
                                            {data.descriptions}
                                        </Typography>
                                    </Box>
                                )
                            }
                        </Box>

                        <Box style={{ marginTop: 20 }}>
                            <Typography variant='body2' style={{ color: '#21212185', fontSize: 12, textAlign: 'justify' }}>
                                Impact is measured over three years, equates to one of the highlighted treatments above, and assumes both customer opt-in to Roundup for Rescues and one friends and family referral.
                            </Typography>
                        </Box>

                    </Box>

                    {/* FAQ */}
                    <Box className={classes.faqDiv}>
                        <Typography variant='body2' style={{ fontSize: 16, fontWeight: 700, color: '#3A3A3A', marginBottom: 25 }}>
                            {faqData.label}
                        </Typography>

                        <Box className={classes.accTopDiv}>

                            <Box className={classes.faqQBox}>
                                {faqData.picklist_entries.slice(0, (faqData.picklist_entries.length) / 2).map((data, i) =>
                                    <Grid>
                                        <Accordion className={classes.accordionCss} style={{ backgroundColor: '#F9F9F9', boxShadow: 'none', borderRadius: 8 }} disableGutters={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon style={{ borderRadius: 100, backgroundColor: '#F3F3F3' }} />}
                                                aria-controls={`panel${i}a-content`}
                                                id={`panel${i}a-header`}
                                            >
                                                <Typography variant='body2' style={{ fontSize: 13, color: '#3A3A3A', fontWeight: 400 }}>
                                                    {data.label}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: 'white', borderRadius: 8 }}>
                                                <Typography variant='body2' style={{ fontSize: 12, color: '#21212185' }}>
                                                    {data.descriptions}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <style jsx>{`
                                        .MuiAccordion-root.Mui-expanded{
                                            border: 2px solid ${plan_switch ? Theme.palette.secondary.main : Theme.palette.primary.main}
                                        }
                                        .MuiAccordion-root{
                                            border: 2px solid white
                                        }
                                    `}</style>
                                    </Grid>
                                )}
                            </Box>

                            <Box className={classes.faqQBox}>
                                {faqData.picklist_entries.slice((faqData.picklist_entries.length) / 2, faqData.picklist_entries.length + 1).map((data, i) =>
                                    <Grid>
                                        <Accordion className={classes.accordionCss} style={{ backgroundColor: '#F9F9F9', boxShadow: 'none', borderRadius: 8 }} disableGutters={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon style={{ borderRadius: 100, backgroundColor: '#F3F3F3' }} />}
                                                aria-controls={`panel${i}a-content`}
                                                id={`panel${i}a-header`}
                                            >
                                                <Typography variant='body2' style={{ fontSize: 13, color: '#3A3A3A', fontWeight: 400 }}>
                                                    {data.label}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: 'white', borderRadius: 8 }}>
                                                <Typography variant='body2' style={{ fontSize: 12, color: '#21212185' }}>
                                                    {data.descriptions}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                            </Box>

                        </Box>

                    </Box>

                </Box>
            </Fade>
        </Modal>
    );
};

export default PlanDetailsModal;

import { Field, reduxForm } from "redux-form";
import InputFields, {
  FieldTitle,
  ThemedButton,
} from "src/Components/InputFields";
import { makeStyles } from "@mui/styles";
import "react-phone-input-2/lib/bootstrap.css";
import getInitFields from "src/utils/getInitFields";
import * as Yup from "yup";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import getScreenDetail from "src/utils/screenDetails";
import GCTooltip from "src/Components/GCTooltip";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20
  },
  checkboxContainer: {
    paddingLeft: 10,
    paddingTop: 10,
  },
  checkBoxDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  checkCss: {
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
}));

const fieldErrors = JSON.parse(localStorage.getItem("fieldErrors"));
const fieldTitles = JSON.parse(localStorage.getItem("fieldTitles"));

if (!fieldErrors) {
  window.NREUM.noticeError("contact-details> missing_data: <fieldErrors>")        // mylog
}
if (!fieldTitles) {
  window.NREUM.noticeError("contact-details> missing_data: <fieldTitles>")        // mylog
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(fieldErrors?.contacts.email.correctEmail)
    .trim()
    .nullable()
    .required(fieldErrors?.required),
  phone_number: Yup.string().trim().nullable()
    .test("length", fieldErrors?.contacts.phone_number.length, value => {
      let check = value && (value.length === 11 || value.length === 17)
      return check;
    })
    .required(fieldErrors?.required),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

let Contact = (props) => {
  const styles = useStyles();
  const { handleSubmit, submitting, invalid } = props;
  const [screenDetail, setScreenDetail] = useState(null);

  useEffect(() => {
    const init = async () => {
      let scrData = await getScreenDetail("contact-details");
      setScreenDetail(scrData);
    };
    init();
  }, []);

  return (
    <form id="contact-details" onSubmit={handleSubmit}>
      <div className={styles.mainDiv}>
        <div>
          {/* <FieldTitle required>Your email</FieldTitle> */}
          <Field
            name="email"
            disabled={JSON.parse(sessionStorage.getItem("added_email")) === 1}
            component={InputFields}
            placeholder={fieldTitles?.contacts.email}
            type="textField"
            otherType="email"
          />
        </div>
      </div>
      <div className={styles.mainDiv}>
        {/* <label htmlFor="phone">Your phone number</label> */}
        <div>
          {/* <FieldTitle required>Your phone number</FieldTitle> */}
          <Field
            name="phone_number"
            component={InputFields}
            placeholder={fieldTitles?.contacts.phone_number}
            type="phoneNumber"
          />
        </div>
      </div>
      <Box className={styles.checkboxContainer}>
        <Box className={styles.checkBoxDiv}>
          <Field
            component={InputFields}
            variant="outlined"
            type="checkBox"
            name="sms_communication"
            className={styles.checkCss}
          />
          <Typography variant="body2">Opt in to SMS communications</Typography>
        </Box>
        <Box className={styles.checkBoxDiv}>
          <Field
            component={InputFields}
            variant="outlined"
            type="checkBox"
            name="marketing_communication"
            className={styles.checkCss}
          />
          <Typography variant="body2">
            Opt in to marketing communications
          </Typography>
        </Box>
      </Box>
      <Box className={styles.forContinueAndTooltop}>
        <Box>
          <ThemedButton
            disabled={invalid}
            isSubmitting={submitting}
            type="submit"
          >
            Continue
          </ThemedButton>
        </Box>
        <Box>
          {screenDetail && screenDetail.tooltip && (
            <GCTooltip>{screenDetail.tooltip}</GCTooltip>
          )}
        </Box>
      </Box>
    </form >
  );
};

Contact = reduxForm({
  initialValues: getInitFields("contact-details"),
  form: "contact-details",
  destroyOnUnmount: false,
  validate,
})(Contact);

export default Contact;

import { Dialog, Box, Button, Typography, IconButton, DialogActions } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import arrowRight from "src/assets/arrow-right.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 250,
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      height: 350,
    }
  },
  cancel: {
    top: 10,
    right: 10,
    position: "absolute",
  },
  cancelIcon: {
    fill: "rgba(33, 33, 33, 0.52)",
    fontSize: "24px !important",
    [theme.breakpoints.up("md")]: {
      fontSize: "32px !important",
    }
  },
  title: {
    fontSize: "26px !important",
    lineHeight: "34px !important",
    textAlign: "center",
    color: "#3A3A3A",
    fontWeight: "bolder !important",
    marginBottom: "10px !important",
    marginTop: "10px !important",
    [theme.breakpoints.up("md")]: {
      fontSize: "34px !important",
    }

  },
  childBox: {
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 32,
      paddingRight: 32
    }
  },
  desc: {
    width: 258,
    fontWeight: "600 !important",
    lineHeight: "22px !important",
    [theme.breakpoints.up("md")]: {
      width: 386,
      height: 48,
    }
  },
  flexBox: {
    display: "flex",
    marginTop: 5,
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: 32,
    }
  },
  submitBtn: {
    height: 48,
    width: 185,
    boxShadow: 3,
    "&.MuiButtonBase-root:hover": {
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.up("md")]: {
      height: 60,
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "50%",
    zIndex: 3,
  },
  btnShadow: {
    position: "absolute",
    left: "12.43%",
    right: "11.89%",
    top: "25%",
    bottom: "0%",
    background: theme.palette.primary.main,
    opacity: 1,
    filter: "blur(40px)",
    borderRadius: "29px",
    zIndex: 1,
  },
}));

const ConfirmationModal = ({
  open,
  handleClose,
  agree,
  disagree,
  title,
  children,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose ? handleClose : () => null}     // checking if the popup can be ignored
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <Box className={classes.root}>
        {disagree && !disagree?.title &&                         // if it has disagree/cancel button then no need for the cross icon
          <Box className={classes.cancel}>
            <IconButton onClick={disagree?.onClick}>
              <CancelOutlinedIcon
                className={classes.cancelIcon}
              />
            </IconButton>
          </Box>}
        <Typography className={classes.title} sx={{ fontSize: 23 }} >
          {title}
        </Typography>
        <Box className={classes.childBox}>
          <Typography className={classes.desc} variant="body2" textAlign={"center"}>
            {children}
          </Typography>
        </Box>
        <Box className={classes.flexBox}>
          {disagree?.title ?                     // action buttons for multiple choice
            <DialogActions>
              <Button color="inherit" onClick={disagree?.onClick}>{disagree?.title}</Button>
              <Button onClick={agree.onClick} autoFocus>
                {agree.title}
              </Button>
            </DialogActions>
            : <Button                             // continue button for only one choice
              variant="contained"
              className={classes.submitBtn}
              onClick={agree.onClick}
              sx={{
                borderRadius: 32,
              }}
            >
              <Box className={classes.btnContainer}>
                <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                  {agree.title}
                </Typography>
                <img
                  src={arrowRight}
                  alt="arrow-left"
                  style={{ objectFit: "contain", marginLeft: 5 }}
                />
              </Box>
              <Box className={classes.btnShadow} />
            </Button>
          }
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmationModal;

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Field, reduxForm } from "redux-form";
import { ThemedButton, ThemedTitle } from "src/Components/InputFields";
import { makeStyles } from "@mui/styles";
import getInitFields from "src/utils/getInitFields";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import getScreenDetail from "src/utils/screenDetails";
import GCTooltip from "src/Components/GCTooltip";
import Theme from "src/Theme/theme";

const useStyles = makeStyles((theme) => ({
  convDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "center",
    },
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
}));

const validationSchema = Yup.object().shape({
  moving_switching: Yup.string().nullable().required("Required"),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

let SwitchingProvider = (props) => {
  const { handleSubmit, submitting, invalid } = props;
  const classes = useStyles();
  const [screenDetail, setScreenDetail] = useState(null);

  useEffect(() => {
    const init = async () => {
      let details = await getScreenDetail("switching");
      setScreenDetail(details);
    };
    init();
  }, []);
  return (
    <form id="switching" onSubmit={handleSubmit}>
      <br />
      <Box className={classes.convDiv}>
        <Box
          class="cc-selector-2"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Field
            id="switchingProvider"
            component="input"
            type="radio"
            name="moving_switching"
            value="switching"
            style={{ marginLeft: 23 }}
          />
          <label
            class="drinkcard-cc switchingProvider"
            for="switchingProvider"
            style={{
              width: 185,
              height: 64,
              borderRadius: 8,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" marginLeft="40px">Just switching</Typography>
          </label>
        </Box>
        <Box sx={{ height: 14, width: 14 }} />
        <Box
          class="cc-selector-2"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Field
            id="movingHome"
            component="input"
            type="radio"
            name="moving_switching"
            value="moving"
            style={{ marginLeft: 23 }}
          />
          <label
            class="drinkcard-cc movingHome"
            for="movingHome"
            style={{
              width: 185,
              height: 64,
              borderRadius: 8,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" marginLeft="40px">We’re moving</Typography>
          </label>
        </Box>

      </Box>

      <style jsx>{`
        .cc-selector input {
          margin: 0;
          padding: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }

        .cc-selector-2 input {
          height: 28px;
          width: 28px;
          accent-color: ${Theme.palette.primary.main};
          position: absolute;
          z-index: 999;
        }

        .cc-selector-2 input:active + .drinkcard-cc,
        .cc-selector input:active + .drinkcard-cc {
          opacity: 0.9;
        }
        .cc-selector-2 input:checked + .drinkcard-cc,
        .cc-selector input:checked + .drinkcard-cc {
          background-color: white;
          border: 2px solid #ff5a6a;
          -webkit-filter: none;
          -moz-filter: none;
          filter: none;
        }
        .drinkcard-cc {
          cursor: pointer;
          background-size: contain;
          background-repeat: no-repeat;
          border: 2px solid #e6e6e6;
          background-color: grey;
          display: inline-block;
          width: 100px;
          height: 70px;
          -webkit-filter: brightness(1.8) grayscale(1) opacity(0.7);
          -moz-filter: brightness(1.8) grayscale(1) opacity(0.7);
          filter: brightness(1.8) grayscale(1) opacity(0.7);
        }
        .drinkcard-cc:hover {
          -webkit-filter: brightness(1.8) grayscale(1) opacity(0.7);
          -moz-filter: brightness(1.8) grayscale(1) opacity(0.7);
          filter: brightness(1.8) grayscale(1) opacity(0.7);
        }
      `}</style>
      <Box className={classes.forContinueAndTooltop}>
        <Box>
          <ThemedButton
            isSubmitting={submitting}
            disabled={invalid}
            type="submit"
          >
            Continue
          </ThemedButton>
        </Box>
        {/* {console.log(screenDetail)} */}
        <Box>
          {screenDetail && screenDetail.tooltip && (
            <GCTooltip>{screenDetail.tooltip}</GCTooltip>
          )}
        </Box>
      </Box>
    </form>
  );
};

SwitchingProvider = reduxForm({
  initialValues: getInitFields("switching"),
  destroyOnUnmount: false,
  form: "SwitchingProvider",
  validate,
})(SwitchingProvider);

export default SwitchingProvider;

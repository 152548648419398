/* global google */

import { Field, reduxForm } from "redux-form";
import InputFields, {
  FieldTitle,
  ThemedButton,
  ThemedTitle,
} from "src/Components/InputFields";
import Geosuggest from "react-geosuggest";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  CircularProgress,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Pin from "src/assets/Pin.svg";
import markerPin from "src/assets/markerPin.png";
import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import getScreenDetail from "src/utils/screenDetails";
import axios from "src/utils/axios";
import * as cancelAxios from "axios";

import axiosEndPoint from "axios";
import getInitFields from "src/utils/getInitFields";
import * as Yup from "yup";
import GCTooltip from "src/Components/GCTooltip";
import { useNavigate } from "react-router-dom";
import InfoModal from "src/Components/InfoModal";
import saveDistributionPoints from "src/utils/saveDistributionPoints";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  mapBox: {
    border: "0.5px solid #e4e4e4 !important",
    width: 337,
    height: 124,
    marginTop: 20,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      border: "1px solid #e4e4e4 !important",
      width: 600,
      height: 168,
    },
    // pointerEvents: "none",
    backgroundColor: "gray",
    borderRadius: 20,
    overflow: "hidden",
    transition: "height 0.2s ease",
  },
  InputFields: {
    position: "relative",
    overflowX: "hidden",
    fontSize: 16,
    // height: 48,
    borderRadius: "10px !important",
    width: "330px !important",
    padding: 8,
    paddingRight: 8,
    margin: -8,
    "&::placeholder": {
      color: "rgba(33, 33, 33, 0.52)",
    },
    [theme.breakpoints.up("md")]: {
      width: "600px !important",
    },
  },
  plInputProps: {
    padding: 10,
    paddingLeft: 38,
    fontSize: 16,
    fontFamily: "Nunito",
    borderRadius: 10,
  },
  geoSuggestInputFields: {
    border: "1px solid grey",
    "&:hover": {
      border: "2px solid #FF5B6A",
    },
    overflowX: "hidden",
    fontSize: 16,
    // height: 48,
    borderRadius: "10px !important",
    width: "330px !important",
    padding: 0,
    paddingLeft: 8,
    paddingRight: 8,
    margin: 0,
    "&::placeholder": {
      color: "rgba(33, 33, 33, 0.52)",
    },
    [theme.breakpoints.up("md")]: {
      width: "390px !important",
    },
  },
  geosuggestInput: {
    textOverflow: "ellipsis",
    // border: "5px !important",
    height: 44,
    paddingLeft: 38,
    border: "none !important",
    fontFamily: "Nunito",
    fontSize: 16,
    width: "280px !important",
    [theme.breakpoints.up("md")]: {
      width: "340px !important",
    },
  },
  geoSuggestContanier: {
    whiteSpace: "wrap",
    overflow: "hidden",
    margin: 0,
  },
  suggests: {
    padding: 0,
    margin: 0,
    textOverflow: "ellipsis",
  },
  suggestItem: {
    cursor: "pointer",
    marginBottom: 2,
    padding: 8,
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "rgba(111, 108, 108, 0.05)",
    },
  },
  suggestItemActive: {},
  flexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },
  addressBox: {
    display: "flex",
    alignItems: "flex-start",
    // paddingTop: 10,
  },
  addressTextbox: {
    [theme.breakpoints.up("md")]: {
      width: '100%'
    },
  },
  pin: {
    position: "absolute",
    top: 10,
    left: 15,
    marginTop: 5,
    marginRight: 5,
    padding: 5,
    backgroundColor: "#FFF7F8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 40,
    cursor: "default",
  },
  markerPin: {
    width: 28,
    height: 38.88,
    position: "absolute",
    top: -22,
    left: -14,
  },
  pinImg: {
    width: 22,
    height: 22,
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
}));

const fieldErrors = JSON.parse(localStorage.getItem("fieldErrors"));
const fieldTitles = JSON.parse(localStorage.getItem("fieldTitles"));

const validationSchema = Yup.object().shape({
  address: Yup.string().trim().nullable().required(fieldErrors?.required),
  apt_unit: Yup.string().trim().nullable(),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

const plInputProps = {
  padding: 10,
  paddingLeft: 38,
  fontSize: 16,
  fontFamily: "Nunito",
  borderRadius: 10,
};

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
let cancelToken;

let Address = (props) => {
  const classes = useStyles();
  const { handleSubmit, invalid, submitting } = props;
  const [address, setAddress] = useState(null);
  const [esiidIsNull, setEsiidIsNull] = useState(true);
  const [aptUnit, setAptUnit] = useState(null);
  const [suggestion, setSuggestions] = useState([]);
  const [location, setLocation] = useState(null);
  const [loadingSugg, setLoadingSugg] = useState(false);
  const [screenDetail, setScreenDetail] = useState(null);
  let errorInitVal = { error: false, message: null };
  const [subError, setSubError] = useState(errorInitVal);
  const [value, setValue] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const navigate = useNavigate();

  const getLatlong = async (address) => {
    // let address = "3410 S 5th St, 3410 S 5th St, TX 76502";
    try {
      let res = await axiosEndPoint.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            key: apiKey,
            address: address,
          },
        }
      );
      let location = res.data.results[0] ? res.data.results[0].geometry.location : null;
      if(location===null || location.lat===null || location.lng===null){
        setEsiidIsNull(true)
      }
      else{
        setEsiidIsNull(false)
      }
      setLocation({
        address: address,
        ...location,
      });
    } catch (e) {
      console.log(address, e);
    }
  };

  const getAddressSuggestions = async (term) => {
    if (term.length === 0) {
      setLocation(null);
    }

    //Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request")
    }

    //Save the cancel token for the current request
    cancelToken = await cancelAxios.CancelToken.source();
    
    setLoadingSugg(true);
    var formBody = new FormData();
    formBody.append("term", term);
      try {
        let response = await axios.post(`/pl/address_validator`, {
          address: term
        }, { cancelToken: cancelToken.token });
        if (response.data.data.length) {
          setSuggestions(response.data.data);
          response.data.data.map((addr)=>{
            if(addr.label === term){
              addr.esiid ? setEsiidIsNull(false) : setEsiidIsNull(true)
            }
            else{
              setEsiidIsNull(true)
            }
          })
          // response.data.data[0].esiid ? setEsiidIsNull(false) : setEsiidIsNull(true)
        }
        else{
          setEsiidIsNull(true)
        }
      } catch (e) {
        setEsiidIsNull(true)
      }
    setLoadingSugg(false);
  };

  const onAddressSubmission = async (values) => {
    let uuid = await localStorage.getItem("tokens");
    uuid = JSON.parse(uuid).uuid;
    let data = null;
    if(!address || !location || !location.lat || !location.lng){
      setSubError(true,"didn't got the required values")
      return null
    }
    let formValues = {
      address: address,
      pet_address: address,
      apt_unit: values.apt_unit,
      lat: String(location.lat),
      lon: String(location.lng),
      esiid: null,
      street: null,
      city: null,
      state: null
    };
    if (suggestion && suggestion.length) {
      suggestion.map(async (item) => {
        if (item.value === address) {
          data = { ...item, apt_unit: values.apt_unit };
          localStorage.setItem("full-address", JSON.stringify(data));
          formValues = {
            ...formValues,
            esiid: data.esiid,
            street: data.street,
            city: data.city,
            state: data.state
          };
        }
      });
    } else {
      formValues = await localStorage.getItem("address");
      formValues = JSON.parse(formValues);
    }
    if(formValues.esiid !== null){
      try {
        await saveDistributionPoints(formValues.esiid);
        let response = await axios.patch(`/customer/update`, {
          ...formValues,
          uuid: uuid,
        });
        localStorage.setItem("address", JSON.stringify(formValues));
        localStorage.setItem("last_submitted", "address");
        navigate("/switching");
      } catch (error) {
        // console.error(e);
        // setSubError({ error: true, message: e.message })
        // console.log(error.response.status, error.response.data.message, error.message)
        try{
          if(error.response.status && error.response.status === 406){
            // setSubError({error: true, message: error.response.data.message})
            setSubError({error: true, message: JSON.parse(localStorage.getItem("nonResidentialAddressMsg"))})//for pending -- Picklist code - 520
          } else if (error.response.status && error.response.status === 409) {
            setSubError({error: true, message: JSON.parse(localStorage.getItem("pendingEnrollmentMsg"))})//for pending -- Picklist code - 515
          } else {
            setSubError({error: true, message: JSON.parse(localStorage.getItem("activeEnrollmentMsg"))})//for others -- Picklist code - 516
          }
        }
        catch(e){
          setSubError({error: true, message: JSON.parse(localStorage.getItem("activeEnrollmentMsg"))})//for others -- Picklist code - 516
        }
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      let json = getInitFields("address");
      if (json && json.address) {
        setAddress(json.address);
        setLocation({
          address: json.address,
          lat: Number(json.lat),
          lng: Number(json.lon)
        })
        setAptUnit(json.apt_unit);
      }
    };
    init();
  }, []);

  // const staticMapURL = `https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=13&size=600x300&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=${apiKey}`;

  useEffect(() => {
    const init = async () => {
      let scrData = await getScreenDetail("address");
      setScreenDetail(scrData);
    };
    init();
  }, []);

  const createMapOptions = (maps) => {
    return {
      panControl: false,
      // mapTypeControl: false,
      scrollwheel: false,
      // zoomControl: false,
      scaleControl: true,
      zoomControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      gestureHandling: "none",
    }
  }

  return (
    <form id="address" onSubmit={handleSubmit(onAddressSubmission)}>
      <InfoModal open={subError.error} message={subError.message} close={() => setSubError(errorInitVal)} />
      <Box className={classes.root}>
        {location && (
          <Box className={classes.mapBox}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: apiKey }}
              defaultCenter={location}
              defaultZoom={18}
              options={createMapOptions}
            >
              <img
                src={markerPin}
                alt="marker-pin"
                className={classes.markerPin}
              />
            </GoogleMapReact>
            {/* <img src={staticMapURL} alt="map-img" className={classes.mapImg} /> */}
          </Box>
        )}
        <Box className={classes.flexBox}>
          <div>
            {/* {window.screen.width >= 720 ? <FieldTitle required>Where do you live</FieldTitle> : <Box style={{marginTop: 20}} />} */}
            <Box style={{marginTop: 20}} />
            <Autocomplete
              // freeSolo
              className={classes.InputFields}
              id="free-solo-2-demo"
              loading={loadingSugg}
              value={address}
              forcePopupIcon={false}
              onChange={(event, newValue) => {
                setAddress(newValue);
                newValue && getLatlong(newValue);
              }}
              // disableClearable
              autoComplete={true}
              autoHighlight={true}
              clearOnBlur={false}
              loadingText={'Getting your address...'}
              // blurOnSelect={true}
              // autoSelect={true}
              options={suggestion.map((option) => option.value)}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                if((event && (event.type!=='click' && event.type!=='keydown')) || address){
                  getAddressSuggestions(newInputValue);
                }
                else if(!event){
                  getAddressSuggestions(newInputValue);
                }
                setInputValue(newInputValue);
              }}
              style={{ borderRadius: 10 }}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    size="small"
                    label={fieldTitles?.address.address}
                    className={classes.addressTextbox}
                    InputProps={{
                      ...params.InputProps,
                      // type: "search",
                      style: { ...plInputProps },
                    }}
                    InputLabelProps={{
                      shrink: true,
                  }}
                  />
                  <Box className={classes.pin}>
                    <img src={Pin} alt="pin icon" className={classes.pinImg} />
                  </Box>
                </>
              )}
            />
          </div>
          <div style={{ marginLeft: 10, display: "none" }}>
            {window.screen.width >= 720 ? <FieldTitle>Apartment Unit</FieldTitle> : <Box style={{marginTop: 20}} />}
            <Field
              value={aptUnit}
              component={InputFields}
              type="textField"
              name="apt_unit"
              placeholder="Apt/Unit"
              customClass="aptUnit"
            />
          </div>
        </Box>
      </Box>
      <Box className={classes.forContinueAndTooltop}>
        <Box>
          <ThemedButton
            disabled={invalid || !address || esiidIsNull}
            isSubmitting={submitting}
            type="submit"
          >
            Continue
          </ThemedButton>
        </Box>
        <Box>
          {screenDetail && screenDetail.tooltip && (
            <GCTooltip>{screenDetail.tooltip}</GCTooltip>
          )}
        </Box>
      </Box>
    </form>
  );
};

Address = reduxForm({
  initialValues: getInitFields("address"),
  destroyOnUnmount: false,
  form: "address",
  validate,
})(Address);

export default Address;

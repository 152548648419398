import { useState, useEffect, useLayoutEffect } from "react";
import axios from "src/utils/axios";
import { Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Field, formValueSelector, reduxForm } from "redux-form";
import getScreenDetail from "src/utils/screenDetails";
import GCTooltip from "src/Components/GCTooltip";
import clsx from "clsx";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//custom component
import PlanCard from "src/Components/PlanCard";
import InputFields from "src/Components/InputFields";
import { connect } from "react-redux";
import Theme from "src/Theme/theme";
import cryptoJS from "src/utils/cryptoJS";

const useStyles = makeStyles((theme) => ({
  planDiv: {
    width: "385px",
    position: 'relative',
    marginTop: '-20px',
    [theme.breakpoints.up("sm")]: {
      position: 'relative',
      marginTop: '50px',
      width: "1000px",
    },
  },
  toggleBox: {
    mt: 2,
    display: "flex",
    padding: "0 10",
    justifyContent: "center",
    alignItems: 'center',
    marginBottom: 34
  },
  dotNav: {
  }
}));

let ShowPlans = (props) => {
  const { handleSubmit, plan_switch, change } = props;
  const classes = useStyles();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [screenDetail, setScreenDetail] = useState(null);
  const navigate = useNavigate();
  const [faqData, setFaqData] = useState(null);
  const [packBenefitsGG, setPackBenefitsGG] = useState(null);
  const [packBenefitsGE, setPackBenefitsGE] = useState(null);
  const [packImpactGG, setPackImpactGG] = useState(null);
  const [packImpactGE, setPackImpactGE] = useState(null);
  const [docDetails, setDocDetails] = useState({});
  // const [record, setRecord] = useState(null);
  // const [planCompleteData, setPlanCompleteData] = useState(null);
  let record = null;
  let planCompleteData = null;

  // const [toggleChecked, setToggleChecked] = useState(null);

  const getData = async (name) => {
    let tokens = await localStorage.getItem(name);
    return JSON.parse(tokens);
  };

  let zipCode = JSON.parse(localStorage.getItem("zip-code") || "{}");
  if (zipCode.zip_code) {
    let plan = JSON.parse(localStorage.getItem("tduOfZip"));
    let products = plan.webProducts || null;
    record = products;
    planCompleteData = plan;
    // setPlanCompleteData(plan);
    // setRecord(products);
  }

  const init = async () => {

    let scrData = await getScreenDetail("plans");
    setScreenDetail(scrData);

    setDocDetails({
      eflDetails: localStorage.getItem("eflDetails"),
      yracDetails: localStorage.getItem("yracDetails"),
      tosDetails: localStorage.getItem("tosDetails"),
    })

    document.cookie = `${"plans"}=${JSON.stringify(record)}`;
    try {
      let response = await axios.get("get/picklist/faq,pack_benefits_gg,pack_benefits_ge,pack_impact_gg,pack_impact_ge");
      setFaqData(response.data.picklist[0]);
      setPackBenefitsGG(response.data.picklist[1]);
      setPackBenefitsGE(response.data.picklist[2]);
      setPackImpactGG(response.data.picklist[3]);
      setPackImpactGE(response.data.picklist[4]);
    } catch (e) {
      //console.log(e);
    }
    setLoading(false);
    // console.log(currentPlanId)
  };

  useEffect(() => {
    let path = window.location.pathname.replace("/", "")
    if (path === "plans") {
      init();
    }
    return () => null;
  }, []);

  useEffect(() => {
    let res = localStorage.getItem("plan")
    let planDetails = JSON.parse(res);
    if (planDetails && planDetails.plan_id && record) {
      record.map((data) => {
        if (data.productID === Number(planDetails.plan_id)) {
          data.greenEnergyPercent === 1 ? change("plan_switch", true) : change("plan_switch", false);
        }
      })
    }
  }, [])

  function useWindowSize() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }


  let dotSettingsMobile = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
  };

  let dotSettingsDesktop = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
  };

  let windowSize = useWindowSize();
  let dotSettings = windowSize >= 720 ? dotSettingsDesktop : dotSettingsMobile;

  return (
    <form id="show-plans" onSubmit={handleSubmit}>
      <Box className={classes.toggleBox}>
        <Typography onClick={() => change("plan_switch", false)} variant="body2" style={{ color: !plan_switch ? '#21212185' : '#3A3A3A', fontWeight: 600, cursor: 'pointer' }}>
          100% Green
        </Typography>
        <Field
          name="plan_switch"
          type="switch1"
          component={InputFields}
          additional={() => null}
        />
        <Typography onClick={() => change("plan_switch", true)} variant="body2" style={{ color: !plan_switch ? '#3A3A3A' : '#21212185', fontWeight: 600, cursor: 'pointer' }}>
          {/* GoodGreen */}
          <Box style={{ width: "82px" }} />
        </Typography>
      </Box>
      <Box>
        <Box className={classes.planDiv}>
          {
            loading ?
              (
                <Box
                  sx={{
                    width: "100%",
                    height: "40vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )
              :
              (
                !loading ? (record ?

                  (
                    <Slider {...dotSettings} className={classes.dotNav}>
                      {
                        (
                          plan_switch ?
                            record.map((singlePlan, index) => {
                              if (singlePlan.greenEnergyPercent === 1) {
                                return (
                                  <Box>
                                    <PlanCard
                                      plan={singlePlan}
                                      plan_switch={plan_switch}
                                      planCompleteData={planCompleteData}
                                      faqData={faqData}
                                      packBenefits={packBenefitsGG}
                                      packImpacts={packImpactGG}
                                      docDetails={docDetails}
                                    />
                                  </Box>
                                )
                              }
                            }
                            )
                            :
                            record.map((singlePlan, index) => {
                              if (singlePlan.greenEnergyPercent === 0) {
                                return (
                                  <Box>
                                    <PlanCard
                                      plan={singlePlan}
                                      plan_switch={plan_switch}
                                      planCompleteData={planCompleteData}
                                      faqData={faqData}
                                      packBenefits={packBenefitsGE}
                                      packImpacts={packImpactGE}
                                      docDetails={docDetails}
                                    />
                                  </Box>
                                )
                              }
                            }
                            )
                        )
                      }
                    </Slider>
                  )
                  :
                  (
                    navigate("/no-plans")
                  ))
                  : null
              )
          }
        </Box>
      </Box>
      <Box>
        {screenDetail && screenDetail.tooltip && (
          <GCTooltip>{screenDetail.tooltip}</GCTooltip>
        )}
      </Box>
      <style jsx>{`
        .slick-dots {
          top: ${windowSize >= 720 ? '-530px' :( windowSize >= 600 ? '-525px' : '-460px')};
          position: relative;
        }
        .slick-dots li.slick-active button:before{
          font-size:15px;
          color:${plan_switch ? Theme.palette.secondary.main : Theme.palette.primary.main};          
        }
      `}</style>
    </form>
  );
};

ShowPlans = reduxForm({
  // a unique name for the form
  form: "show-plans",
  destroyOnUnmount: false,
})(ShowPlans);

const selector = formValueSelector('show-plans') // <-- same as form name
ShowPlans = connect(
  state => {
    // can select values individually
    const plan_switch = selector(state, 'plan_switch')
    return {
      plan_switch,
    }
  }
)(ShowPlans)

export default ShowPlans;

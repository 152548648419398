import { makeStyles } from "@mui/styles";
import { Avatar, Box } from "@mui/material";
import charlie from "src/assets/charlie-1.png";
import home from "src/assets/home.png";
import Heart from "src/Components/Heart";
import { keyframes } from "@mui/system";

const pulse = keyframes`
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
`;

let pulseAnimation = `${pulse} 0.833s infinite ease`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "95%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  progressContainer: {
    marginTop: 20,
    marginBottom: 30,
    height: 3.5,
    width: "100%",
    backgroundColor: "#F4F4F4",
    borderRadius: 50,
    position: "relative",
  },
  heart: {
    width: 14,
    height: 13,
    padding: 3,
    position: "absolute",
    top: -12,
  },
  fillerStyles: {
    height: "100%",
    borderRadius: "inherit",
    textAlign: "right",
    backgroundColor: theme.palette.primary.main,
    transition: "width 0.6s ease-out",
  },
  progressHead: {
    position: "relative",
  },
  avatar: {
    position: "absolute",
    right: -20,
    top: -20,
    [theme.breakpoints.up("md")]: {
      right: -17,
      top: -20,
    },
    backgroundColor: "#FFDEE1",
    borderRadius: "50%",
  },
}));

const ProgressBar = ({ steps, totalSteps, completed }) => {
  const classes = useStyles();
  let hearts = [];

  if (!hearts.length) {
    let newArray = [];
    let screens = ["contact-details", "address", "credit-check"];

    screens.map((screen) => {
      let indx = steps.indexOf(screen);
      let progressVal = (indx / totalSteps) * 100;
      progressVal = Math.round(progressVal);
      newArray.push(progressVal);
    });
    hearts = newArray;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.progressContainer}>
        {hearts.map((item, index) => (
          <Box key={index} className={classes.heart} style={{ left: `${item - 2}%` }}>
            <Box
              sx={{
                width: 24,
                height: 24,
                animationDelay: "0.5",
                animation: completed > item ? pulseAnimation : "none",
              }}
            >
              <Heart active={completed > item} />
            </Box>
          </Box>
        ))}
        <Box className={classes.fillerStyles} sx={{ width: `${completed}%` }}>
          <Box className={classes.progressHead}>
            {completed !== 100 && (
              <Box className={classes.avatar}>
                <Avatar
                  alt="progress-head-icon"
                  src={charlie}
                  sx={{
                    objectFit: "contain",
                    width: 42,
                    height: 42,
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <img
        alt="progress-home-icon"
        src={home}
        style={{
          objectFit: "contain",
          width: 32,
          height: 32,
          marginBottom: 10,
        }}
      />
    </Box>
  );
};

export default ProgressBar;

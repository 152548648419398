import { MenuItem, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import GCTooltip from "src/Components/GCTooltip";
import InputFields, {
  FieldTitle,
  ThemedButton,
} from "src/Components/InputFields";
import getScreenDetail from "src/utils/screenDetails";
import * as Yup from "yup";
import getInitFields from "src/utils/getInitFields";
import axios from "src/utils/axios";

const useStyles = makeStyles((theme) => ({
  InputFields: {
    fontSize: 16,
    height: 48,
    borderRadius: "10px !important",
    width: "338px !important",
    "&::placeholder": {
      color: "#3A3A3A",
    },
    [theme.breakpoints.up("md")]: {
      width: "350px !important",
    },
  },
}));

let breedList = [];

const fieldErrors = JSON.parse(localStorage.getItem("fieldErrors"));

const validationSchema = Yup.object().shape({
  pet_name: Yup.string().trim().nullable().matches(/^[a-zA-Z]*$/, fieldErrors?.petName.pet_name.onlyAlphabets).required(fieldErrors?.required),
  pet_breed: Yup.string().trim().nullable().test(
    "valid option check", fieldErrors?.petName.pet_breed.validBreed, value=>{
      let check = (breedList.length===0 || breedList.includes(value)) ? true : false;
      return check;
    }
  ).required(fieldErrors?.required),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

let PetName = (props) => {
  const { handleSubmit, submitting, invalid } = props;
  const classes = useStyles();
  const [petType, setPetType] = useState(null);
  const [screenDetail, setScreenDetail] = useState(null);

  const [petBreeds, setPetBreeds] = useState([]);

  const getAPIdata = async (petTypeProp) => {
    let breedRes = petTypeProp === 'dog' ? await axios.get(`get/picklist/dog_breed`) : await axios.get(`get/picklist/cat_breed`);
    setPetBreeds(breedRes.data.picklist[0].picklist_entries);
    for(let i=0;i<breedRes.data.picklist[0].picklist_entries.length;i++){
      breedList.push(breedRes.data.picklist[0].picklist_entries[i].label)
    }
  }

  useEffect(async () => {
    let path = window.location.pathname.replace("/", "")
    if (path === "pet-name") {
      let data = localStorage.getItem("pet-type");
      let dataParse = JSON.parse(data);
      setPetType(dataParse.pet_type);
      await getAPIdata(dataParse.pet_type);

      const init = async () => {
        let scrData = await getScreenDetail("zip-code");
        setScreenDetail(scrData);
      };
      init();
    }
  }, []);

  return (
    <form id="pet-name" onSubmit={handleSubmit}>
      {/* <ThemedTitle>
        Woof! Sounds like a new friend for me! What’s your pup’s name & breed?
      </ThemedTitle> */}
      {/* <br /> */}
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <div style={{marginTop: 20}}>
          {/* {window.screen.width >= 720 && <FieldTitle required>
            Your {petType ? `${petType}'s` : "pet's"} name
          </FieldTitle>} */}
          <Field
            name="pet_name"
            component={InputFields}
            placeholder={`Enter ${petType}'s name`}
            type="textField"
          />
        </div>
        <div style={{marginTop: 20}}>
          {/* {window.screen.width >= 720 ? <FieldTitle required>
            Your {petType ? `${petType}'s` : "pet's"} breed
          </FieldTitle> : <Box style={{ marginTop: 10 }} />} */}
          <Field
            fullWidth
            component={InputFields}
            type="selectSearch"
            name="pet_breed"
            placeholder={`Enter ${petType}'s breed`}
            options={petBreeds.map((data) => data.label)}
          />
        </div>
      </Box>
      <Box className={classes.forContinueAndTooltop}>
        <Box>
          <ThemedButton
            disabled={invalid}
            isSubmitting={submitting}
            type="submit"
          >
            Continue
          </ThemedButton>
        </Box>
        <Box>
          {screenDetail && screenDetail.tooltip && (
            <GCTooltip>{screenDetail.tooltip}</GCTooltip>
          )}
        </Box>
      </Box>
    </form>
  );
};

PetName = reduxForm({
  // a unique name for the form
  initialValues: getInitFields("pet-name"),
  destroyOnUnmount: false,
  form: "pet-name",
  validate,
})(PetName);

export default PetName;

import axios from './axios';
import { checkUTMCode, uploadUTMs, utmRetriver } from './utmCookies';

const getTokens = async () => {
    let screenDetails = localStorage.getItem("screenDetails");
    let contactNumber = localStorage.getItem("contactNumber");
    let tokens = localStorage.getItem("tokens");
    let fieldTitles = localStorage.getItem("fieldTitles");

    if (!screenDetails) {
        try {
            let screenDetailsRes = await axios.get("screen/detail/list");
            let details = screenDetailsRes.data.screen_details;
            localStorage.setItem("screenDetails", JSON.stringify(details));
        } catch (e) {
            console.log(e);
        }
    }

    if (!contactNumber) {
        try {
            let contactNumberRes = await axios.get("get/picklist/settings");
            let details = contactNumberRes.data.picklist[0].picklist_entries;
            details.map((data) => {
                switch (data.key) {
                    case "contact_number":
                        localStorage.setItem("contactNumber", JSON.stringify(data.descriptions));
                        break;
                    case "home_url":
                        localStorage.setItem("homeURL", JSON.stringify(data.descriptions));
                        break;
                    case "payment_declined_message":
                        localStorage.setItem("paymentDeclineMessage", JSON.stringify(data.descriptions));
                        break;
                    case "pending_enrollment_msg":
                        localStorage.setItem("pendingEnrollmentMsg", JSON.stringify(data.descriptions));
                        break;
                    case "non_residential_address_msg":
                        localStorage.setItem("nonResidentialAddressMsg", JSON.stringify(data.descriptions));
                        break;
                    case "active_enrollment_msg":
                        localStorage.setItem("activeEnrollmentMsg", JSON.stringify(data.descriptions));
                        break;
                    case "info_modal_subheading":
                        localStorage.setItem("infoModalSubheading", JSON.stringify(data.descriptions));
                        break;
                    case "pack_benefits_subheading":
                        localStorage.setItem("packBenefitsSubheading", JSON.stringify(data.descriptions));
                        break;
                    case "pack_impacts_subheading":
                        localStorage.setItem("packImpactsSubheading", JSON.stringify(data.descriptions));
                        break;
                    case "no_agent_for_utm_code":
                        localStorage.setItem("noAgentIdFound", JSON.stringify(data.descriptions));
                        break;
                    case "credit_card_err_msg":
                        localStorage.setItem("creditCardErrMsg", data.descriptions);
                        break;
                    case "deposit_waiver_info":
                        localStorage.setItem("depositWaiverInfo", data.descriptions);
                        break;
                    case "auto_pay_option":
                        localStorage.setItem("autoPayOption", data.descriptions);
                        break;
                    case "deposit_waiver_option":
                        localStorage.setItem("depositWaiverOption", data.descriptions);
                        break;
                    case "efl_details":
                        localStorage.setItem("eflDetails", data.descriptions);
                        break;
                    case "yrac_details":
                        localStorage.setItem("yracDetails", data.descriptions);
                        break;
                    case "tos_details":
                        localStorage.setItem("tosDetails", data.descriptions);
                        break;
                    case "general_rescue_fund_info":
                        localStorage.setItem("generalRescueFundInfo", data.descriptions);
                        break;
                    default:
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    if (!fieldTitles) {
        try {
            let titlesJSON = await axios.get("get/picklist/titles_and_errors");
            let details = titlesJSON.data.picklist[0].picklist_entries;
            details.map((data) => {
                switch (data.key) {
                    case "field_titles":
                        localStorage.setItem("fieldTitles", data.descriptions);
                        break;
                    case "field_errors":
                        localStorage.setItem("fieldErrors", data.descriptions);
                        break;
                    default:
                        break;
                }
            })
            window.location.reload()
        } catch (e) {
            console.error(e);
        }
    }

    if (!tokens) {
        try {
            let response = await axios.get(`/get/init/record`);
            tokens = response.data;
            localStorage.setItem("tokens", JSON.stringify(tokens));
            if (!localStorage.getItem("utmlookupcode") || !localStorage.getItem("referral_code")) {
                let referral_code = utmRetriver("referral_code")
                let utmArray = checkUTMCode();
                let utmCode = null
                let nullValues = utmArray.every(value=>value===null)
                if (utmArray.length && !nullValues) {
                    utmCode = ""
                    utmArray.map(utm => {
                        if (utm !== null) {
                            utmCode.length === 0 ? 
                            utmCode = utmCode + utm :
                            utmCode = utmCode + '_' + utm;
                        }
                    })
                    localStorage.setItem("utmlookupcode", utmCode)
                }
                if (referral_code) {
                    localStorage.setItem("referral_code", referral_code)
                }
                await uploadUTMs(utmCode, referral_code)
            }
        } catch (e) {
            console.error(e);
        }

        try {
            let metaData = await axios.get("get/metadata/ACCOUNT_TYPE,LEAD_TYPE,CONTACT_TYPE,PERSON_TYPE,PAYMENT_ACCOUNT_TYPE,PREMISE_TYPE",
                {
                    headers: {
                        "sg-authorization": `Bearer ${tokens.sg_token.accessToken}`,
                    }
                })
            metaData = metaData.data.data.listItems;
            // console.log(metaData)
            await localStorage.setItem("metaData", JSON.stringify(metaData));
        } catch (e) {
            console.log(e)
        }
    }
}

export default getTokens;
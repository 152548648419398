import { Field, reduxForm } from "redux-form";
import InputFields, {
  FieldTitle,
  ThemedButton,
  ThemedTitle,
} from "src/Components/InputFields";
import { makeStyles } from "@mui/styles";
import "react-phone-input-2/lib/bootstrap.css";
import getInitFields from "src/utils/getInitFields";
import * as Yup from "yup";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import getScreenDetail from "src/utils/screenDetails";
import GCTooltip from "src/Components/GCTooltip";
import NoPlanEmailModal from "./NoPlanEmailModal";
import axios from "src/utils/axios";
import charlie from "src/assets/charlie-2.png";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  checkboxContainer: {
    paddingLeft: 10,
    paddingTop: 10,
  },
  checkBoxDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  checkCss: {
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  tint4: {
    position: "absolute",
    top: -36,
    width: 62,
    height: 62,
    padding: 6,
    [theme.breakpoints.up("md")]: {
      top: -58,
      padding: 12,
      width: 100,
      height: 100,
    },
    backgroundColor: "rgba(255, 233, 238, 0.5)",
    borderRadius: 100,
    animation: `$pulse 1.2s infinite ease-out`,
  },
  tint3: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFDEE1",
    borderRadius: 100,
  },
  avatar: {
    width: "100%",
    height: "100%",
    borderRadius: 100,
  },
  divider: {
    borderColor: "#F5F4F4",
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 50,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginBottom: 70,
    }
  },
  subHeadingText: {
    color: '#21212185',
    fontSize: '13px',
    [theme.breakpoints.up("md")]: {
      fontSize: '16px',
    }
  }
}));

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter correct email")
    .trim()
    .nullable()
    .required("Required"),
  phone_number: Yup.string().trim().nullable().required("Required"),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

let NoPlans = (props) => {
  const classes = useStyles();
  const { handleSubmit, submitting, invalid } = props;
  const [screenDetail, setScreenDetail] = useState(null);
  const [noPlanEmailModal, setNoPlanEmailModal] = useState(false);

  const getData = async (step) => {
    let localData = await localStorage.getItem(step);
    return JSON.parse(localData);
  };

  useEffect(() => {
    const init = async () => {
      let scrData = await getScreenDetail("no-plans");
      setScreenDetail(scrData);
    };
    init();
  }, []);

  const noPlanFormSubmit = async(values) =>{
    let tokens = await getData("tokens");
    let zip = await getData("zip-code");
    const noPlanData = {
      zip_code: zip.zip_code,
      screen_id: 1,
      email: values.email,
      uuid: tokens.uuid,
      not_serviceable: 1
    }
    try {
      let response = await axios.patch(`/customer/update`, noPlanData);
      //console.log(response.status, response.data, noPlanData);
    } catch (e) {
      //console.log("Error : ",e);
    }
    /*
    *  post to hS
    * */
    const portalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
    const noplansFormId = process.env.REACT_APP_HUBSPOT_LAUNCH_NO_PLANS_FORM_ID;
    //const HUBSPOT_API_URL = 'https://api.hsforms.com/submissions/v3/integration/submit/21077409/f75070a4-c21a-4115-8dc6-b96dcf6bcc3f';
    const HUBSPOT_API_URL = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${noplansFormId}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      let hubspottuk = null;
      document.cookie.split('; ').forEach(function (a,b){
        if (a.toString().match(/hub/)){
          hubspottuk = a.toString().split('=')[1];
        }
      },hubspottuk);
	  let promo_code = null;
      if (zip.promo_code) {
		promo_code = zip.promo_code;
      }
      const response = await axios.post(
          HUBSPOT_API_URL,
          {
            fields: [
              {
                objectTypeId: "0-1",
                name: "email",
                value: values.email,
              },
              {
                objectTypeId: "0-1",
                name: "zip",
                value: zip.zip_code,
              },
              {
                objectTypeId: "0-1",
                name: "promo_code",
                value: promo_code,
              },
              {
                objectTypeId: "0-1",
                name: "signup_prospect_uuid",
                value: tokens.uuid,
              },
              /*{
                objectTypeId: "0-1",
                name: "utm_campaign",
                value: utm_campaign,
              },
              {
                objectTypeId: "0-1",
                name: "utm_source",
                value: utm_source,
              },
              {
                objectTypeId: "0-1",
                name: "utm_medium",
                value: utm_medium,
              },
              {
                objectTypeId: "0-1",
                name: "utm_content",
                value: utm_content,
              },*/
            ],
            context: {
              hutk: hubspottuk,
              pageName: "Out of area lead capture",
            },
          },
          config
      );

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  return (
    <form id="no-plans" onSubmit={handleSubmit(noPlanFormSubmit)}>

      <NoPlanEmailModal
        open={noPlanEmailModal}
        handleClose={()=>setNoPlanEmailModal(false)}
      />
      <Box className={classes.divider} sx={{ borderTop: 2, borderColor: "#f4f4f4" }} >
        <Box className={classes.tint4}>
          <Box className={classes.tint3}>
            <img
              src={charlie}
              alt="charlie-avatar"
              className={classes.avatar}
            />
          </Box>
        </Box>
      </Box>
      <Box style={{display: 'flex', justifyContent: 'center'}}>
        <ThemedTitle>
          Uh-oh! GoodCharlie hasn’t made it to your area just yet.
        </ThemedTitle>
      </Box>
      <Typography variant="body2" style={{textAlign: 'center', marginTop: 10}}>
        <span className={classes.subHeadingText}>
          But we’ll be there very soon and will let you know when we are!
        </span>
      </Typography>
      <br />
      <div className={classes.mainDiv}>
        <div>
          <FieldTitle required>Add your email for a special offer!</FieldTitle>
          <Field
            name="email"
            component={InputFields}
            placeholder="Email"
            type="textField"
            otherType="email"
            label="hhh"
          />
        </div>
      </div>
      <Box className={classes.forContinueAndTooltop}>
        <Box>
          <ThemedButton
            disabled={invalid}
            isSubmitting={submitting}
            type="submit"
            onClick={()=>setNoPlanEmailModal(true)}
          >
            Continue
          </ThemedButton>
        </Box>
        {/* <Box>
          {screenDetail && screenDetail.tooltip && (
            <GCTooltip>{screenDetail.tooltip}</GCTooltip>
          )}
        </Box> */}
      </Box>
    </form>
  );
};

NoPlans = reduxForm({
  initialValues: getInitFields("no-plans"),
  form: "no-plans",
  destroyOnUnmount: false,
  validate,
})(NoPlans);

export default NoPlans;

const getInitFields = (formName) => {
  let initValues = {};
  let fetched;
  try {
    fetched = localStorage.getItem(formName);
    if (fetched && fetched && Object.keys(JSON.parse(fetched)).length > 0) {
      fetched = true;
    }
  } catch (e) {
    fetched = false;
  }
  if (fetched) {
    let fields = JSON.parse(localStorage.getItem(formName));

    //======================= formatting initial values =========================
    switch (formName) {
      case "contact-details":
        initValues = {
          ...fields,
          marketing_communication:
            fields.marketing_communication === 0 ? false : true,
          sms_communication: fields.sms_communication === 0 ? false : true,
        };
        break;
      case "pet-name":
        initValues = {
          ...fields,
        }
        break;
      case "pet-age":
        initValues = {
          ...fields,
          pet_weight_type: "LB"
        }
        break;
      case "partner":
        initValues = {
          ...fields,
          general_rescue: fields.general_rescue ? true : false,
        };
        break;
      case "payment-method":
        initValues = {
          auto_pay: fields.auto_pay ? true : false,
          eBill: fields.eBill ? true : false
        };
        break;
      case "payment-details":
        initValues = {
          auto_pay: fields.auto_pay ? true : false,
          deposit_waiver: fields.deposit_waiver ? true : false,
          eBill: fields.eBill ? true : false
        };
        break;
      case "review":
        initValues = {
          referral_code: localStorage.getItem("referral-code") || null
        };
        break;
      default:
        initValues = fields;
    }
  }

  // ========================== Default Values ============================
  else {
    switch (formName) {
      case "contact-details":
        initValues = {
          marketing_communication: true,
          sms_communication: true
        }
        break;
      case "pet-type":
        initValues = {
          // pet_type: "dog",
          // pet_sex: "male",
          has_pet: 1
        }
        break;
      case "pet-age":
        initValues = {
          pet_weight_type: "LB"
        }
        break;
      case "start-date":
        let startDateLocal = JSON.parse(localStorage.getItem("blackoutStartDate"))
        initValues = {
          // start_date: new Date(startDateLocal)
        };
        break;

      case "switching": {
        initValues = {
          moving_switching: "switching",
        };
        break;
      }
      case "partner":
        initValues = {
          general_rescue: true,
          // partner_id: "houston",
          match: true
        };
        break;
      case "credit-check":
        initValues = {
          // dob: new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()),
          run_credit_check: "runCredit",
        };
        break;
      case "payment-method":
        let creditCheck = localStorage.getItem("credit_check_response");
        creditCheck = JSON.parse(creditCheck)
        let methodAutoPay = true;
        if (creditCheck && creditCheck.totalDepositAmount > 0) {
          methodAutoPay = false;
        }
        initValues = {
          payment_type: "bank",
          auto_pay: methodAutoPay,
          eBill: true
        };
        break;
      case "payment-details":
        let payMethod = localStorage.getItem("payment-method");
        if (payMethod)
          try {
            payMethod = JSON.parse(payMethod);
          } catch (e) {
            payMethod = undefined;
          }
        let autoPay = false;
        if (payMethod?.auto_pay !== undefined) {
          autoPay = payMethod.auto_pay;
        }
        initValues = {
          auto_pay: autoPay,
          deposit_waiver: false,
          eBill: true
        };
        break;
      case "review":
        initValues = {
          language: "english",
          agreement: false
        }
        break;
      default:
        initValues = {};
    }
  }
  // console.log(initValues);
  return initValues;
};

export default getInitFields;

import { Field, formValueSelector, reduxForm, SubmissionError } from "redux-form";
import InputFields, {
  FieldTitle,
  ThemedButton,
} from "src/Components/InputFields";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import getInitFields from "src/utils/getInitFields";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import getScreenDetail from "src/utils/screenDetails";
import GCTooltip from "src/Components/GCTooltip";
import { connect } from "react-redux";
import axios from "src/utils/axios";
import checkPromoCode from "src/utils/checkPromoCode";
import InfoModal from "src/Components/InfoModal";
import PhoneIcon from 'src/assets/phone.svg';
import cryptoJS from "src/utils/cryptoJS";

const useStyles = makeStyles((theme) => ({
  InputFields: {
    fontSize: 16,
    height: 48,
    width: 350,
    "&::placeholder": {
      color: "#999999",
    },
  },
  navContainer: {
    display: "flex",
    width: 300,
    marginTop: 20,
    justifyContent: "space-between",
  },
  mainDiv: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submitBtn: {
    height: 60,
    width: 180,
    boxShadow: 3,
    "&.MuiButtonBase-root:hover": {
      background: theme.palette.primary.main,
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "70%",
    zIndex: 3,
  },
  btnShadow: {
    position: "absolute",
    left: "12.43%",
    right: "11.89%",
    top: "25%",
    bottom: "0%",
    background: theme.palette.primary.main,
    opacity: 1,
    filter: "blur(40px)",
    borderRadius: "29px",
    zIndex: 1,
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  promoText: {
    textDecorationLine: 'underline',
    cursor: 'pointer',
    marginTop: 20,
    marginLeft: 20,
    fontWeight: "bold !important",
    width: '100%',
    color: "#3A3A3A",
  },
  promoDiv: {
    width: "100%",
    marginTop: 20,
  },
  phoneComponent2: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    position: "absolute",
    bottom: 15,
    left: "50%",
    transform: "translate(-50%, 0)",
    [theme.breakpoints.up("md")]: {
      display: "none",
    }
  },
  phoneLogo: {
    backgroundColor: '#FFE9EE',
    height: 18,
    width: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    [theme.breakpoints.up("md")]: {
      height: 24,
      width: 24,
    }
  },
  phoneNumberText: {
    fontSize: "13px !important",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px !important",
    }
  },
}));

const zipCodeRegExp = /^[0-9]{5}|^[0-9]{0}$/;

const fieldErrors = JSON.parse(localStorage.getItem("fieldErrors"));
const fieldTitles = JSON.parse(localStorage.getItem("fieldTitles"));

const validationSchema = Yup.object().shape({
  zip_code: Yup.string()
    .matches(zipCodeRegExp, fieldErrors?.zipCode.zip_code.notValid)
    .required(fieldErrors?.required)
    .min(5, fieldErrors?.zipCode.zip_code.fiveDigits)
    .max(5, fieldErrors?.zipCode.zip_code.fiveDigits)
    .nullable(),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

let GetPlans = (props) => {
  const { handleSubmit, submitting, invalid, pristine, promo_code } = props;
  const classes = useStyles();
  const [screenDetail, setScreenDetail] = useState(null);
  const [promoCode, setPromoCode] = useState(false);
  let initModal = {
    error: false,
    message: null
  };
  const [modal, setModal] = useState(initModal)
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      let scrData = await getScreenDetail("zip-code");
      setScreenDetail(scrData);
    };
    init();
  }, []);

  const getData = async (step) => {
    let localData = await localStorage.getItem(step);
    return JSON.parse(localData);
  };

  const saveData = async (values) => {
    await localStorage.setItem("zip-code", JSON.stringify(values))
    cryptoJS.save("zip-code", values);
  }

  const getPlans = async (zipCode) => {
    let tokens = await getData("tokens");
    try {
      let response = await axios.get(
        `get/service/providers/by/zipcode?zip=${zipCode}`,
        {
          headers: {
            "sg-authorization": `Bearer ${tokens.sg_token.accessToken}`,
          },
        }
      );
      let plan = response.data?.data[0]
      if (plan) {
        localStorage.setItem("tduOfZip", JSON.stringify(plan));
        cryptoJS.save("tduOfZip", plan)
        return 1;
      }
    } catch (err) {
      console.error(err);
      return err;
    }
    localStorage.removeItem("tduOfZip");
    cryptoJS.remove("tduOfZip");
    return 0;
  };

  const submit = async (values) => {
    if (values.promo_code && promo_code.trim() !== "") {
      let promoCheck = await checkPromoCode(values.promo_code)
      if (promoCheck.value !== 1) {
        throw new SubmissionError({ promo_code: promoCheck.message })
      }
    }
    let token = await getData("tokens");
    let updateData = {
      uuid: token.uuid,
      screen_id: 1,
      zip_code: values.zip_code,
      promo_code: values.promo_code ? values.promo_code : null
    };
    try {
      let response = await axios.patch(`/customer/update`, updateData);
      await saveData(values);
      await localStorage.setItem("last_submitted", "zip-code");
      let planRes = await getPlans(values.zip_code);
      if (planRes !== undefined) {
        switch (planRes) {
          case 0:
            navigate("/no-plans");
            break;
          case 1:
            navigate("/plans");
            break;
          default:
            if (planRes !== undefined && planRes.message) {
              setModal({
                error: true,
                message: planRes.response.data.message || planRes.message
              })
            }
            break;
        }
      }
    } catch (e) {
      console.error(e);
      setModal({
        error: true,
        message: e.message
      })
    }
  }

  const PhoneNumberComponent = ({ position }) => {
    return (
      <Box className={position === "top" ? classes.phoneComponent : classes.phoneComponent2}>
        <Box className={classes.phoneLogo} >
          <img src={PhoneIcon} alt="Phone"/>
        </Box>
        <Box width={8} />
        <Typography variant="body2" className={classes.phoneNumberText} style={{ fontWeight: 600 }}>
          {JSON.parse(localStorage.getItem("contactNumber"))}
        </Typography>
      </Box>
    )
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <InfoModal open={modal.error} close={() => setModal(initModal)} message={modal.message} />
      <div className={classes.mainDiv}>
        <div style={{marginTop: 20}}>
          {/* <FieldTitle required>Your zip code</FieldTitle> */}
          <Field
            component={InputFields}
            name="zip_code"
            placeholder={fieldTitles?.zipCode.zip_code}
            type="textField"
            required
          />
        </div>

        {promoCode ? <div className={classes.promoDiv}>
          {/* <FieldTitle>Your promo code</FieldTitle> */}
          <Field
            component={InputFields}
            name="promo_code"
            placeholder={fieldTitles?.zipCode.promo_code}
            type="textField"
          // onBlur={() => promoCodeCheck()}
          />
        </div> :
          <div className={classes.promoText}>
            <Typography variant="body2" onClick={() => setPromoCode(true)} className={classes.promoText}>
              Have a Promo Code?
            </Typography>
          </div>}

      </div>
      <Box className={classes.forContinueAndTooltop}>
        <Box>
          <ThemedButton
            type="submit"
            isSubmitting={submitting}
            disabled={invalid}
          >
            Continue
          </ThemedButton>
        </Box>
        <Box>
          {screenDetail && screenDetail.tooltip && (
            <GCTooltip>{screenDetail.tooltip}</GCTooltip>
          )}
        </Box>
      </Box>
      <PhoneNumberComponent position={"bottom"} />
    </form>
  );
};

GetPlans = reduxForm({
  initialValues: getInitFields("zip-code"),
  form: "zip-code",
  destroyOnUnmount: false,
  validate,
})(GetPlans);

const selector = formValueSelector('zip-code') // <-- same as form name
GetPlans = connect(
  state => {
    // can select values individually
    const promo_code = selector(state, 'promo_code')
    return {
      promo_code,
    }
  }
)(GetPlans)

export default GetPlans;

import { Field, reduxForm } from "redux-form";
import { Box, Button, Typography } from "@mui/material";
import InputFields, {
  FieldTitle,
  ThemedButton,
  ThemedTitle,
} from "src/Components/InputFields";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import getScreenDetail from "src/utils/screenDetails";
import Theme from "src/Theme/theme";
import getInitFields from "src/utils/getInitFields";
import GCTooltip from "src/Components/GCTooltip";
import { useNavigate } from "react-router-dom";
import axios from "src/utils/axios";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import GoodQuality from 'src/assets/goodQuality.png';
import arrowRight from "src/assets/arrow-right.png";
import InfoModal from "src/Components/InfoModal";

const useStyles = makeStyles((theme) => ({
  cvcBoxes: {
    width: 338,
    [theme.breakpoints.up("md")]: {
      width: 350,
    },
  },
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: 370,
    backgroundColor: 'white',
    boxShadow: 24,
    [theme.breakpoints.up("md")]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '60%',
      height: 420,
      backgroundColor: 'white',
      boxShadow: 24,
    },
  },
  submitBtn: {
    height: 48,
    width: 120,
    boxShadow: 3,
    "&.MuiButtonBase-root:hover": {
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.up("md")]: {
      height: 60,
      width: 185,
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "50%",
    zIndex: 3,
  },
  btnShadow: {
    position: "absolute",
    left: "12.43%",
    right: "11.89%",
    top: "25%",
    bottom: "0%",
    background: theme.palette.primary.main,
    opacity: 1,
    filter: "blur(40px)",
    borderRadius: "29px",
    zIndex: 1,
  },
}));

let CardDetails = (props) => {
  const { handleSubmit, submitting, invalid } = props;
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [err, setErr] = useState(null);
  const fieldTitles = JSON.parse(localStorage.getItem("fieldTitles"));

  let errorInitVal = { error: false, message: null };
  const [subError, setSubError] = useState(errorInitVal);

  let credit_card_err_msg = localStorage.getItem("creditCardErrMsg") || "We could not process your credit card";

  const [screenDetails, setScreenDetails] = useState(null);
  const navigate = useNavigate();
  let depositAmount = JSON.parse(localStorage.getItem("credit_check_response")).totalDepositAmount
  depositAmount = depositAmount.toFixed(2);
  const [depositModal, setDepositModal] = useState(false)
  const [iframeConfig, setIframeConfig] = useState(null);
  let tokens = localStorage.getItem("tokens");
  tokens = JSON.parse(tokens);

  const checkSubmission = async () => {
    let payment_response = await localStorage.getItem("payment-response");
    if (payment_response) {
      payment_response = JSON.parse(payment_response);
      if (payment_response.token && payment_response.token.trim() !== "") {
        // console.log(payment_response)
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    const init = async () => {
      let scrInfo = await getScreenDetail("card-details", Theme);
      setScreenDetails(scrInfo);
      let basicInfo = await localStorage.getItem("basic-info");
      let credit_check = await localStorage.getItem("credit_check_response");
      basicInfo = JSON.parse(basicInfo);
      credit_check = JSON.parse(credit_check);
      setIframeConfig({
        ...basicInfo,
        // amount: credit_check.totalDepositAmount,
        appId: process.env.REACT_APP_ICHECK_APP_ID,
        appSecret: process.env.REACT_APP_ICHECK_APP_SECRET,
        uuid: tokens.uuid
      })
      // let submitted = await checkSubmission();
      // if (submitted) {
      //   setDepositModal(true);
      // }
    };
    if (!screenDetails) {
      init();
    }
    const submitPaymentDetails = async () => {
      if (window.addEventListener) {
        window.addEventListener("message", function (e) {
          let jsonData = e.data;
          if (typeof jsonData != "undefined") {
            if (
              typeof jsonData == "object" &&
              jsonData.constructor.name == "Object"
            ) {
              let source = jsonData.source;
              //Should check the source system. Vendor should return it in the response JSON.
              if (typeof source != "undefined" && source === "iCheck") {
                let declined = null
                try {
                  declined = typeof transactionResponse === "string" && jsonData.transactionResponse?.split('|')[0] === 'DECLINED';
                } catch (e) {
                  declined = false;
                }
                if (
                  (typeof jsonData.error != "undefined" && jsonData.error != null) || declined
                ) {
                  setSubError({ error: true, message: credit_card_err_msg + '.\n\n' });
                  console.error("Icheck Gateway Error:", jsonData.error || jsonData.transactionResponse);
                } else {
                  const init = async () => {

                    let tokenPaymentDetails = await localStorage.getItem("tokens");
                    tokenPaymentDetails = JSON.parse(tokenPaymentDetails);
                    const newValues = {
                      // ...values,
                      payment_token: jsonData.token,
                      payment_response: JSON.stringify(jsonData),
                    };
                    // await saveData(newValues);
                    if (!declined) {
                      try {
                        let response = await axios.patch(`/customer/update`, {
                          uuid: tokenPaymentDetails.uuid,
                          screen_id: 14,
                          ...newValues,
                        });
                        localStorage.setItem("nextPage", JSON.stringify("review"));
                        localStorage.setItem("last_submitted", "payment-details")
                        localStorage.setItem(
                          "payment-response",
                          JSON.stringify(jsonData)
                        );
                        localStorage.setItem(
                          "payment-details",
                          JSON.stringify({ payment_token: jsonData.token })
                        );
                        navigate("/review");
                      } catch (e) {
                        console.log(e);
                        setSubError({ error: true, message: credit_card_err_msg + '.\n\n' })
                      }
                    }
                    else {
                      setSubError({ error: true, message: credit_card_err_msg + '.\n\n' })
                    }

                    // console.log("form submission:", newValues);
                    // navigate("/review")

                  };
                  init();
                  setData(jsonData);
                }
                // console.log(jsonData);
              }
            }
          }
        });
      } else {
        window.attachEvent("onmessage", function (e) {
          console.log("Inside onmessage");
        });
      }
    }
    submitPaymentDetails();
  }, []);

  const submit = (values) => {
    // console.log("Submission", values);
    alert("done")
  }

  return (
    <form id="payment-details" onSubmit={handleSubmit(submit)}>
      <InfoModal open={subError.error} message={subError.message} close={() => setSubError(errorInitVal)} />

      <ThemedTitle>
        {screenDetails &&
          <div
            dangerouslySetInnerHTML={{ __html: screenDetails.description }}
          />}
      </ThemedTitle>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <div>
          {/* <FieldTitle required>Name on the Card</FieldTitle> */}
          <div style={{ marginTop: 20 }}>
            <Field
              component={InputFields}
              variant="outlined"
              type="textField"
              placeholder={fieldTitles?.cardDetails.name_on_card}
              name="cardName"
            />
          </div>
        </div>
        <br />
        {iframeConfig && (
          <>
            <Typography color="red"> {err && err}</Typography>
            {/* {console.log(iframeConfig)} */}
            <iframe
              title="Credit Card"
              id="iFrameCC"
              name="iFrameCC"
              src={`https://iframe.icheckgateway.com/iFrameCC.aspx?appId=${iframeConfig.appId}&appSecret=${iframeConfig.appSecret}&custId=${iframeConfig.uuid}&amount=0&firstName=${iframeConfig.first_name}&lastName=${iframeConfig.last_name}&css=payment{border: 0px; box-shadow: none; background: white}  input[type=submit],input[type=reset]{background: rgb(255, 91, 106); border-radius: 25px; max-width: 128px; height:38; text-transform: capitalize}`}
              frameborder="0"
              scrolling="no"
              width="320"
              height="520"
            />
          </>
        )}

      </Box>
      <Box className={classes.forContinueAndTooltop}>
        <Box>
          {screenDetails && screenDetails.tooltip && (
            <GCTooltip>{screenDetails.tooltip}</GCTooltip>
          )}
        </Box>
      </Box>
    </form>
  );
};

CardDetails = reduxForm({
  initialValues: getInitFields("card-details"),
  destroyOnUnmount: false,
  // a unique name for the form
  form: "CardDetails",
})(CardDetails);

export default CardDetails;

const getPartnerID = (lable) => {
    let partners = localStorage.getItem("partnersList");
    if (partners) {
        let partnersList = JSON.parse(localStorage.getItem("partnersList"))
        let partnerId = null;
        partnersList.map(partner => {
            if (partner.label === lable) {
                partnerId = partner.id
            }
        })
        return partnerId;
    }
    return null;
}

export function getAccountNote(key, value) {
    let partners = localStorage.getItem("partnersList");
    if (partners) {
        let partnersList = JSON.parse(localStorage.getItem("partnersList"))
        let sg_account_notes = null;
        try {
            partnersList.map(partner => {
                if (partner[key] === value) {
                    sg_account_notes = partner.sg_account_notes;
                }
            })
        } catch (e) {
            window.NREUM.noticeError(e);
        }
        if (sg_account_notes) {
            return sg_account_notes;
        }
    }
    return "gc_general_rescue";
}

export function getPartnerLabel(id) {
    let partners = localStorage.getItem("partnersList");
    if (partners) {
        let partnersList = JSON.parse(localStorage.getItem("partnersList"))
        let partnerLabel = null;
        partnersList.map(partner => {
            if (partner.id === id) {
                partnerLabel = partner.label;
            }
        })
        return partnerLabel;
    }
    return null;
}

export default getPartnerID;
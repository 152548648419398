import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import Theme from "src/Theme/theme";
import powerIcon from "src/assets/powerIcon.svg";
import favoritePink from "src/assets/favoritePink.png";
import { ThemedButton, ThemedTitle } from "src/Components/InputFields";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import theme from 'src/Theme/theme';
import { useEffect, useState } from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import axios from "src/utils/axios";
import cryptoJS from "src/utils/cryptoJS";
import { CircularProgress } from '@mui/material';

import PlanDetailsModal from "./PlanDetailsModal";

import VideoVetPink from 'src/assets/plan/goodenergy/videovetpink.svg';
import RoundupPink from 'src/assets/plan/goodenergy/rounduppink.svg';
import EmergencyFundPink from 'src/assets/plan/goodenergy/emergencyfundpink.svg';
import TickIconPink from 'src/assets/plan/goodenergy/tickpink.svg';

import VideoVetBlue from 'src/assets/plan/goodgreen/videovetblue.svg';
import RoundupBlue from 'src/assets/plan/goodgreen/roudupblue.svg';
import EmergencyFundBlue from 'src/assets/plan/goodgreen/emergencyfundblue.svg';
import TickIconBlue from 'src/assets/plan/goodgreen/tickblue.svg';
import InfoModal from "./InfoModal";




const useStyles = makeStyles((theme) => ({
    container: {
        width: "243px",
        height: "391px",
        marginTop: 20,
        marginBottom: 10,
        [theme.breakpoints.up("sm")]: {
            marginTop: 5,
            width: "283px",
            height: 'auto'
        },
        borderRadius: "24px",
        paddingBottom: '19px',
        border: "1px solid #E5E5E5",
        // "&:hover":{
        //     borderColor: Theme.palette.secondary.main
        // },
    },
    containerSelected: {
        width: "243px",
        height: "391px",
        marginTop: 20,
        marginBottom: 10,
        [theme.breakpoints.up("sm")]: {
            marginTop: 5,
            width: "283px",
            height: 'auto'
        },
        borderRadius: "24px",
        paddingBottom: '19px',
        border: "1px solid #E5E5E5",
    },
    containerInner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "24px",
        border: "1px solid #E5E5E5",
        padding: "13px",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        paddingBottom: 10,
    },
    titleBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 43,
    },
    heartContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        [theme.breakpoints.up("sm")]: {
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 20,
        },
        heartContainer: {
            display: "flex",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 10,
            marginLeft: 10,
            [theme.breakpoints.up("sm")]: {
                marginTop: 20,
                marginBottom: 20,
                marginLeft: 20,
            },
        },
    },
    docs: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    banner: {
        position: "relative",
        width: 110,
        marginTop: 50,
        marginLeft: -40,
        backgroundColor: "yellow",
        transform: "rotateZ(-45deg)",
    },
    bannerBG: {
        width: 100,
        position: "absolute",
        borderLeft: "25px solid transparent",
        borderRight: "25px solid transparent",
        zIndex: 1,
    },
    bannerText: {
        color: "#fff",
        width: "100%",
        padding: "2px 25px",
        position: "absolute",
        fontWeight: 600,
        zIndex: 5,
        textAlign: "center"
    },
    headingText: {
        fontSize: '16px',
        fontWeight: 700,
        [theme.breakpoints.up("sm")]: {
            fontSize: '18px',
        },
    },
    priceText: {
        fontSize: '20px',
        fontWeight: 700,
        color: '#4D4D4D',
        [theme.breakpoints.up("sm")]: {
            fontSize: '24px',
        },
    },
    basedOnText: {
        fontSize: '10px',
        fontWeight: 600,
        color: '#21212185',
        [theme.breakpoints.up("sm")]: {
            fontSize: '12px',
        },
    },
    tickText: {
        fontSize: '11px',
        fontWeight: 400,
        [theme.breakpoints.up("sm")]: {
            fontSize: '13px',
        },
    },
    tickIcon: {
        height: '12.5px',
        width: '12.5px',
        [theme.breakpoints.up("sm")]: {
            height: '15px',
            width: '15px',
        },
    },
    benefitsHeading: {
        fontSize: '12px',
        [theme.breakpoints.up("sm")]: {
            fontSize: '14px',
        },
    },
    benefitsIcons: {
        height: '25px',
        width: '15px',
        [theme.breakpoints.up("sm")]: {
            height: '30px',
            width: '20px',
        },
    },
    packBenefitsSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingLeft: 25,
        marginTop: 10,
        height: '80px',
        [theme.breakpoints.up("sm")]: {
            marginTop: 12,
            height: '96px',
        },
    },
    submitButtonSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        [theme.breakpoints.up("sm")]: {
            marginTop: '30px',
        },
    },
    listBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingLeft: 25,
        marginTop: 20,
        height: '62px',
        [theme.breakpoints.up("sm")]: {
            marginTop: 24,
            height: '70px',
        },
    },
    benefitsHeadingSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingLeft: 25,
        marginTop: 20,
        [theme.breakpoints.up("sm")]: {
            marginTop: 23,
        },
    }
}));

const PlanCard = ({ plan, plan_switch, faqData, packBenefits, packImpacts, planCompleteData, docDetails }) => {

    const classes = useStyles();
    const navigate = useNavigate();
    let errorInitVal = { error: false, message: null };
    const [subError, setSubError] = useState(errorInitVal);
    const [openPlanDetailsModal, setOpenPlanDetailsModal] = useState(false);
    const [selectButtonLoading, setSelectButtonLoading] = useState(false);
    // let currentPlanId = null;
    const [currentPlanId, setCurrentPlanId] = useState(null);

    // console.log(currentPlanId)

    const saveToLocal = () => {
        // localStorage.setItem("selectedPlan", JSON.stringify(plan));
        let planObj = {
            plan_id: String(plan.productID),
            plan_name: plan.name,
            plan_amount: plan.kwh1000Price
        }
        localStorage.setItem("plan", JSON.stringify(planObj))
        cryptoJS.save("plan", planObj)
    }

    const getData = async (item) => {
        let localData = await localStorage.getItem(item);
        return JSON.parse(localData);
    };

    const selectPlanBtnClick = async () => {
        setSelectButtonLoading(true);
        await localStorage.setItem("last_submitted", "plans");
        try {
            let forUuid = await getData("tokens");
            // let forPlan = await getData("plan");
            let data = {
                uuid: forUuid.uuid,
                plan_id: String(plan.productID),
                plan_name: plan.name,
                plan_amount: plan.kwh1000Price
            }
            let response = await axios.patch(`/customer/update`, data);
            await saveToLocal();
            setSelectButtonLoading(false)
            navigate(`/basic-info`);
        } catch (e) {
            console.error(e);
            setSubError({ error: true, message: e.message });
            setSelectButtonLoading(false);
        }
    }

    useEffect(() => {
        const forDisablingBtn = async () => {
            let res = localStorage.getItem("plan")
            let res1 = await JSON.parse(res)
            if (res1 && res1.length !== 0) {
                setCurrentPlanId(res1.plan_id)
            }
        }
        forDisablingBtn();
    }, [])

    const Banner = ({ isFeatured, isRecommended }) => {
        let bannerTag = null;
        if (isFeatured) {
            bannerTag = "Charlie's Choice"
        } else if (isRecommended) {
            bannerTag = "Most Popular"
        }

        if (bannerTag) {
            return (
                <Box
                    style={{ position: 'absolute', top: 0, left: 0 }}
                >
                    <Box className={classes.banner}>
                        <Typography variant="caption" className={classes.bannerText} >{bannerTag}</Typography>
                        <Box className={classes.bannerBG} sx={{ borderBottom: `25px solid ${plan_switch ? Theme.palette.secondary.main : Theme.palette.primary.main}` }} />
                    </Box>
                </Box>
            )
        } else {
            return null;
        }
    }

    return (
        // <Box style={plan.contractPeriodNumValue && plan.contractPeriodNumValue !== 36 ? { marginRight: '24px' } : { marginRight: '0px', }}>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <PlanDetailsModal
                open={openPlanDetailsModal}
                handleClose={() => setOpenPlanDetailsModal(false)}
                plan={plan}
                planCompleteData={planCompleteData}
                plan_switch={plan_switch}
                faqData={faqData}
                packBenefits={packBenefits}
                packImpacts={packImpacts}
                selectPlanBtnClick={selectPlanBtnClick}
                currentPlanId={currentPlanId}
                selectButtonLoading={selectButtonLoading}
                docDetails={docDetails}
            />

            <InfoModal open={subError.error} message={subError.message} close={() => setSubError(errorInitVal)} />

            {plan_switch && (plan.greenEnergyPercent === 1) ?

                <Card
                    className={currentPlanId === String(plan.productID) ? classes.containerSelected : classes.container}
                    sx={{
                        borderRadius: "24px",
                        "&:hover": {
                            borderColor: Theme.palette.secondary.main
                        }
                    }}
                    style={{
                        borderWidth: 2,
                        borderColor: currentPlanId === String(plan.productID) ? Theme.palette.secondary.main : ""
                    }}
                >
                    <Box className={classes.titleBox} style={{ backgroundColor: 'rgba(206,245,241,0.3)' }}>
                        <Typography variant="body2">
                            <span className={classes.headingText} style={{ color: plan_switch ? Theme.palette.secondary.main : Theme.palette.primary.main }}>
                                {plan.name}
                            </span>
                        </Typography>
                    </Box>

                    <Box sx={{ position: 'relative', paddingTop: '5px' }}>

                        <Banner isFeatured={plan.isFeatured} isRecommended={plan.isRecommended} />

                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="body2">
                                <span className={classes.priceText}>
                                    {plan.kwh2000Price.toLocaleString(navigator.language, { minimumFractionDigits: 1 })}¢
                                </span>
                            </Typography>
                        </Box>

                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="body2">
                                <span className={classes.basedOnText}>
                                    Based on 2,000 kWh
                                </span>
                            </Typography>
                        </Box>

                        <Box className={classes.listBox}>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={TickIconBlue} alt="feature" className={classes.tickIcon} />
                                <Box width="10px" />
                                {plan.productDisplayShortDescription && <Typography variant="body2">
                                    <span className={classes.tickText}>
                                        {plan.productDisplayShortDescription.replace("-", " ").replace(",", "")}
                                    </span>
                                </Typography>}
                            </Box>

                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={TickIconBlue} alt="feature" className={classes.tickIcon} />
                                <Box width="10px" />
                                <Typography variant="body2" >
                                    <span className={classes.tickText}>
                                        Earth friendly e-billing
                                    </span>
                                </Typography>
                            </Box>

                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={TickIconBlue} alt="feature" className={classes.tickIcon} />
                                <Box width="10px" />
                                <Typography variant="body2">
                                    <span className={classes.tickText}>
                                        {plan.greenEnergyPercent * 100}%&nbsp; renewable energy
                                    </span>
                                </Typography>
                            </Box>
                        </Box>

                        <Box className={classes.benefitsHeadingSection}>
                            <Box>
                                <Typography variant="body2" className={classes.benefitsHeading} style={{ fontWeight: 700 }}>
                                    {plan.productDisplayFeature1 ? plan.productDisplayFeature1 : "Pack Benefits"}
                                </Typography>
                            </Box>
                        </Box>

                        <Box className={classes.packBenefitsSection}>
                            {(plan.productDisplayFeature2) ?
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={VideoVetBlue} alt="time period" className={classes.benefitsIcons} />
                                    <Box width="10px" />
                                    <Typography variant="body2" >
                                        <span className={classes.tickText}>
                                            {plan.productDisplayFeature2}
                                        </span>
                                        {/* 24/7 Video Vet */}
                                    </Typography>
                                </Box>
                                : null
                            }
                            {(plan.productDisplayFeature3) ?
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={EmergencyFundBlue} alt="renewable energy" className={classes.benefitsIcons} />
                                    <Box width="10px" />
                                    <Typography variant="body2" >
                                        <span className={classes.tickText}>
                                            {plan.productDisplayFeature3}
                                        </span>
                                    </Typography>
                                </Box>
                                : null
                            }
                            {(plan.productDisplayFeature4) ?
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={RoundupBlue} alt="fixed rate" className={classes.benefitsIcons} />
                                    <Box width="10px" />
                                    <Typography variant="body2" >
                                        <span className={classes.tickText}>
                                            {plan.productDisplayFeature4}
                                        </span>
                                    </Typography>
                                </Box>
                                : null
                            }
                        </Box>

                        <Box className={classes.submitButtonSection}>
                            <Button
                                onClick={selectPlanBtnClick}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    height: 33,
                                    width: 143,
                                    boxShadow: 3,
                                    color: 'white',
                                    borderRadius: '32px',
                                    background: theme.palette.primary.main,
                                    "&.MuiButtonBase-root:hover": {
                                        background: theme.palette.primary.main,
                                    },
                                    [theme.breakpoints.up("md")]: {
                                        height: 40,
                                        width: 171
                                    },
                                }}>
                                {!selectButtonLoading ? (
                                    <>
                                        <Box className={classes.btnContainer}>
                                            <Typography sx={{
                                                fontSize: 13,
                                                fontWeight: 600,
                                                color: "white",
                                                [theme.breakpoints.up("sm")]: {
                                                    fontSize: 16,
                                                },
                                            }}>
                                                {currentPlanId === String(plan.productID) ? "Selected" : "Select Plan"}
                                            </Typography>
                                        </Box>
                                    </>
                                )

                                    :

                                    (
                                        <>
                                            <Box
                                                className={classes.btnContainer}
                                                sx={{ display: 'flex', color: "#fff", width: "70%", alignItems: "center" }}
                                            >
                                                <CircularProgress
                                                    size={18}
                                                    thickness={6}
                                                    sx={{
                                                        m: 0,
                                                        mr: 0.5,
                                                        p: 0,
                                                    }}
                                                    color="inherit"
                                                />
                                                <Typography sx={{
                                                    fontSize: 13,
                                                    fontWeight: 600,
                                                    color: "white",
                                                    [theme.breakpoints.up("sm")]: {
                                                        fontSize: 16,
                                                    },
                                                }}>
                                                    {selectButtonLoading ? "Submitting" : (currentPlanId === String(plan.productID) ? "Selected" : "Select Plan")}</Typography>
                                            </Box>
                                        </>
                                    )}
                            </Button>
                            <Typography variant="body2"
                                style={{
                                    marginTop: 10,
                                    color: "#21212185",
                                    textDecorationLine: 'underline',
                                    cursor: "pointer"
                                }}>
                                <span onClick={() => { setOpenPlanDetailsModal(true) }}>
                                    Learn more
                                </span>
                            </Typography>
                        </Box>
                    </Box>


                </Card>

                : (
                    !plan_switch && (plan.greenEnergyPercent === 0) ?

                        <Card
                            className={currentPlanId === String(plan.productID) ? classes.containerSelected : classes.container}
                            sx={{
                                borderRadius: "24px",
                                "&:hover": {
                                    borderColor: Theme.palette.primary.main
                                }
                            }}
                            style={{
                                borderWidth: 2,
                                borderColor: (currentPlanId === String(plan.productID)) ? Theme.palette.primary.main : ""
                            }}
                        >
                            <Box className={classes.titleBox} style={{ backgroundColor: 'rgba(255,222,225,0.3)' }}>
                                <span className={classes.headingText} style={{ color: plan_switch ? Theme.palette.secondary.main : Theme.palette.primary.main }}>
                                    {plan.name}
                                </span>
                            </Box>

                            <Box sx={{ position: 'relative', paddingTop: '5px' }}>

                                <Banner isFeatured={plan.isFeatured} isRecommended={plan.isRecommended} />

                                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant="body2">
                                        <span className={classes.priceText}>
                                            {plan.kwh2000Price.toLocaleString(navigator.language, { minimumFractionDigits: 1 })}¢
                                        </span>
                                    </Typography>
                                </Box>

                                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant="body2">
                                        <span className={classes.basedOnText}>
                                            Based on 2,000 kWh
                                        </span>
                                    </Typography>
                                </Box>

                                <Box className={classes.listBox}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={TickIconPink} alt="feature" className={classes.tickIcon} />
                                        <Box width="10px" />
                                        {plan.productDisplayShortDescription && <Typography variant="body2">
                                            <span className={classes.tickText}>
                                                {plan.productDisplayShortDescription.replace(" - ", " ").replace(",", "")}
                                            </span>
                                        </Typography>}
                                    </Box>

                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={TickIconPink} alt="feature" className={classes.tickIcon} />
                                        <Box width="10px" />
                                        <Typography variant="body2" >
                                            <span className={classes.tickText}>
                                                Earth friendly e-billing
                                            </span>
                                        </Typography>
                                    </Box>

                                    <Box className={classes.tickIcon} />
                                </Box>

                                <Box className={classes.benefitsHeadingSection}>
                                    <Box>
                                        <Typography variant="body2" className={classes.benefitsHeading} style={{ fontWeight: 700 }}>
                                            {plan.productDisplayFeature1 ? plan.productDisplayFeature1 : "Pack Benefits"}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box className={classes.packBenefitsSection}>
                                    {(plan.contractPeriodNumValue && plan.contractPeriodName && plan.productDisplayFeature2) ?
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={VideoVetPink} alt="time period" className={classes.benefitsIcons} />
                                            <Box width="10px" />
                                            <Typography variant="body2" >
                                                {/* 24/7 Video Vet */}
                                                <span className={classes.tickText}>
                                                    {plan.productDisplayFeature2}
                                                </span>
                                            </Typography>
                                        </Box>
                                        : null
                                    }
                                    {(plan.productDisplayFeature3) ?
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={EmergencyFundPink} alt="renewable energy" className={classes.benefitsIcons} />
                                            <Box width="10px" />
                                            <Typography variant="body2" >
                                                <span className={classes.tickText}>
                                                    {plan.productDisplayFeature3}
                                                </span>
                                            </Typography>
                                        </Box>
                                        : null
                                    }
                                    {(plan.productDisplayFeature4) ?
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={RoundupPink} alt="fixed rate" className={classes.benefitsIcons} />
                                            <Box width="10px" />
                                            <Typography variant="body2">
                                                <span className={classes.tickText}>
                                                    {plan.productDisplayFeature4}
                                                </span>
                                            </Typography>
                                        </Box>
                                        : null
                                    }
                                </Box>

                                <Box className={classes.submitButtonSection}>
                                    <Button
                                        onClick={selectPlanBtnClick}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            height: 33,
                                            width: 143,
                                            boxShadow: 3,
                                            color: 'white',
                                            borderRadius: '32px',
                                            background: theme.palette.primary.main,
                                            "&.MuiButtonBase-root:hover": {
                                                background: theme.palette.primary.main,
                                            },
                                            [theme.breakpoints.up("md")]: {
                                                height: 40,
                                                width: 171
                                            },
                                        }}>
                                        {!selectButtonLoading ? (
                                            <>
                                                <Box className={classes.btnContainer}>
                                                    <Typography sx={{
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        color: "white",
                                                        [theme.breakpoints.up("sm")]: {
                                                            fontSize: 16,
                                                        },
                                                    }}>
                                                        {currentPlanId === String(plan.productID) ? "Selected" : "Select Plan"}
                                                    </Typography>
                                                </Box>
                                            </>
                                        )

                                            :

                                            (
                                                <>
                                                    <Box
                                                        className={classes.btnContainer}
                                                        sx={{ display: 'flex', color: "#fff", width: "70%", alignItems: "center" }}
                                                    >
                                                        <CircularProgress
                                                            size={18}
                                                            thickness={6}
                                                            sx={{
                                                                m: 0,
                                                                mr: 0.5,
                                                                p: 0,
                                                            }}
                                                            color="inherit"
                                                        />
                                                        <Typography sx={{
                                                            fontSize: 13,
                                                            fontWeight: 600,
                                                            color: "white",
                                                            [theme.breakpoints.up("sm")]: {
                                                                fontSize: 16,
                                                            },
                                                        }}>
                                                            {selectButtonLoading ? "Submitting" : (currentPlanId === String(plan.productID) ? "Selected" : "Select Plan")}
                                                        </Typography>
                                                    </Box>
                                                </>
                                            )}
                                        {/* <Typography variant="body2">
                                            {currentPlanId === String(plan.productID) ? "Selected" : "Select Plan"}
                                        </Typography> */}
                                    </Button>
                                    <Typography variant="body2"
                                        style={{
                                            marginTop: 10,
                                            color: "#21212185",
                                            textDecorationLine: 'underline',
                                            cursor: "pointer"
                                        }}>
                                        <span onClick={() => { setOpenPlanDetailsModal(true) }}>
                                            Learn more
                                        </span>
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>

                        : null
                )
            }

        </Box>
    )

}

export default PlanCard;

import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

//icons
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import GoodQuality from 'src/assets/goodQuality.png';


import {makeStyles} from '@mui/styles';
import { ThemedTitle } from './InputFields';
import Theme from 'src/Theme/theme';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme)=>({
    mainBox:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '30%',
        backgroundColor: 'white',
        boxShadow: 24,
        [theme.breakpoints.up("md")]: {
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            height: '50%',
            backgroundColor: 'white',
            boxShadow: 24,
        },
    },
    thumbsUpLogo:{
        marginTop: '-20px',
        height: 60,
        width: 60,
        [theme.breakpoints.up("md")]: {
            marginTop: '0px',
            height: 90,
            width: 90,
        },
    },
    crossIcon:{
        margin: '20px 20px 0px 0px',
        color: '#21212185',
        [theme.breakpoints.up("md")]: {
            margin: '35px 35px 0px 0px',
            color: '#21212185',
        },
    },
    backHomeBox:{
        margin: '10px 42px',
        [theme.breakpoints.up("md")]: {
            margin: '0px'
        },
    }
}))

const NoPlanEmailModal = ({
  open,
  handleClose,
}) => {

    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box className={classes.mainBox} style={{borderRadius: '12px'}}>
                    <Box style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                        <Box onClick={handleClose} className={classes.crossIcon}>
                            <CancelOutlinedIcon fontSize='large'/>
                        </Box>
                    </Box>
                
                    <Box>
                        <img src={GoodQuality} className={classes.thumbsUpLogo} />
                    </Box>

                    <ThemedTitle>
                        Sent successfully!
                    </ThemedTitle>
                    <Box className={classes.backHomeBox}>
                        <Typography variant="body2" style={{textAlign: 'center'}}>
                            We received your email for a special offer!,&nbsp;
                            <span style={{color: Theme.palette.secondary.main, cursor: 'pointer'}} onClick={()=>navigate("/")}>
                                back to Home
                            </span>
                        </Typography>
                    </Box>
                </Box>
            </Fade>
        </Modal>
      );
};

export default NoPlanEmailModal;

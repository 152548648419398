import { Field, reduxForm } from "redux-form";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Box, Button, Popover, Typography } from "@mui/material";
import InputFields, {
    FieldTitle,
    ThemedButton,
    ThemedTitle,
} from "src/Components/InputFields";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import getScreenDetail from "src/utils/screenDetails";
import Theme from "src/Theme/theme";
import getInitFields from "src/utils/getInitFields";
import GCTooltip from "src/Components/GCTooltip";
import { useNavigate } from "react-router-dom";
import axios from "src/utils/axios";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import GoodQuality from 'src/assets/goodQuality.png';
import arrowRight from "src/assets/arrow-right.png";
import InfoIcon from '@mui/icons-material/Info';
import InfoModal from "src/Components/InfoModal";
import DepositIFrame from "src/Components/DepositIFrame";
import { autoPayOpt, depositWaiverOpt } from "src/utils/getPaymentOptions";

const useStyles = makeStyles((theme) => ({
    cvcBoxes: {
        width: 338,
        [theme.breakpoints.up("md")]: {
            width: 350,
        },
    },
    mainBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: 370,
        backgroundColor: 'white',
        boxShadow: 24,
        [theme.breakpoints.up("md")]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            height: 420,
            backgroundColor: 'white',
            boxShadow: 24,
        },
    },
    paymentOpt: {
        display: "flex",
        marginTop: 12,
        justifyContent: "space-between",
        justifySelf: "flex-start",
        // backgroundColor: 'yellow',
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: 370
        }
    },
    submitBtn: {
        height: 48,
        width: 120,
        boxShadow: 3,
        "&.MuiButtonBase-root:hover": {
            background: theme.palette.primary.main,
        },
        [theme.breakpoints.up("md")]: {
            height: 60,
            width: 185,
        },
    },
    btnContainer: {
        display: "flex",
        justifyContent: "space-around",
        width: "50%",
        zIndex: 3,
    },
    btnShadow: {
        position: "absolute",
        left: "12.43%",
        right: "11.89%",
        top: "25%",
        bottom: "0%",
        background: theme.palette.primary.main,
        opacity: 1,
        filter: "blur(40px)",
        borderRadius: "29px",
        zIndex: 1,
    },
    allCyan: {
        color: Theme.palette.secondary.main
    },
    dhpColor: {
        color: '#3A3A3A'
    },
    infoiconColor: {
        color: '#21212185'
    }
}));


let autoPayOption = autoPayOpt();
let depositWaiverOption = depositWaiverOpt();

let CreditCheckFailed = (props) => {
    const { handleSubmit, submitting, invalid, deposit_waiver, auto_pay } = props;
    const classes = useStyles();
    const [err, setErr] = useState(null);
    const fieldTitles = JSON.parse(localStorage.getItem("fieldTitles"));

    const [anchor, setAnchor] = useState(null);
    let errorInitVal = { error: false, message: null };
    const [subError, setSubError] = useState(errorInitVal);
    let credit_card_err_msg = localStorage.getItem("creditCardErrMsg") || "We could not process your credit card";

    const [screenDetails, setScreenDetails] = useState(null);
    const navigate = useNavigate();
    let depositAmount = JSON.parse(localStorage.getItem("credit_check_response")).totalDepositAmount
    depositAmount = depositAmount.toFixed(2);
    const [depositModal, setDepositModal] = useState(false)
    const [iframeConfig, setIframeConfig] = useState(null);
    let tokens = localStorage.getItem("tokens");
    tokens = JSON.parse(tokens);

    const handlePopoverOpen = (event) => {
        setAnchor(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchor(null);
    };

    const open = Boolean(anchor);

    const checkSubmission = () => {
        let paymentDetails = localStorage.getItem("payment-details");
        let payment_response = localStorage.getItem("payment-response");
        if (paymentDetails && payment_response) {
            payment_response = JSON.parse(payment_response);
            paymentDetails = JSON.parse(paymentDetails)
            if (payment_response.token && Number(payment_response.amount)) {
                return true;
            }
        }
        return false;
    }

    const update = async (newValues) => {
        let tokenPaymentDetails = await localStorage.getItem("tokens");
        tokenPaymentDetails = JSON.parse(tokenPaymentDetails);
        try {
            await axios.patch(`/customer/update`, {
                uuid: tokenPaymentDetails.uuid,
                screen_id: 14,
                ...newValues,
            });
            return { status: true };
        } catch (e) {
            console.error(e);
            return { status: false, error: e };
        }
    }

    let basicInfo = localStorage.getItem("basic-info");
    let credit_check = localStorage.getItem("credit_check_response");
    basicInfo = JSON.parse(basicInfo);
    credit_check = JSON.parse(credit_check);

    useEffect(() => {
        const init = async () => {
            let scrInfo = await getScreenDetail("card-details", Theme);
            setScreenDetails(scrInfo);
            let submitted = checkSubmission();
            if (submitted) {
                setDepositModal(true);
            }
        };
        init();
    }, []);

    useEffect(() => {
        let url = null;
        let cssParams = "&css=payment{border: 0px; box-shadow: none; background: white}  input[type=submit],input[type=reset]{background: rgb(255, 91, 106); border-radius: 25px; max-width: 128px; height:38; text-transform: capitalize}"
        if (deposit_waiver !== undefined) {
            let config = {
                ...basicInfo,
                amount: deposit_waiver ? 0 : credit_check.totalDepositAmount,
                appId: process.env.REACT_APP_ICHECK_APP_ID,
                appSecret: process.env.REACT_APP_ICHECK_APP_SECRET,
                uuid: tokens.uuid,
            }
            url = `https://iframe.icheckgateway.com/iFrameCC.aspx?appId=${config.appId}&appSecret=${config.appSecret}&custId=${config.uuid}&firstName=${config.first_name}&lastName=${config.last_name}`
            url = `${url}&amount=${config.amount}`
            url = `${url}${cssParams}`
            config.url = url;
            setIframeConfig(config);
        }
    }, [deposit_waiver])


    let method = localStorage.getItem("payment-method");
    if (method) {
        try {
            method = JSON.parse(method);
        } catch (e) {
            method = null;
        }
    }

    useEffect(() => {
        if (method) {
            localStorage.setItem("payment-method", JSON.stringify({
                ...method,
                auto_pay: auto_pay
            }))
        }
    }, [auto_pay])

    const IframeSubmission = async ({ status, res, message }) => {
        if (!status) {
            setSubError({ error: true, message: message })
        } else if (res && typeof (res.token) !== 'undefined' && typeof (res.transactionResponse) !== 'undefined') {
            let tokenPaymentDetails = await localStorage.getItem("tokens");
            tokenPaymentDetails = JSON.parse(tokenPaymentDetails);
            // await saveData(newValues);
            let declined;
            try {
                declined = typeof res.transactionResponse === 'string' && res.transactionResponse.split('|')[0] === 'DECLINED'
            } catch (e) {
                declined = false
            }
            if (!declined) {
                const newValues = {
                    payment_token: res.token,
                    payment_response: JSON.stringify(res),
                    depositWaived: deposit_waiver ? 1 : 0,
                    auto_pay: auto_pay ? 1 : 0
                };
                let response = await update(newValues)
                localStorage.setItem("payment-details", JSON.stringify({
                    payment_token: res.token,
                    deposit_waiver: deposit_waiver,
                    auto_pay: auto_pay
                }));
                localStorage.setItem("payment-method", JSON.stringify({
                    payment_type: "card",
                    auto_pay: auto_pay,
                    eBill: true
                }))
                if (!response.status) {
                    setSubError({ error: true, message: response.error.message });
                    return null;
                }
                localStorage.setItem("last_submitted", "payment-details");
                if (Number(res.amount)) {
                    setDepositModal(true);
                } else {
                    localStorage.setItem("nextPage", JSON.stringify("review"));
                    navigate("/review");
                }
                localStorage.setItem(
                    "payment-response",
                    JSON.stringify(res)
                );
            }
            else {
                setSubError({ error: true, message: credit_card_err_msg })
            }
        }
        else {
            setSubError({ error: true, message: credit_card_err_msg })
        }
    }

    const submit = async () => {

        let tokenPaymentDetails = await localStorage.getItem("tokens");
        tokenPaymentDetails = JSON.parse(tokenPaymentDetails);

        const newValues = {
            depositWaived: deposit_waiver ? 1 : 0,
            auto_pay: auto_pay ? 1 : 0,
            payment_response: null,
            payment_token: null
        };

        let res = await update(newValues);
        if (!res.status) {
            setSubError({ error: true, message: res.error.message || res.error })
            return null;
        }
        localStorage.setItem("last_submitted", "payment-details");
        localStorage.setItem("payment-details", JSON.stringify({
            deposit_waiver: deposit_waiver,
            auto_pay: auto_pay,
            payment_token: null
        }));
        localStorage.setItem("payment-method", JSON.stringify({
            payment_type: "none",
            auto_pay: auto_pay,
            eBill: true
        }))
        localStorage.removeItem("payment-response")
        localStorage.setItem("nextPage", JSON.stringify("review"));
        navigate("/review");
    }

    return (
        <form id="payment-details" onSubmit={handleSubmit(submit)}>
            <InfoModal open={subError.error} message={subError.message} close={() => setSubError(errorInitVal)} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                // onBackdropClick={() => checkSubmission ? setDepositModal(false) : null}
                open={depositModal}
            >
                <Fade in={depositModal}>
                    <Box className={classes.mainBox} style={{ borderRadius: '12px' }}>

                        <Box style={{ margin: '50px 0px 0px 0px', color: '#21212185' }}>
                            <img src={GoodQuality} alt="GoodQuality" style={{ width: 90, height: 90 }} />
                        </Box>

                        <ThemedTitle>
                            Thank you! <br /> Your deposit of ${depositAmount} was successful.
                        </ThemedTitle>
                        <Box style={{ marginTop: 30 }}>
                            <Button
                                variant="contained"
                                className={classes.submitBtn}
                                sx={{
                                    borderRadius: 32,
                                }}
                                onClick={() => {
                                    setDepositModal(false);
                                    localStorage.setItem("nextPage", JSON.stringify("review"));
                                    localStorage.setItem("last_submitted", "payment-details")
                                    navigate("/review")
                                }}
                            >
                                <Box className={classes.btnContainer}>
                                    <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                                        Continue
                                    </Typography>
                                    <img
                                        src={arrowRight}
                                        alt="arrow-left"
                                        style={{ objectFit: "contain", marginLeft: 5 }}
                                    />
                                </Box>
                                <Box className={classes.btnShadow} />
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>

            <ThemedTitle>
                {screenDetails &&
                    <div
                        dangerouslySetInnerHTML={{ __html: screenDetails.description }}
                    />}
            </ThemedTitle>
            <Box
                sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
                <Box className={classes.paymentOpt}>
                    <Box style={{ display: autoPayOption ? "block" : "none" }} >
                        <Field
                            name="auto_pay"
                            type="switch"
                            component={InputFields}
                            additional={() => null}
                            placeholder="Autopay"
                        />
                        <Box sx={{ width: 40 }} />
                    </Box>
                    <Box style={{ display: depositWaiverOption ? "flex" : "none", alignItems: "center" }} >
                        <Field
                            name="deposit_waiver"
                            type="switch"
                            component={InputFields}
                            placeholder="Deposit Waiver"
                            additional={() => null}
                        />
                        <InfoIcon
                            style={{ fontSize: 'large', marginLeft: 5, cursor: 'pointer' }}
                            className={Boolean(anchor) ? classes.allCyan : classes.infoiconColor}
                            onClick={handlePopoverOpen}
                        />
                        <Popover
                            open={open}
                            anchorEl={anchor}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            style={{
                                marginLeft: 16,
                                borderRadius: '8px',
                            }}
                        >
                            <Box style={{
                                maxWidth: '246px',
                                padding: '8px 9px 8px 16px',
                                backgroundColor: '#CEF5F1',

                            }}
                            >
                                <Typography
                                    variant="body2"
                                    color={Theme.palette.secondary.main}
                                    fontWeight="600"
                                >
                                    {localStorage.getItem("depositWaiverInfo")}
                                </Typography>
                            </Box>
                        </Popover>
                    </Box>
                </Box>
                {!(!auto_pay && deposit_waiver) && <div>
                    {/* <FieldTitle required>Name on the Card</FieldTitle> */}
                    <div style={{ marginTop: 20 }}>
                        <Field
                            component={InputFields}
                            variant="outlined"
                            type="textField"
                            placeholder={fieldTitles?.cardDetails.name_on_card}
                            name="cardName"
                        />
                    </div>
                </div>}
                {!(!auto_pay && deposit_waiver) &&
                    <DepositIFrame
                        iframeConfig={iframeConfig}
                        setSubRes={IframeSubmission}
                        deposit_waiver={deposit_waiver}
                        err={err}
                        auto_pay={auto_pay}
                    />
                }

            </Box>
            <Box className={classes.forContinueAndTooltop}>
                {(!auto_pay && deposit_waiver) && <Box>
                    <ThemedButton
                        isSubmitting={submitting}
                        type="submit"
                    >
                        Continue
                    </ThemedButton>
                </Box>}
                <Box>
                    {screenDetails && screenDetails.tooltip && (
                        <GCTooltip>{screenDetails.tooltip}</GCTooltip>
                    )}
                </Box>
            </Box>
        </form>
    );
};

CreditCheckFailed = reduxForm({
    initialValues: getInitFields("payment-details"),
    destroyOnUnmount: false,
    form: "CreditCheckFailed",
}
)(CreditCheckFailed);

const selector = formValueSelector('CreditCheckFailed') // <-- same as form name
CreditCheckFailed = connect(
    state => {
        const auto_pay = selector(state, 'auto_pay');
        const deposit_waiver = selector(state, "deposit_waiver");
        const cardName = selector(state, "cardName");
        return {
            auto_pay,
            deposit_waiver,
            cardName
        }
    }
)(CreditCheckFailed)

export default CreditCheckFailed

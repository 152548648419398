import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { ThemedButton } from "./InputFields";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
    maxWidth: 767,
    width: "100%",
    lineHeight: 1.4,
    padding: " 0px 15px",
  },
  notfound404: {
    position: "relative",
  },
  heading: {
    fontWeight: "900 !important",
    margin: 0,
    textTransform: "uppercase",
  },
}));

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <div className={classes.notfound404}>
        <Typography variant="h1" className={classes.heading}>
          404
        </Typography>
      </div>
      <Typography variant="h3">Oops! This Page Could Not Be Found</Typography>
      <Typography variant="body1">
        Sorry but the page you are looking for does not exist, have been
        removed. name changed or is temporarily unavailable
      </Typography>
      <ThemedButton style={{ width: 300 }} component={Link} to="/">
        Go To Homepage
      </ThemedButton>
    </Box>
  );
};

export default PageNotFound;

import axios from "./axios"

const fieldErrors = JSON.parse(localStorage.getItem("fieldErrors"));

const checkPromoCode = async (promo_code) => {
    let promoCheck = {
        value: 0,  // 0 not checked, 1 valid, 2 not valid, 3 inactive
        message: "Failed to check promo code",
        description: null
    }
    await axios.get(`/promocodes/${promo_code}`)
        .then(promoCheckRes => {
            if (promoCheckRes.data && promoCheckRes.data.description) {
                promoCheck = {
                    value: 1,  // valid
                    message: "Valid promo code",
                    description: promoCheckRes.data.description
                };
            }
        }).catch(function (error) {
            if (error.response) {
                if (error.response.status === 409) {
                    promoCheck = {
                        value: 3,  // inactive
                        // message: error.response.data.message
                        message: fieldErrors?.zipCode.promo_code.inactive
                    };
                }
                else if (error.response.status === 404) {
                    promoCheck = {
                        value: 2,  // invalid
                        // message: error.response.data.message
                        message: fieldErrors?.zipCode.promo_code.invalid
                    };
                }
            }
        })
    return promoCheck;
}

export default checkPromoCode;

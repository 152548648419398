import { Box, Popover, Radio, Typography } from "@mui/material";
import { Field, formValueSelector, reduxForm } from "redux-form";
import InputFields, {
  ThemedButton,
  ThemedTitle,
} from "src/Components/InputFields";
import GCTooltip from "src/Components/GCTooltip";
import { useEffect, useState } from "react";
import getScreenDetail from "src/utils/screenDetails";
import GCSmallLogo from "src/assets/gc_logo_30.svg";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import InfoIcon from '@mui/icons-material/Info';
import Divider from '@mui/material/Divider';

//Logos
import houstonPA from "src/assets/partners/houston.png";
import dallasPA from "src/assets/partners/dallas.png";
import getInitFields from "src/utils/getInitFields";
import Theme from "src/Theme/theme";
import axios from "src/utils/axios";
import getTokens from "src/utils/getTokens";
import { connect } from "react-redux";
import { checkUTMCode } from "src/utils/utmCookies";

const useStyles = makeStyles((theme) => ({
  convDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "center",
    },
  },
  checkBoxDiv: {
    // width: 280,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  checkCss: {
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  match: {
    color: "#3A3A3A",
    width: 260,
    [theme.breakpoints.up("md")]: {
      width: 420
    }
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  fullWidthField: {
    [theme.breakpoints.up("md")]: {
      width: '100%'
    },
  },
  generalRescue: {
    fontWeight: 'bold',
    fontFamily: 'nunito',
    marginTop: 20,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  generalRescueInput: {
    marginLeft: 0,
    [theme.breakpoints.up("md")]: {
      marginLeft: 20
    }
  },
  allCyan: {
    color: Theme.palette.secondary.main
  },
  dhpColor: {
    color: '#3A3A3A'
  },
  infoiconColor: {
    color: '#21212185'
  },
  orDivider: {
    marginTop: 30,
    marginBottom: 30
  }
}));

let partnersList = [];

const fieldErrors = JSON.parse(localStorage.getItem("fieldErrors"));

const validationSchema = Yup.object().shape({
  partner: Yup.string().trim().nullable()
    .when("general_rescue", {
      is: false,
      then: Yup.string().trim().nullable().test(
        "valid option check", fieldErrors?.partner?.partner, value => {
          let check = (partnersList.length === 0 || partnersList.includes(value)) ? true : false;
          return check;
        }
      ).required(fieldErrors?.required),
    }),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

const dummyPartners = [
  {
    "id": 1,
    "partner_name": "Houston Pets Alive",
    "active": 1,
    "img_url": "src/assets/partners/houston.png",
    "sg_account_notes": "houston"
  },
  {
    "id": 2,
    "partner_name": "Dallas Pets Alive",
    "active": 1,
    "img_url": "src/assets/partners/dallas.png",
    "sg_account_notes": "dallas"
  },
  {
    "id": 3,
    "partner_name": "Lola’s Lucky Day",
    "active": 1,
    "img_url": "src/assets/partners/lolas_lucky_day.png",
    "utm_campaign": "ST",
    "utm_source": "UV",
    "utm_medium": "WX",
    "utm_content": "YZ",
    "sg_account_notes": "lolas_lucky_day"
  },
  {
    "id": 4,
    "partner_name": "Lost Dogs of Texas ",
    "active": 1,
    "img_url": "src/assets/partners/lost_dogs_of_texas.png",
    "sg_account_notes": "lost_dogs_of_texas"
  }
]

let Partner = (props) => {
  const { handleSubmit, submitting, invalid, general_rescue, change } = props;
  const classes = useStyles();
  const [screenDetail, setScreenDetail] = useState(null);
  const [partners, setPartners] = useState([]);
  const [anchor, setAnchor] = useState(null);

  const open = Boolean(anchor);

  const handlePopoverOpen = (event) => {
    setAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const checkPartnerUTM = (partners) => {
    let submitted = localStorage.getItem("partner");
    let utmCode = localStorage.getItem("partnerutmlookupcode");
    if (submitted) {
      let submittedData = JSON.parse(submitted)
      if (submittedData?.partner === null) {
        if (submittedData?.general_rescue === false || general_rescue === false) {
          let matched = false;
          if (utmCode) {
            partners.map(partner => {
              // let partnerUTM = partner.utm_campaign + partner.utm_source + partner.utm_medium + partner.utm_content;
              let partnerUTM = partner.utm_content;
              if (utmCode === partnerUTM) {
                matched = true;
                change("partner", partner.label)
              }
            })
          }
          return matched;
        }
      }
    } else {
      let matched = false;
      if (utmCode) {
        partners.map(partner => {

          let partnerUTM = partner.utm_content;
          if (utmCode === partnerUTM) {
            matched = true;
            change("partner", partner.label)
          }
        })
      }
      return matched;
    }
    return false;
  }

  const handleFocusPartner = () => {
    change("general_rescue", false);
  }

  function sortedPartners(key) {
      return function(a, b) {
          if (a[key] > b[key]) {
              return 1;
          } else if (a[key] < b[key]) {
              return -1;
          }
          return 0;
      }
  }

  const getPartners = async () => {
    try {
      let response = await axios.get("partner/list");
      // let partnerArray = dummyPartners;
      let partnerArray = response.data.partners;
      let newArray = []
      partnerArray.map((item) => {
        if (item.active) {
          partnersList.push(item.partner_name)

          newArray.push({
            id: item.id,
            label: item.partner_name,
            img_url: item.img_url,
            utm_campaign: item.utm_campaign,
            utm_source: item.utm_source,
            utm_medium: item.utm_medium,
            utm_content: item.utm_content,
            sg_account_notes: item.sg_account_notes
          })
        }
      })
      setPartners(newArray.sort(sortedPartners("label")));
      localStorage.setItem("partnersList", JSON.stringify(newArray));
      let matched = checkPartnerUTM(newArray);
      if (matched && (general_rescue || general_rescue === undefined)) {
        change("general_rescue", false)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const init = async () => {
      let details = await getScreenDetail("partner");
      setScreenDetail(details);
      let json = getInitFields("partner");
      if (json && json.address) {
      }
    };
    init();
    getPartners()
  }, []);

  useEffect(() => {
    if (general_rescue === false) {
      checkPartnerUTM(partners);
    }
  }, [general_rescue])


  return (
    <form id="partner" onSubmit={handleSubmit}>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <br />
        <div className={classes.generalRescue}>
          <img src={GCSmallLogo} alt="GC General Rescue" /> &nbsp;
          GoodCharlie General Rescue Fund
          <div className={classes.generalRescueInput}>
            <Field
              name="general_rescue"
              type="switch"
              component={InputFields}
              additional={() => {
                if (!general_rescue) {
                  change("partner", null);
                }
              }}
              placeholder=""
            />
          </div>
          <InfoIcon
            style={{ fontSize: 'large', marginLeft: 5, cursor: 'pointer' }}
            className={Boolean(anchor) ? classes.allCyan : classes.infoiconColor}
            onClick={handlePopoverOpen}
          />
          <Popover
            open={open}
            anchorEl={anchor}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            style={{
              marginLeft: 16,
              borderRadius: '8px',
            }}
          >
            <Box style={{
              maxWidth: '246px',
              padding: '8px 9px 8px 16px',
              backgroundColor: '#EDFFFC',

            }}
            >
              <Typography
                variant="body2"
                color={Theme.palette.secondary.main}
                fontWeight="600"
              >
                {localStorage.getItem("generalRescueFundInfo")}
              </Typography>
            </Box>
          </Popover>
        </div>
        <div className={classes.orDivider}>
          <Divider>
            <Typography variant="inherit" color="GrayText">OR</Typography>
          </Divider>
        </div>

        {partners.length ? (
          <div style={{ marginTop: 20, width: "100%", }} onClick={handleFocusPartner}>
            <Field
              fullWidth
              // disabled={general_rescue}
              customClass={"fullWidthField"}
              component={InputFields}
              type="selectSearch"
              name="partner"
              additional={{ key: "label", field: "partner", partners: partners }}
              placeholder={"Select a specific rescue partner"}
              options={partners.map((data) => data.label)}
            />
          </div>
        ) : null}

        <br />
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Box className={classes.checkBoxDiv}>
            <Field
              component={InputFields}
              variant="outlined"
              type="checkBox"
              name="match"
            />
            <Typography className={classes.match} variant="body2">
              I want to match GoodCharlie’s monthly roundup of my bill and donate too.
            </Typography>
          </Box>
        </Box>

        <Box className={classes.forContinueAndTooltop}>
          <Box>
            <ThemedButton
              isSubmitting={submitting}
              disabled={invalid}
              type="submit"
            >
              Continue
            </ThemedButton>
          </Box>
          <Box>
            {screenDetail && screenDetail.tooltip && (
              <GCTooltip>{screenDetail.tooltip}</GCTooltip>
            )}
          </Box>
        </Box>
      </div>
    </form >
  );
};

Partner = reduxForm({
  initialValues: getInitFields("partner"),
  form: "Partner",
  destroyOnUnmount: false,
  validate,
})(Partner);

const selector = formValueSelector('Partner') // <-- same as form name
Partner = connect(
  state => {
    const general_rescue = selector(state, 'general_rescue');
    return {
      general_rescue
    }
  }
)(Partner)

export default Partner;

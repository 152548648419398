import { Field, reduxForm } from "redux-form";
import { makeStyles } from "@mui/styles";
import InputFields, {
  FieldTitle,
  ThemedButton,
} from "src/Components/InputFields";
import * as Yup from "yup";
import getInitFields from "src/utils/getInitFields";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import getScreenDetail from "src/utils/screenDetails";
import GCTooltip from "src/Components/GCTooltip";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
}));

const fieldErrors = JSON.parse(localStorage.getItem("fieldErrors"));
const fieldTitles = JSON.parse(localStorage.getItem("fieldTitles"));

const validationSchema = Yup.object().shape({
  first_name: Yup.string().trim().nullable().required(fieldErrors?.required),
  last_name: Yup.string().trim().nullable().required(fieldErrors?.required),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

let CustomerInfo = (props) => {
  const classes = useStyles();
  const { handleSubmit, submitting, invalid } = props;
  const [screenDetail, setScreenDetail] = useState(null);

  useEffect(() => {
    const init = async () => {
      let details = await getScreenDetail("basic-info");
      setScreenDetail(details);
    };
    init();
  }, []);

  return (
    <form id="basic-info" onSubmit={handleSubmit}>
      {/* <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <ThemedTitle>{title}</ThemedTitle>
      </div> */}
      <div className={classes.mainDiv}>
        {/* <label htmlFor="firstName">Your first name</label> */}
        <div>
          {/* <FieldTitle required>Your first name</FieldTitle> */}
          {/* <Field name="firstName" component={InputFields} type="textField" /> */}
          <Field
            component={InputFields}
            variant="outlined"
            type="textField"
            placeholder={fieldTitles?.basicInfo.first_name}
            name="first_name"
          />
        </div>
      </div>
      <div className={classes.mainDiv}>
        <div>
          {/* <FieldTitle required>Your last name</FieldTitle> */}
          <Field
            name="last_name"
            placeholder={fieldTitles?.basicInfo.last_name}
            component={InputFields}
            variant="outlined"
            type="textField"
          />
        </div>
      </div>
      <Box className={classes.forContinueAndTooltop}>
        <Box>
          <ThemedButton
            disabled={invalid}
            isSubmitting={submitting}
            type="submit"
          >
            Continue
          </ThemedButton>
        </Box>
        <Box>
          {screenDetail && screenDetail.tooltip && (
            <GCTooltip>{screenDetail.tooltip}</GCTooltip>
          )}
        </Box>
      </Box>
    </form>
  );
};

CustomerInfo = reduxForm({
  initialValues: getInitFields("basic-info"),
  destroyOnUnmount: false,
  validate,
  form: "customerInfo",
})(CustomerInfo);

export default CustomerInfo;

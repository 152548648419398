import BankDetails from "./BankDetails";
import NoDepositCardDetails from "./CardDetails";
import CreditCheckFailed from "./CreditCheckFailed";
import PageNotFound from "src/Components/PageNotFound"

const PaymentDetails = ({ onSubmit }) => {
  const getData = (scope) => {
    let data = localStorage.getItem(scope);
    try {
      data = JSON.parse(data);
    } catch (e) {
      data = null;
    }
    return data;
  }
  const credit_check = getData("credit_check_response");
  const method = getData("payment-method");
  if (credit_check.totalDepositAmount > 0) {
    return <CreditCheckFailed onSubmit={onSubmit} />;
  } else if (method.payment_type === "bank") {
    return <BankDetails onSubmit={onSubmit} />;
  } else if (method.payment_type === "card") {
    return <NoDepositCardDetails onSubmit={onSubmit} />;
  } else {
    return <PageNotFound />
  }
};

export default PaymentDetails;

import { Card, Dialog, Grid, Typography, Box, MenuItem, Paper } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { Field, reduxForm, SubmissionError, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import InputFields, {
  ThemedButton,
} from "src/Components/InputFields";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "src/utils/axios";
import * as Yup from "yup";
import moment from "moment";
import GCTooltip from "src/Components/GCTooltip";
import getScreenDetail from "src/utils/screenDetails";
import { useSnackbar } from "notistack";
import dialogImg from "src/assets/review-popup.png";
import dialogImg2 from 'src/assets/reviewMobileImage.png';
import EnergyIcon from 'src/assets/energyIcon.svg';
import accountCreation from "src/utils/accountCreation";
import checkPromoCode from "src/utils/checkPromoCode";
import Theme from "src/Theme/theme";
import InfoModal from "src/Components/InfoModal";
import docFilter from "src/utils/docFilter";

import VideoVetPink from 'src/assets/plan/goodenergy/videovetpink.svg';
import RoundupPink from 'src/assets/plan/goodenergy/rounduppink.svg';
import EmergencyFundPink from 'src/assets/plan/goodenergy/emergencyfundpink.svg';
import EmergencyFundBlue from 'src/assets/plan/goodgreen/emergencyfundblue.svg';
import VideoVetBlue from 'src/assets/plan/goodgreen/videovetblue.svg';
import SavingsBlue from 'src/assets/plan/goodgreen/roudupblue.svg';
import { autoPayOpt } from "src/utils/getPaymentOptions";
import { getAccountNote } from "src/utils/getPartnerID";

let submitLog = true;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 326,
    [theme.breakpoints.up("sm")]: {
      width: 540,
    },
  },
  benefitsContainer: {
    padding: "5px 0",
    margin: "3vh 0",
    width: "100%",
    borderRadius: "12px !important",
    position: 'relative',
    [theme.breakpoints.up("sm")]: {
      margin: "3vh auto",
      maxWidth: 540,
    },
  },
  benefits: {
    padding: "10px 15px",
    display: "flex",
    alignItems: 'center'
  },
  divider: {
    display: "flex",
    height: 1,
    width: "100%",
  },
  rightIcon: {
    paddingRight: 20,
    height: 32,
    width: 32
  },
  billingDetails: {
    padding: "15px 0px",
    margin: "3vh 0",
    width: "100%",
    backgroundColor: "rgba(243, 243, 243, 0.3)",
    borderRadius: "12px !important",
    [theme.breakpoints.up("md")]: {
      // width: 543,
    },
  },
  gridContainer: {
    padding: "10px 0px",
    [theme.breakpoints.up("md")]: {
      padding: "15px 10px",
    },
  },
  gridHead: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
    color: "rgba(33, 33, 33, 0.52)",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 30,
    },
  },
  gridBody: {
    width: "100%",
    paddingLeft: 5
  },
  multFlexBox: {
    display: "flex",
  },
  itemValue: {
    //whiteSpace: "nowrap",
    overflow: "hidden",
    cursor: "default",
    fontWeight: 600,
    // "&:hover": {
    //   overflow: "visible",
    // },
    // textOverflow: "ellipsis",
    whiteSpace: 'pre-line',
    flex: 1,
  },
  metaStyle: {
    textOverflow: "ellipsis",
    fontWeight: 600,
    color: "rgba(33, 33, 33, 0.52)",
    flex: 0.8,
  },
  descContainer: {
    width: 150,
    height: 90,
    [theme.breakpoints.up("md")]: {
      width: 240,
      height: 123,
    },
    marginTop: 10,
    backgroundColor: "rgb(196, 196, 196, 0.1)",
    borderRadius: "0px 12px 12px 12px",
    padding: "16px 14px",
    color: "rgba(33, 33, 33, 0.52)",
    overflowY: "scroll",
    whiteSpace: "wrap",
  },
  descText: {
    cursor: "default",
    fontWeight: 600,
    "&:hover": {
      overflow: "visible",
    },
    textOverflow: "ellipsis",
    flex: 1,
  },
  agreement: {
    display: "flex",
    justifyContent: "flex-start",
  },
  checkbox: {
    // marginTop: 10,
    [theme.breakpoints.up("md")]: {
      marginTop: -10
    },
  },
  themedText: {
    textTransform: "none",
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
  dialogImg: {
    height: '100%',
    width: '100%',
    borderRadius: '11px',
    [theme.breakpoints.up("md")]: {
      height: '100%',
      width: '100%',
      // objectFit: 'fill'
    },
  },
  reviewModal: {
    height: 458,
    width: 328,
    overflow: 'hidden',
    [theme.breakpoints.up("md")]: {
      height: 500,
      width: 980,
      overflow: 'hidden',
    },
  },
  imgBox: {
    overflow: "hidden",
    borderRadius: 8,
    height: '189px',
    margin: '12.5px 14px 0px 14px',
    [theme.breakpoints.up("md")]: {
      margin: '16px 17px 16px 17px',
      height: '468px',
      width: '458px'
    },
  },
  dialogContent: {
    padding: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: 'center',
    height: "90%",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      justifyContent: "center",
      textAlign: 'center',
      height: "100%",
    },
  },
  reviewBox1: {
    textAlign: 'center',
    height: 142,
    width: 268,
    marginBottom: '20px',
    [theme.breakpoints.up("md")]: {
      height: "135px",
      width: "390px",
      marginBottom: '120px',
      marginLeft: '15px'
    },
  },
  reviewModalTextHurray: {
    fontSize: "20px",
    fontWeight: "700",
    color: Theme.palette.secondary.main,
    [theme.breakpoints.up("md")]: {
      fontSize: "34px",
      fontWeight: "700",
    },
  },
  reviewModalText1: {
    fontSize: "20px",
    fontWeight: "700",
    [theme.breakpoints.up("md")]: {
      fontSize: "34px",
      fontWeight: "700",
    },
  },
  reviewModalText2: {
    height: "40px",
    width: "268px",
    fontSize: '13px',
    [theme.breakpoints.up("md")]: {
      fontSize: '16px',
      height: "48px",
      width: "386px",
    },
  },
  sgFail: {
    minHeight: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "25px 10px",
    [theme.breakpoints.up("md")]: {
      padding: "5px 150px",
      minHeight: 260,
      alignItems: "center",
    }
  },
  sgFailTitle: {
    fontSize: "20px !important",
    lineHeight: "22px !important",
    paddingBottom: 10,
    [theme.breakpoints.up("md")]: {
      paddingBottom: 15,
      fontSize: "23px !important"
    }
  },
  sgFaildesc: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      // textAlign: "center"
    }
  },
  sgFailAction: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
      marginTop: 15,
      alignItems: "flex-start",
      justifyContent: "flex-start"
    }
  },
  benefitsSection: {
    padding: '26px 25px',
    [theme.breakpoints.up("md")]: {
      padding: '26px 46px',
    }
  },
  forBenefitsBText: {
    fontSize: 12,
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
    }
  },
  benefitsLogoSizing: {
    maxHeight: 20,
    [theme.breakpoints.up("md")]: {
      maxHeight: 23,
    }
  },
  planBannerCss: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 26,
    width: 110,
    backgroundColor: 'white',
    fontSize: 8,
    borderRadius: 30,
    position: 'absolute',
    left: 190,
    top: -13,
    fontWeight: 600,
    [theme.breakpoints.up("sm")]: {
      left: 380,
      top: -13
    }
  },
  itemValueText: {
    fontWeight: 600,
    fontSize: 11,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    }
  },
  metaValueText: {
    fontWeight: 600,
    fontSize: 11,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    }
  },
  titleText: {
    fontWeight: 600,
    fontSize: 13,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    }
  },
  languageOptionsText: {
    fontWeight: 600,
    fontSize: 12,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    }
  },
  agreementText: {
    fontSize: 10,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    }
  },
  noDepositText: {
    color: "#3A3A3A",
    fontWeight: "normal",
    fontSize: 13,
    marginBottom: 10,
    [theme.breakpoints.up("md")]: {
      marginBottom: 20,
      fontSize: 20,
    }
  },
  docStyling: {
    "&:hover": {
      textDecorationLine: 'underline'
    }
  }
}));

const validationSchema = Yup.object().shape({
  agreement: Yup.boolean().oneOf([true], "Message"),
  referral_code: Yup.string().nullable(),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    // window.NREUM.noticeError(errors);                // no need since these errors are for basic field validation
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

const dashedBorderStyle = {
  border: 0,
  borderTop: 1,
  borderColor: "#E6E6E6",
  borderStyle: "dashed",
};

const getData = async (name) => {
  let localData = await localStorage.getItem(name);
  return JSON.parse(localData);
};

const _getData = (name) => {
  let localData = localStorage.getItem(name);
  return JSON.parse(localData);
};

let autoPayOption = autoPayOpt();

let Review = (props) => {
  const { handleSubmit, submitting, invalid, language, referral_code, change } = props;
  const classes = useStyles();
  const [screenDetail, setScreenDetail] = useState(null);
  const [promoCode, setPromoCode] = useState(null);
  const [docProviderID, setDocProviderID] = useState(null);
  const [_records, setRecords] = useState([]);
  const myaccountUrl = process.env.REACT_APP_MYACCOUNT_REDIRECT;

  const [currentPlanData, setCurrentPlanData] = useState(null); //object {} plan
  const [reviewRes, setReviewRes] = useState({
    sgSuccess: false,
    error: false,
    message: null
  });
  const [accTokens, setAccTokens] = useState(null);

  const [noDepositTitle, setNoDepositTitle] = useState(null);
  const navigate = useNavigate();

  const closeSgFail = () => {
    setReviewRes({
      sgSuccess: false,
      error: false,
      message: null
    });
  }

  const fields = [
    "name",
    "address",
    "esiid",
    "contact-info",
    "preference",
    "language",
    "payment-method",
    "deposit",
    "promo-code",
    "refferal-code",
    "start-date",
    "opt-ins"
  ]


  useEffect(() => {
    const getRecords = async () => {
      let reviewRecords = [];

      let basicInfo = _getData("basic-info");
      let addressInfo = _getData("address");
      let contactInfo = _getData("contact-details");
      let paymentMethod = _getData("payment-method");
      let partnerInfo = _getData("partner");
      let startDateInfo = _getData("start-date");
      let paymentDetails = _getData("payment-details");
      let paymentResponse = _getData("payment-response");
      let creditCheck = _getData("credit_check_response");
      let zipData = await getData("zip-code");

      fields.map(async (item) => {
        const addField = (key, value) => {
          let obj = {
            title: key,
            value: value
          }
          reviewRecords.push(obj)
        }
        switch (item) {
          case "name":
            addField("Name", `${basicInfo.first_name} ${basicInfo.last_name}`);
            break;
          case "address":
            addField("Address", addressInfo.address)
            break;
          case "esiid":
            addField("ESI ID", addressInfo.esiid)
            break;
          case "contact-info":
            try {
              let contact = [
                { name: "Phone", value: contactInfo.phone_number.replace(1, "") },
                { name: "Email", value: contactInfo.email },
              ]
              addField("Contact Info", contact)
            } catch (err) {
              window.NREUM.noticeError(err)                              // mylog
            }
            break;
          case "preference":
            let chOrg = partnerInfo.partner ? partnerInfo.partner : "Good Charlie General Rescue Fund"
            let capOrg = chOrg[0].toUpperCase() + chOrg.slice(1);
            let pref = [
              {
                name: "auto_pay",
                value: "Auto Pay",
                meta: paymentMethod.auto_pay ? "On" : "Off"
              },
              {
                name: "eBill",
                value: "eBill",
                meta: paymentMethod.eBill ? "On" : "Off"
              },
              {
                name: "partner",
                value: "Charity Org.",
                meta: capOrg
              },
              {
                name: "match",
                value: "Matching Roundup:",
                meta: partnerInfo.match ? "On" : "Off"
              },
            ]
            addField("Preferences", pref)
            break;
          case "language":
            let lang = {
              title: "Language",
              inputs: [{
                customClass: "reviewLang",
                type: "selectField",
                name: "language",
                value: "English",
                placeholder: "Select language",
                options: [
                  {
                    key: 1,
                    value: "English",
                    label: "English"
                  },
                  {
                    key: 1,
                    value: "Spanish",
                    label: "Spanish"
                  },
                ]
              }],
            }
            reviewRecords.push(lang);
            break;
          case "payment-method":
            // case "deposit":
            let payment = [];
            let deposit = null;
            if (paymentResponse) {
              let amount = Number(paymentResponse.amount)
              let method = {
                name: "method",
                value: paymentResponse.cardNumber ? "Credit Card" : "Bank"
              }
              let payNumber = {
                name: "paymentNumber",
                value: paymentResponse.cardNumber ? `************${paymentResponse.cardNumber}`
                  : `**********${paymentResponse.accountNumber}`
              }
              if (amount > 0) {
                payment.push(method);
                payment.push(payNumber)
                deposit = `$${amount}`;
              } else {
                payment.push(method);
                payment.push(payNumber);
                if (paymentDetails && paymentDetails.deposit_waiver) {
                  deposit = "*Pending"
                }
              }
            } else if (creditCheck.totalDepositAmount > 0 && paymentDetails.deposit_waiver) {
              let method = {
                name: "method",
                value: "*Pending"
              }
              payment.push(method);
              deposit = "*Pending"
            } else {
              let method = {
                name: "method",
                value: "None"
              }
              payment.push(method);
            }
            addField("Payment Method", payment);
            if (deposit) {
              addField("Deposit", deposit)
            }
            break;
          case "promo-code":

            if (zipData.promo_code) {
              let promoCheck = await checkPromoCode(zipData.promo_code);
              if (promoCheck.value === 1) {
                let localPromoCode = ({
                  code: zipData.promo_code,
                  description: promoCheck.description
                });
                setPromoCode(localPromoCode);               // need a local variable since it is not gonna reflect immediately
                let obj = {
                  title: "Promo Code",
                  value: localPromoCode
                    ? (localPromoCode ? localPromoCode.code : "-")
                    : "None",
                  description: localPromoCode ? localPromoCode.description : null
                }
                reviewRecords.push(obj)
              }
            } else {
              addField("Promo Code", "None")
            }
            break;
          case "refferal-code":
            reviewRecords.push({
              title: "Refferal Code",
              inputs: [{
                type: "textField",
                name: "referral_code",
                // value: localStorage.getItem("referral_code") || "hshdf",
                placeholder: "Enter referral code",
                customClass: "reviewField"
              }],
            })
            break;
          case "start-date":
            addField("Start Date", moment(startDateInfo.start_date).format("LL") || "None");
            break;
          case "opt-ins":
            let optIns = [
              {
                name: "SMS Communication",
                value: "SMS Communication",
                meta: contactInfo.sms_communication ? "On" : "Off"
              },
              {
                name: "Marketing Communication",
                value: "Marketing Communication",
                meta: contactInfo.marketing_communication ? "On" : "Off"
              }
            ]
            addField("Opt Ins", optIns);
            break;
          default:
            return null;
        }
      })
      setRecords(reviewRecords);
    }
    getRecords();

    let nextPage = JSON.parse(localStorage.getItem("nextPage"));
    let screenNow = window.location.pathname;
    if (screenNow.replace('/', '') !== nextPage) {
      if (nextPage) {
        navigate(`/${nextPage}`);
        window.location.reload()
      }
    }
  }, [])

  const docRedirection = (url) => {
    if (url) {
      window.open(`/signup/api/v1/get/document/${encodeURIComponent(url)}`, "_blank");
    }
  }

  useEffect(() => {

    const init = async () => {

      if (!accTokens) {
        let tokens = await localStorage.getItem("tokens");
        setAccTokens(JSON.parse(tokens))
      }

      if (localStorage.getItem("sg_account")) {
        setReviewRes({
          sgSuccess: true,
          error: false,
          message: null
        })
      }

      let details = await getScreenDetail("review");
      setScreenDetail(details);

      if (!autoPayOption) {
        let creditCheck = _getData("credit_check_response");
        if (creditCheck.totalDepositAmount === 0) {
          let depositdetails = await getScreenDetail("payment-method");
          if (typeof depositdetails === "object" && typeof depositdetails.description === "string") {
            depositdetails = depositdetails.description.split(".")[0];
            setNoDepositTitle(depositdetails);
          }
        }
      }

      let dspIdData = await getData("distribution_point_response");
      // console.log(dspIdData)

      let dataForPlanId = await getData("plan");

      const planDataAPI = async () => {
        let tokenForAPI = await getData("tokens");
        let zipData = await getData("zip-code");

        let dataServiceProvider = {
          distributionServiceProviderID: dspIdData[0].distributionServiceProviderID,
          zip: zipData.zip_code
        }
        try {
          let response = await axios.post(`/sg/get/product/by/service/provider`, dataServiceProvider,
            {
              headers: {
                "sg-authorization": `Bearer ${tokenForAPI.sg_token.accessToken}`,
              },
            },
          );

          response.data.data.webProducts.map((i) => {
            if (i.productID === Number(dataForPlanId.plan_id)) {
              setCurrentPlanData(i);
              setDocProviderID(response.data.data.distributionServiceProvider.distributionServiceProviderID);
            }
          })
        } catch (e) {
          window.NREUM.noticeError(e);
          setCurrentPlanData(null)
        }
      }

      if (!currentPlanData) {
        planDataAPI();
      }

    };
    if (!referral_code) {
      change("referral_code", localStorage.getItem("referral_code") || null);
    }
    change("language", language);
    let path = window.location.pathname.replaceAll("/", "");
    if (path === "review") {
      init();
    }
    return () => null;
  }, []);

  const ThemedText = ({ children, to }) => (
    <Link to="/review/#" className={classes.themedText} >{` ${children}`}</Link>
  );

  const { enqueueSnackbar } = useSnackbar();

  const submitPromise = ms => new Promise(resolve => setTimeout(resolve, ms))

  const submit = async (values) => {
    return submitPromise(0) // simulate server latency
      .then(async () => {
        let response = await checkReferralCode(values.referral_code);
        // console.log(response)
        let customFields = []
        if (response.valid === 0) {
          throw new SubmissionError({ referral_code: 'Invalid referral code' })
        } else if (response.valid === 1) {
          let accountField = {
            "accountCustomFieldID": 0,
            "accountID": 0,
            "customFieldID": 1003,
            "value": response.data.account_number
          }
          let partnerAccountNote = getAccountNote("label", response.data.partner)

          let donationPreference = {
            "accountCustomFieldID": 0,
            "accountID": 0,
            "customFieldID": 1004,
            "value": partnerAccountNote
          }
          customFields.push(accountField)
          customFields.push(donationPreference)
        }
        if (promoCode) {
          let promoField = {
            "accountCustomFieldID": 0,
            "accountID": 0,
            "customFieldID": 2003,
            "value": promoCode.code,
          }
          customFields.push(promoField);
        }

        let metaData = await getData("metaData");
        let payment = await getData("payment-method");
        let PaymentAccountTypeID = null;

        if (!payment) {
          window.NREUM.noticeError("review> missing_data: <payment-method>");        // mylog
        } else {
          metaData.map(({ listType, listData }) => {

            if (listType === "PAYMENT_ACCOUNT_TYPE") {
              listData.map(({ name, value }) => {
                if ((payment.payment_type === "card" && name === "Credit Card") || (payment.payment_type === "bank" && name === "E-Check")) {
                  PaymentAccountTypeID = value;
                }
              })
            }
          })
        }

        try {
          let forUuid = await getData("tokens");
          let data = {
            uuid: forUuid.uuid,
            plan_id: String(currentPlanData.productID),
            plan_name: currentPlanData.name,
            plan_amount: currentPlanData.kwh1000Price,
            referral_code: values.referral_code || null,
            // payment_account_type_id: PaymentAccountTypeID
          }
          let response = await axios.patch(`/customer/update`, data);
          await accountCreation(setReviewRes, enqueueSnackbar, customFields)
        } catch (e) {
          console.error(e);
          window.NREUM.noticeError(e);
          setReviewRes({
            sgSuccess: false,
            error: true,
            message: e.message
          });
        }
      })
  }

  const formEl = useRef();

  const checkReferralCode = async (referral_code) => {
    let resJson = {
      valid: 2,
      data: null
    }   // 0 invalid, 1 valid, 2 no referral code
    if (referral_code && referral_code.trim() !== "") {
      try {
        let response = await axios.post("/validate/referral/code", {
          "referral_code": referral_code
        });
        response = response.data
        if (response.data) {
          resJson.valid = 1
          resJson.data = response.data
        } else {
          resJson.valid = 0
        }
      } catch (e) {
        window.NREUM.noticeError(e);
        throw new SubmissionError({ _error: e })
      }
    } else {
      resJson.valid = 2
    }
    return resJson;
  }
  if (submitLog) {
    if (_records) {
      if (_records[0]) {
        submitLog = false;
        let logPayload = {
          "records": _records,
          "file": 'Review:load',
          "cookies": document.cookie
        }
        if (accTokens.uuid) {
          logPayload.uuid = accTokens.uuid;
        }
        axios.post("/log", logPayload);
      }
    }
  }
  return (
    <form id="review" ref={formEl} onSubmit={handleSubmit(submit)}>
      <Box className={classes.root}>
        {noDepositTitle &&
          <span className={classes.noDepositText}>
            {noDepositTitle}
          </span>
        }
        <Box className={classes.container}>

          <Box className={classes.benefitsContainer}
            style={{
              border: currentPlanData && currentPlanData.greenEnergyPercent ? `1px solid ${Theme.palette.secondary.main}` : `1px solid ${Theme.palette.primary.main}`,
              backgroundColor: currentPlanData && currentPlanData.greenEnergyPercent ? '#E6FAF8' : '#FFF7F8'
            }}>
            {currentPlanData && (currentPlanData.isRecommended || currentPlanData.isFeatured) &&
              <Box className={classes.planBannerCss} style={{ border: currentPlanData.greenEnergyPercent ? `1px solid ${Theme.palette.secondary.main}` : `1px solid ${Theme.palette.primary.main}` }}>
                {currentPlanData.isRecommended ? "MOST POPULAR" : "CHARLIE'S CHOICE"}
              </Box>
            }
            <Box className={classes.benefitsSection}>
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {currentPlanData && currentPlanData.kwh2000Price && <Typography variant="body2" style={{ fontSize: '24px', fontWeight: 'bold', color: '#4D4D4D' }}>
                      {currentPlanData.kwh2000Price.toLocaleString(navigator.language, { minimumFractionDigits: 1 })}¢
                    </Typography>}
                  </Box>

                  <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="body2" style={{ fontSize: '12px', fontWeight: 600, color: '#21212185' }}>
                      Based on 2,000 kWh
                    </Typography>
                  </Box>
                </Box>
                {currentPlanData && currentPlanData.name && <Typography variant="body2" style={{ fontSize: '20px', fontWeight: 'bold', color: currentPlanData.greenEnergyPercent ? Theme.palette.secondary.main : Theme.palette.primary.main }}>
                  {currentPlanData.name}
                </Typography>}
              </Box>
              <Box style={{ display: 'flex', marginTop: 15 }}>
                {currentPlanData && currentPlanData.productDisplayShortDescription &&
                  <Typography variant="body2" style={{ width: 'auto' }}>
                    <span className={classes.forBenefitsBText}>
                      • {currentPlanData.productDisplayShortDescription.replace("-", " ").replace(",", "")}
                    </span>
                  </Typography>
                }

                <Typography variant="body2" style={{ width: 'auto', marginLeft: 10 }}>
                  <span className={classes.forBenefitsBText}>
                    • Earth friendly e-billing
                  </span>
                </Typography>
              </Box>

              {currentPlanData && currentPlanData.greenEnergyPercent !== 0 &&
                <Box style={{ display: 'flex', marginTop: 10 }}>
                  <Typography variant="body2" style={{ width: 'auto' }}>
                    <span className={classes.forBenefitsBText}>
                      • {currentPlanData.greenEnergyPercent * 100}%&nbsp; renewable energy
                    </span>
                  </Typography>
                </Box>}
            </Box>
            <Box style={{ borderTop: (currentPlanData && currentPlanData.greenEnergyPercent) ? `1px dashed ${Theme.palette.secondary.main}` : `1px dashed ${Theme.palette.primary.main}` }} />

            <Box className={classes.benefitsSection}>
              <Grid container spacing={3}>
                <Grid item xs="auto">
                  {
                    (currentPlanData && currentPlanData.productDisplayFeature2) ?
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={currentPlanData.greenEnergyPercent ? VideoVetBlue : VideoVetPink} alt="time period" className={classes.benefitsLogoSizing} />
                        <Box width="10px" />
                        <Typography variant="body2">
                          <span className={classes.forBenefitsBText}>
                            {currentPlanData.productDisplayFeature2}
                          </span>
                          {/* 24/7 Video Vet */}
                        </Typography>
                      </Box>
                      : null
                  }
                </Grid>
                <Grid item xs="auto">
                  {(currentPlanData && currentPlanData.productDisplayFeature3) ?
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={currentPlanData.greenEnergyPercent ? EmergencyFundBlue : EmergencyFundPink} alt="fixed rate" className={classes.benefitsLogoSizing} />
                      <Box width="10px" />
                      <Typography variant="body2">
                        <span className={classes.forBenefitsBText}>
                          {currentPlanData.productDisplayFeature3}
                        </span>
                      </Typography>
                    </Box>
                    : null
                  }
                </Grid>
                <Grid item xs="auto">
                  {(currentPlanData && currentPlanData.productDisplayFeature4) ?
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={currentPlanData.greenEnergyPercent ? SavingsBlue : RoundupPink} alt="renewable energy" className={classes.benefitsLogoSizing} />
                      <Box width="10px" />
                      <Typography variant="body2" className={classes.forBenefitsBText}>
                        <span className={classes.forBenefitsBText}>
                          {currentPlanData.productDisplayFeature4}
                        </span>
                      </Typography>
                    </Box>
                    : null
                  }
                </Grid>
              </Grid>
            </Box>

          </Box>

          <Card className={classes.billingDetails} style={{ backgroundColor: "rgba(243, 243, 243, 0.3)" }}>
            {_records.map(
              ({ title, value, inputs, description }, index) =>
              (
                <Grid
                  container
                  className={classes.gridContainer}
                  sx={index !== 0 ? { ...dashedBorderStyle } : {}}
                >
                  <Grid item xs={4} className={classes.gridHead}>
                    <Typography sx={{ fontWeight: 600 }}>
                      <span className={classes.titleText}>
                        {title}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8} className={classes.gridBody}>
                    {!inputs ? Array.isArray(value) ? (
                      <>
                        {value.map((item) => (
                          <Box className={classes.multFlexBox}>
                            <Typography className={classes.itemValue}>
                              <span className={classes.itemValueText}>
                                {item.value}
                              </span>
                            </Typography>
                            {item.meta && (
                              <Typography className={classes.metaStyle}>
                                <span className={classes.metaValueText}>
                                  {item.meta}
                                </span>
                              </Typography>
                            )}
                          </Box>
                        ))}
                      </>
                    ) : (
                      <Box>
                        <Typography className={classes.itemValue}>
                          <span className={classes.itemValueText}>
                            {value}
                          </span>
                        </Typography>
                        {description && (
                          <Box className={classes.descContainer}>
                            <Typography className={classes.descText}>
                              <span className={classes.metaValueText}>
                                {description}
                              </span>
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    ) :
                      (
                        <Box>
                          {inputs.map(({ name, placeholder, type, value, label, options, customClass }) => (
                            <Box>
                              {type === "selectField" ?
                                (
                                  <Box >
                                    <Typography variant="body2" sx={{ fontWeight: 600, lineHeight: 2 }} >
                                      <span className={classes.titleText}>
                                        {placeholder}
                                      </span>
                                    </Typography>
                                    <Box>
                                      <Field
                                        component={InputFields}
                                        placeholder={placeholder}
                                        name={name}
                                        type={type}
                                        value={value}
                                        fullWidth
                                        customClass={customClass}
                                        className={classes.languageOptionsText}
                                      >
                                        {options.map((item, index) => (
                                          <MenuItem key={item.key} value={item.value}>
                                            <span className={classes.languageOptionsText}>
                                              {item.label}
                                            </span>
                                          </MenuItem>
                                        ))}
                                      </Field>
                                    </Box>
                                  </Box>
                                )
                                : (
                                  <Field
                                    component={InputFields}
                                    placeholder={placeholder}
                                    name={name}
                                    type={type}
                                    value={value}
                                    customClass={customClass}
                                  />
                                )}
                            </Box>
                          ))}
                        </Box>
                      )
                    }
                  </Grid>
                </Grid>
              )
            )}
          </Card>
          <Box className={classes.agreement}>
            <Box className={classes.checkbox}>
              <Field component={InputFields} type="checkBox" name="agreement" />
            </Box>
            <Typography>
              <span className={classes.agreementText}>
                By checking this box, I agree to establish GoodCharlie as my
                retail electric provider, agree to the{" "}
                <span onClick={() => docRedirection(docFilter(currentPlanData.productDocuments, language ? language : 'English', 'TOS', docProviderID))} className={classes.docStyling}>
                  <ThemedText> Terms of Service</ThemedText>,
                </span>
                <span onClick={() => docRedirection(docFilter(currentPlanData.productDocuments, language ? language : 'English', 'YRAC', docProviderID))} className={classes.docStyling}>
                  <ThemedText> Your Rights as a Customer</ThemedText>,
                </span>
                and
                <span onClick={() => docRedirection(docFilter(currentPlanData.productDocuments, language ? language : 'English', 'EFL', docProviderID))} className={classes.docStyling}>
                  <ThemedText> Electricity Facts Label</ThemedText>{" "}
                </span>
                documents associated with my plan. I also authorize GoodCharlie to
                switch, establish or change my service.
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.forContinueAndTooltop}>
        <Box>
          <ThemedButton
            isSubmitting={submitting}
            type="submit"
            // onClick={performSubmit}
            disabled={invalid}
            titleOnly
          >
            Join the Pack
          </ThemedButton>
        </Box>
        <Box>
          {screenDetail && screenDetail.tooltip && (
            <GCTooltip>{screenDetail.tooltip}</GCTooltip>
          )}
        </Box>
      </Box>
      <Dialog
        open={reviewRes.sgSuccess}
        onBackdropClick={() => null}
        fullWidth
        maxWidth="md"
      >
        <Grid container className={classes.reviewModal}>
          <Grid item xs={12} sm={6}>
            <Box className={classes.imgBox}>
              <img
                src={window.screen.width < 732 ? dialogImg2 : dialogImg}
                alt="dialog-img"
                className={classes.dialogImg}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.dialogContent}>
              <Box className={classes.btnTextBox}>
                <Box className={classes.reviewBox1}>
                  <Typography>
                    <span className={classes.reviewModalTextHurray}>
                      Welcome to The Pack!
                    </span>
                  </Typography>
                  <Typography>
                    <span className={classes.reviewModalText1}>
                      You are making Texas the best place for a dog to live!
                    </span>
                  </Typography>
                  <Typography variant="body2" marginTop="20px">
                    <span className={classes.reviewModalText2}>
                      Create your account now.
                    </span>
                  </Typography>
                </Box>
                <ThemedButton onClick={() => window.open(`${myaccountUrl}register?t=${accTokens.uuid}`, '_self')}>MyAccount</ThemedButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
      <InfoModal open={reviewRes.error} close={closeSgFail} message={reviewRes.message} />
    </form>
  );
};

Review = reduxForm({
  initialValues: {
    agreement: false,
    language: "English",
    // referral_code: localStorage.getItem("referral_code") || null
  },
  destroyOnUnmount: false,
  validate,
  form: "review",
})(Review);

const selector = formValueSelector('review') // <-- same as form name
Review = connect(
  state => {
    // can select values individually
    const language = selector(state, 'language')
    const referral_code = selector(state, 'referral_code')
    return {
      referral_code,
      language,
    }
  }
)(Review)

export default Review;

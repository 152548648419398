import axios from "./axios"

const getData = async (step) => {
    let localData = await localStorage.getItem(step);
    return JSON.parse(localData);
};

const saveDistributionPoints = async (esiid) => {
    let tokens = await getData("tokens");
    let plan = await getData("plan");
    let distributionPoints = []
    try {
        let distributionPointsRes = await axios.post("/sg/service/providers/by/esiid",
            { esiid: esiid },
            {
                headers: {
                    "sg-authorization": `Bearer ${tokens.sg_token.accessToken}`,
                }
            }
        );
        let distPointArray = distributionPointsRes.data.data;
        distPointArray.map(point => {
            let distPoint = null;
            distPointFields.map(field => {
                switch (field) {
                    case 'distributionServiceProviderID':
                    case 'premiseTypeID':
                    case 'address1':
                    case 'shortAddress':
                    case 'city':
                    case 'stateCode':
                    case 'zipCode':
                    case 'serviceProviderIdentifier':
                    case 'stationCode':
                    case 'status':
                    case 'fullAddress':
                    case 'distributionServiceProviderName':
                    case 'monthlyBillingCycle':
                    case 'polrCustomerClass':
                        distPoint = { ...distPoint, [field]: point[field] };
                        break;
                    case 'premiseTypeID':
                        distPoint = { ...distPoint, [field]: String(point[field]) };
                    case 'stationName':
                        distPoint[field] = point[field];
                        distPoint['productID'] = plan.plan_id;
                        break;
                    case 'hasAmsMeter':
                        distPoint[field] = point[field];
                        // distPoint['switchOrMoveInDate'] = moment(startDate.start_date).format("YYYY-MM-DD HH:mm:ss");
                        break;
                }
            })
            distributionPoints.push(distPoint)
        })
        await localStorage.setItem("distribution_point_response", JSON.stringify(distributionPoints))
        return distributionPoints;
    } catch (err) {
        console.log(err)
    }
}
export default saveDistributionPoints;

const distPointFields = [
    'distributionServiceProviderID',
    'premiseTypeID',
    'address1',
    'shortAddress',
    'city',
    'stateCode',
    'zipCode',
    'serviceProviderIdentifier',
    'stationCode',
    'status',
    'fullAddress',
    'distributionServiceProviderName',
    'monthlyBillingCycle',
    'polrCustomerClass',
    'stationName',
    'hasAmsMeter'
]

import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import InputFields, {
  FieldTitle,
  ThemedButton,
  ThemedTitle,
} from "src/Components/InputFields";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import GCTooltip from "src/Components/GCTooltip";
import getScreenDetail from "src/utils/screenDetails";
import getInitFields from "src/utils/getInitFields";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  coverBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 20
  },
  inputFieldYM: {
    height: 48,
    width: 175,
  },
  weightType: {
    height: 48,
    width: 70,
  },
  inputFieldQuantity: {
    height: 48,
    width: 272,
    marginRight: 10,
  },
  inputFieldUnit: {
    height: 48,
    width: 74,
    backgroundColor: "#F3F3F3",
    color: "#21212185",
    fontSize: 16,
    lineHeight: "48px",
    textAlign: "center",
    marginLeft: 2,
    marginBottom: 6,
  },
  forContinueAndTooltop: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
}));

let numberRegExp = /^[1-9]\d*$/;

const fieldErrors = JSON.parse(localStorage.getItem("fieldErrors"));
const fieldTitles = JSON.parse(localStorage.getItem("fieldTitles"));

const validationSchema = Yup.object().shape({
  pet_year: Yup.string().trim().nullable().test(
    "valid option check", fieldErrors?.petAge.pet_year.validYear, value=>{
      let check = (years.includes(value)) ? true : false;
      return check;
    }
  ).when('pet_month', {
    is: "0 Month",
    then: Yup.string().test(
      "valid option check", fieldErrors?.petAge.pet_year.validYear, value=>{
        let check = (years.includes(value)) ? true : false;
        return check;
      }).test("0 Month", fieldErrors?.petAge.pet_year.yearMonthZero, val => val !== '0 Year').required(fieldErrors?.required).nullable()
  }).required(fieldErrors?.required),
  pet_month: Yup.string().trim().nullable().test(
    "valid option check", fieldErrors?.petAge.pet_month.validMonth, value=>{
      let check = (months.includes(value)) ? true : false;
      return check;
    }
  ).required(fieldErrors?.required),
  pet_weight: Yup.string()
    .matches(numberRegExp, fieldErrors?.petAge.pet_weight.validWeight)
    .trim()
    .nullable()
    .required(fieldErrors?.required),
});

const validate = (values) => {
  const formErrors = {};

  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach((error) => {
      formErrors[error.path] = error.message;
    });
  }

  return formErrors;
};

// const months = ["1 Month", "2 Months", "3 Months", "4 Months", "5 Months"];
// const years = ["1 Year", "2 Years", "3 Years", "4 Years", "5 Years"];

const months = Array.from({ length: 12 }, (_, i) => {
  if (i === 0 || i === 1) {
    return i + 0 + " Month";
  } else {
    return i + 0 + " Months";
  }
});
const years = Array.from({ length: 20 }, (_, i) => {
  if (i === 0 || i === 1) {
    return i + 0 + " Year";
  } else {
    return i + 0 + " Years";
  }
});

let PetAge = (props) => {
  const classes = useStyles();
  const { handleSubmit, submitting, invalid } = props;
  const [screenDetail, setScreenDetail] = useState(null);
  const [petType, setPetType] = useState(null);

  useEffect(async () => {
    let data = localStorage.getItem("pet-type");
    let dataParse = JSON.parse(data);
    setPetType(dataParse.petType);
    const init = async () => {
      let scrData = await getScreenDetail("pet-age");
      setScreenDetail(scrData);
    };
    init();
  }, []);

  return (
    <form id="pet-age" onSubmit={handleSubmit}>
      <Box className={classes.root}>
        <div>
         {/* { window.screen.width >= 720 ? <FieldTitle required>
            How old is your {petType ? petType : "pet"}?
          </FieldTitle> : <Box style={{marginTop: 50}} />} */}
          <Box className={classes.coverBox}>
            <Field
              component={InputFields}
              type="selectSearch"
              name="pet_year"
              placeholder={fieldTitles?.petAge.pet_year}
              className={classes.inputFieldYM}
              style={{ marginRight: 10 }}
              options={years.map((item) => ( item ))}
            />
            <Field
              component={InputFields}
              type="selectSearch"
              name="pet_month"
              placeholder={fieldTitles?.petAge.pet_month}
              className={classes.inputFieldYM}
              options={months.map((item) => ( item ))}
            />
          </Box>
        </div>
        <div>
          {/* {window.screen.width >= 720 ? <FieldTitle required>Pet weight</FieldTitle> : <Box style={{marginTop: 30}} />} */}

          <Box className={classes.coverBox}>
            <Field
              component={InputFields}
              type="textFieldNumber"
              name="pet_weight"
              placeholder={fieldTitles?.petAge.pet_weight}
              className={classes.inputFieldQuantity}
            />
            <Box className={classes.inputFieldUnit} sx={{ borderRadius: 2 }}>
              lbs
            </Box>
            {/* <Field
              disabled
              component={InputFields}
              type="selectField"
              name="pet_weight_type"
              className={classes.weightType}
              style={{ marginRight: 10 }}
            >
              {["LB", "kg"].map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Field> */}
          </Box>
        </div>
        <Box className={classes.forContinueAndTooltop}>
          <Box>
            <ThemedButton
              disabled={invalid}
              isSubmitting={submitting}
              type="submit"
            >
              Continue
            </ThemedButton>
          </Box>
          <Box>
            {screenDetail && screenDetail.tooltip && (
              <GCTooltip>{screenDetail.tooltip}</GCTooltip>
            )}
          </Box>
        </Box>
      </Box>
    </form>
  );
};

PetAge = reduxForm({
  initialValues: getInitFields("pet-age"),
  destroyOnUnmount: false,
  validate,
  form: "PetName",
})(PetAge);

export default PetAge;

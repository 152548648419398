import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import charlie from "src/assets/charlie-2.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-end",
    justifySelf: "center",
    marginTop: 24,
    padding: '0px 15px',
    width: "90%",
    [theme.breakpoints.up("md")]: {
      marginTop: 36,
      width: "auto",
      maxWidth: 490
    },
  },
  planTooltip: {
    // width: "505px",
    backgroundColor: "#EDEDED82",
    borderRadius: "32px 32px 32px 5px",
  },
  planTooltipText: {
    // height: "48px",
    // width: "443px",
    padding: "15.5px 31px",
    fontSize: "14px",
  },
  planTooltipIcon: {
    height: "44px",
    width: "44px",
    backgroundColor: "#CEF5F1",
    marginRight: "13px",
    // marginTop: "55px",
    borderRadius: "100%",
  },
  planTooltipCharlie: {
    height: "44px",
    width: "44px",
    borderRadius: 100,
  },
}));

const GCTooltip = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.planTooltipIcon}>
        <img
          src={charlie}
          alt="charlie"
          className={classes.planTooltipCharlie}
        />
      </Box>
      <Box className={classes.planTooltip}>
        <Box className={classes.planTooltipText}>
          <Typography variant="body2">{children}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GCTooltip;

const screenDetails = async (activeScreen, Theme) => {
  let newScreenDetails = await localStorage.getItem("screenDetails");
  newScreenDetails = JSON.parse(newScreenDetails);
  let credit_check = await localStorage.getItem("credit_check_response");
  credit_check = JSON.parse(credit_check);
  let newIndex = null;
  let newScrData = null;
  if (!newScreenDetails) return null
  await newScreenDetails.map(
    async ({ title, description, tooltip, path }, index) => {
      let method = null;

      switch (activeScreen) {
        case "payment-method":
          if (credit_check && credit_check.totalDepositAmount > 0) {
            activeScreen = "deposit";
          }
          break;
        case "payment-details":
          let method = localStorage.getItem("payment-method");
          if (method) {
            try {
              method = JSON.parse(method);
            } catch (e) {
              method = null;
            }
          }
          method = method?.payment_type;
          if (method === "bank") {
            activeScreen = "bank-details";
          } else {
            activeScreen = "card-details";
          }
          break;
        case "card-details":
          if (credit_check && credit_check.totalDepositAmount > 0) {
            activeScreen = "deposit";
          }
          break;
        // case "credit-check":
        //   activeScreen = "verify-identity"
        //   break;
        case "address":
          let has_pet = localStorage.getItem("pet-type");
          has_pet = JSON.parse(has_pet).has_pet
          if (!has_pet) {
            activeScreen = "address-no-pet";
          }
          break;
        case "switching":
          let has_pet_data = localStorage.getItem("pet-type");
          has_pet_data = JSON.parse(has_pet_data).has_pet
          if (!has_pet_data) {
            activeScreen = "switching-no-pet";
          }
          break;
        default:
        // no statement
      }

      if (path === `/${activeScreen}`) {
        newIndex = index;
        let desc = description;
        if (Theme !== undefined) {
          await variables.map(async ({ variable, source, key, defaultVal, noStyle }) => {
            if (desc.includes(variable)) {
              let newValue = null;
              newValue = await localStorage.getItem(source);
              newValue = JSON.parse(newValue);
              if (newValue && newValue[key]) {
                newValue = newValue[key];
                if (path === `/contact-details`) {
                  newValue = `${newValue}!`;
                }
              } else {
                newValue = defaultVal;
              }
              if (noStyle) {
                if (activeScreen === "deposit") {
                  newValue = `$${newValue.toFixed(2)}`;
                }
              }
              else {
                newValue = `<span style="color:${Theme.palette.secondary.main}">${newValue}</span>`;
              }
              desc = desc.replaceAll(variable, newValue);
            }
          });
        }
        newScrData = {
          ...newScreenDetails[newIndex],
          description: desc,
        };
        return newScrData;
      }
    }
  );
  return newScrData;
};

export default screenDetails;

const variables = [
  { variable: "{{charlie}}", defaultVal: "Charlie!" },
  {
    variable: "{{br}}", defaultVal: "<br>", noStyle: true
  },
  {
    variable: "{{subDesc}}", defaultVal: `<span style="color: #3A3A3A !important; font-weight: normal; font-size:${window.screen.width > 700 ? "20px" : "13px"} !important; ">`, noStyle: true
  },
  {
    variable: "{{/subDesc}}", defaultVal: "</span>", noStyle: true
  },
  {
    variable: "{{name}}",
    source: "basic-info",
    key: "first_name",
    defaultVal: "Name",
  },
  {
    variable: "{{pet_name}}",
    source: "pet-name",
    key: "pet_name",
    defaultVal: "Pet Name",
  },
  {
    variable: "{{deposit}}",
    source: "credit_check_response",
    key: "totalDepositAmount",
    defaultVal: 300,
    noStyle: true
  },
];

import { Dialog, Button, DialogActions, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    InfoContainer: {
        minHeight: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "25px 10px",
        [theme.breakpoints.up("md")]: {
            padding: "5px 150px",
            minHeight: 260,
            alignItems: "center",
        }
    },
    infoTitle: {
        fontSize: "20px !important",
        lineHeight: "22px !important",
        paddingBottom: 10,
        [theme.breakpoints.up("md")]: {
            paddingBottom: 15,
            fontSize: "23px !important"
        }
    },
    infodesc: {
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            // textAlign: "center"
        }
    },
    infoAction: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 0,
        [theme.breakpoints.up("md")]: {
            marginTop: 20,
            alignItems: "flex-start",
            justifyContent: "flex-start"
        }
    },
}))

const InfoModal = ({ open, close, handleClose, message }) => {
    const classes = useStyles();
    if (!open || !message) {
        return null;
    }
    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
        >
            <Box className={classes.InfoContainer}>
                <Typography variant="h4" className={classes.infoTitle}>{message ? message : "Something went wrong"}</Typography>
                <Typography variant="body2" className={classes.infodesc}>{JSON.parse(localStorage.getItem("infoModalSubheading"))}</Typography>
                {/* picklist 517 */}
                <DialogActions className={classes.infoAction}>
                    <Button onClick={handleClose ? handleClose : close} >Cancel</Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default InfoModal;